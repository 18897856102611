<div>
  <nz-spin style=" overflow: hidden;width: 100%; height: 100%;" [nzSpinning]="isLoadingMediaData">
<!--    <nz-slider [(ngModel)]="zoom" (ngModelChange)="zoomChange($event)"></nz-slider>-->
<!--    <div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--    </div>-->
    <div class="block" #waveform></div>
  </nz-spin>
</div>
