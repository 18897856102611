import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SystemService } from '../../../../../services/system.service';
import { UserService } from '../../../../../services/user.service';
import { SchoolService } from '../../../../../services/school.service';
import { environment } from '../../../../../../environments/environment';
import { HWResourceService as ResourceService } from '../../../../../services/huawei-resource.service';
import { NzModalService } from 'ng-zorro-antd/modal';


const AppPackageMap = {
  ireadabc: {
    name: '全阅读',
    ios: {
      teacher: 'https://apps.apple.com/us/app/%E5%85%A8%E9%98%85%E8%AF%BB%E6%95%99%E5%B8%88%E7%AB%AF/id1478692733?l=zh&ls=1',
      student: 'https://apps.apple.com/us/app/%E5%85%A8%E9%98%85%E8%AF%BB%E8%8B%B1%E8%AF%AD/id1478212023?l=zh&ls=1'
    },
    android: {
      teacher: '',
      student: ''
    },
    qr: [
      {
        name: '教师端',
        qr_pic: 'https://hw-cdn.ireadabc.com/app/ireadabc_teacher.png',
        download_link: '/api/v2/app/ireadabc/client/teacher.html',
        type: 'teacher'
      },
      {
        name: '学生端',
        qr_pic: 'https://hw-cdn.ireadabc.com/app/ireadabc_student.png',
        download_link: '/api/v2/app/ireadabc/client/student.html',
        type: 'student'
      }
    ]
  },
  ireadabc_resources: {
    name: '全阅读资源',
    android: {
      teacher: ''
    },
    ios: {
      teacher: '上app store后，修改为对应的地址'
    },
    qr: [
      {
        name: '教师端',
        qr_pic: environment.production ? 'https://hw-cdn.ireadabc.com/app/ireadabc_resources_teacher.png' : 'https://hw-cdn.ireadabc.com/app/staging_ireadabc_resources_teacher.png' ,
        download_link: '/api/v2/app/ireadabc_resources/client/teacher.html',
        type: 'teacher'
      },
      // {
      //   name: '学生端',
      //   qr_pic: environment.production ? 'https://hw-cdn.ireadabc.com/app/ireadabc_resources_student.png' : 'https://hw-cdn.ireadabc.com/app/staging_ireadabc_resources_teacher.png' ,
      //   type: 'student'
      // }
    ]
  },
};

@Component({
  selector: 'app-admin-app-manager',
  templateUrl: './app-manager.component.html',
  styleUrls: ['./app-manager.component.scss']
})
export class AppManagerComponent implements OnInit {
  displayData: any[] = [];
  totalCount = 0;
  currentPageIndex = 1;
  appDetail = false;
  isSpinning = false;
  isUploadingApk = false;
  // @ts-ignore
  @ViewChild('versionElement', { static: false }) versionElement: ElementRef;
  info = {
    platform: 'android',
    type: 'student',
    domain: 'ireadabc',
    force: 0,
    version: '0.0.0',
    pub_date: Date.now(),
    store_list: '',
    change_log: ''
  };
  editId = 0;
  isAPK = false;
  uploading = false;
  progress  = 0;
  AppPackageInfo: any;


  iosTeacherAddress = 'https://apps.apple.com/us/app/%E5%85%A8%E9%98%85%E8%AF%BB%E6%95%99%E5%B8%88%E7%AB%AF/id1478692733?l=zh&ls=1';
  iosStudentAddress = 'https://apps.apple.com/us/app/%E5%85%A8%E9%98%85%E8%AF%BB%E8%8B%B1%E8%AF%AD/id1478212023?l=zh&ls=1';
  verReg = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
  isEditOp = false;
  appIdentity: 'ireadabc_resources'|'ireadabc' = 'ireadabc';

  constructor(private sysSrv: SystemService,
              private userSrv: UserService,
              private schoolSrv: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private modalService: NzModalService,
              private route: ActivatedRoute,
              private resService: ResourceService,
              private nzMessageService: NzMessageService) {
    this.resetInfo();
    const appIdentity = this.route.snapshot.data['appIdentity'];
    if (appIdentity) {
      this.appIdentity = appIdentity;
    }
    console.log('appIdentity', this.appIdentity);
    if (AppPackageMap[this.appIdentity]) {
      this.AppPackageInfo = AppPackageMap[this.appIdentity];
    } else {
      this.nzMessageService.error('未知的包名');
    }


  }

  ngOnInit() {
    setTimeout(() => {
      this.loadData(1);
    });
  }
  loadData(page: number) {
    this.isSpinning = true;
    this.currentPageIndex = page;
    this.sysSrv.listAppVersionV2(page, this.appIdentity).then(res => {
      this.displayData = res.rows;
      this.totalCount = res.total;
      this.currentPageIndex = page;
      this.isSpinning = false;
    });
  }
  resetInfo() {
    this.info = {
      type: 'student',
      platform: 'android',
      domain: this.appIdentity,
      force: 0,
      version: '0.0.0',
      pub_date: Date.now(),
      store_list: '',
      change_log: ''
    };
  }

  edit(id: number) {
    this.editId = id;
    this.isEditOp = true;
    this.appDetail = true;
    this.progress = 0;
    const d = this.displayData.find(r => r.id === id);
    // d.store_list = ;
    this.info = {...d, store_list: JSON.parse(d.store_list).join('\n')};
  }
  deleteVer(id: number) {
    this.sysSrv.deleteAppVersion(id).then(res => {
      this.appDetail = false;
      this.resetInfo();
      this.loadData(this.currentPageIndex);

    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      this.appDetail = false;
    });
  }
  addVer() {
    this.resetInfo();
    this.isEditOp = false;
    this.appDetail = true;
    this.progress = 0;

  }
  formatDatetime  (date: Date, withTime = true) {
    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }
    const aaaa = date.getFullYear();
    let gg = date.getDate();
    let mm = (date.getMonth() + 1);

    if (gg < 10) {
      // @ts-ignore
      gg = '0' + gg;
    }
    if (mm < 10) {
      // @ts-ignore
      mm = '0' + mm;
    }
    let cur_day = aaaa + '-' + mm + '-' + gg;

    if (withTime) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      if (hours < 10) {
        // @ts-ignore
        hours = '0' + hours;
      }


      if (minutes < 10) {
        // @ts-ignore
        minutes = '0' + minutes;
      }

      if (seconds < 10) {
        // @ts-ignore
        seconds = '0' + seconds;
      }
      cur_day += ' ' + hours + ':' + minutes + ':' + seconds;
    }
    return cur_day;
  }
  handleOk() {
    // console.log('232');
    // return;
    // this.info.pub_date = this.formatDatetime(this.info.pub_date, false);
    if (this.editId) {
      this.sysSrv.updateAppVersionInfo(this.editId, this.info).then(res => {
        this.appDetail = false;
        this.resetInfo();
        this.loadData(this.currentPageIndex);

      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        this.appDetail = false;
      });
      this.editId = 0;
    } else {
      if (!['android', 'ios'].includes(this.info.platform)) {
        this.nzMessageService.error('平台类型错误');
        return;
      }
      if (!['student', 'teacher'].includes(this.info.type)) {
        this.nzMessageService.error('客户端类型错误');
        return;
      }
      if (!this.info.store_list) {
        this.nzMessageService.error('下载链接不能为空');
        return;
      }
      if (!this.appIdentity) {
        this.nzMessageService.error('app类型错误');
        return;
      }
      this.info.domain = this.appIdentity;
      this.sysSrv.addAppVersionInfo( this.info).then(res => {
        this.appDetail = false;
        this.resetInfo();
        this.loadData(this.currentPageIndex);
      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        this.appDetail = false;
      });
    }

  }
  handleCancel () {
    this.appDetail = false;
    this.editId = 0;
  }
  onChangeApp(k: string, v: string) {
    if (this.info.platform === 'ios') {
      if (this.info.type === 'student') {
        if (this.AppPackageInfo['ios']) {
          this.info.store_list = this.AppPackageInfo['ios'].student; // this.iosStudentAddress;
        }
      }
      if (this.info.type === 'teacher') {
        if (this.AppPackageInfo['ios']) {
          this.info.store_list =  this.AppPackageInfo['ios'].teacher; // this.iosTeacherAddress;
        }
      }
    } else {
      this.info.store_list = '';
    }

  }
  onChangeVersion(evt: any) {
    /*
    console.log(evt);
    const value = this.versionElement.nativeElement.value;
    let newVal = '';
    const reg = /^(([1-9]+\d*\.)+[1-9]+\d*)|[1-9]+\d*$/; // /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      newVal = value;
    }
    this.versionElement.nativeElement.value = newVal;*/

  }

  beforeUpload = (file?: any): boolean => {
    // @ts-ignore
    if (!file.name.endsWith('.apk')) {
      this.nzMessageService.error('it is not a apk file');
      return false;
    }

    if (!this.info.version || !this.verReg.test(this.info.version) || this.info.version === '0.0.0') {
      this.nzMessageService.error('版本号错误，请修复后在上传');
      return false;
    }
    if (!this.info.type) {
      this.nzMessageService.error('请正确选择发布app类型');
      return false;
    }
    this.uploading = true;
    this.progress = 0;
    let path = `/app/${this.appIdentity}/android/`;
    if (!environment.production) {
      path = '/dev' + path;
    }
    // this.resService.doUpload(file as any, path,
    //   this.doProgress , this.uploadSuccess , this.uploadFailure );
    this.isUploadingApk = true;
    this.progress = 0;
    this.info.store_list = '';
    try {
      // @ts-ignore
      this.resService.doUpload({file, /* type: ResType,*/
        osspath: path,
        hash: `${this.info.type}.${this.info.version}`,
        progress_cb: this.doProgress,
        success_cb: this.uploadSuccess,
        error_cb: this.uploadFailure });
    } catch (e) {
     this.uploadFailure({message: '上传失败，请重试'});
    }

    return false;
  }
  uploadSuccess = (name: string, hash: string, url: string, file?: File, inOss = false) => {
    this.nzMessageService.info('Upload Success');
    // this.updateStatus(false);
    this.uploading = false;
    // this.updateSource(url);
    this.info.store_list = url;
    this.isUploadingApk = false;

  }
  uploadFailure = (err: any) => {
    this.uploading = false;
    if (err && err.name && err.name === 'cancel') {
      return;
    }
    console.log(err);
    this.nzMessageService.error('Upload Error ' + err.message);
    this.isUploadingApk = false;
  }

  doProgress = (p: number) => {
    if (p > 1) {
      p = 1;
    }
    if (p < 0) {
      p = 0;
    }
    // console.log(Math.floor(p * 100));
    // @ts-ignore
    this.progress =  Math.floor(p * 100);
  }

  showAppDownload(qr_pic: string, download_link: string) {
    // const content = this.AppPackageInfo['qr'][type];
    const name = this.AppPackageInfo['name'];
    const modal = this.modalService.create({
      nzTitle: `${name}APP下载`,
      nzContent: `<a target="_blank" href="${download_link ? download_link : '#' }"><img src="${qr_pic}" style="width: 100%;height: auto;"/></a>`,
      // nzComponentParams: data,
      // nzMaskClosable: false,
      // nzClosable: false,
      nzClassName: 'app-download-modal',
      nzWrapClassName: 'app-download-modal-wrap',
      nzWidth: 400,
      nzFooter: null
      // nzFooter: [
      // nzFooter: [
      //   {
      //     label: '关闭',
      //     type: 'default',
      //     loading: false,
      //     onClick(): void {
      //
      //       modal.destroy();
      //     }
      //   }
      // ]
    });
  }
}
