import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ServiceLocator } from './locator.service';

export class BaseService {
  data: any;
  private nzMessageService: NzMessageService;

  constructor(protected http: HttpClient,
              protected router: Router
              ) {
    this.nzMessageService = ServiceLocator.injector.get(NzMessageService);
  }
  objectToQueryString(obj: Record<string, any>, prefix = ''): string {
     const q = Object.entries(obj)
      .map(([key, value]) => {
        const fullKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === 'object' && value !== null) {
          return this.objectToQueryString(value, fullKey);
        }

        return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
      });
    return q.filter(s => Boolean(s.trim())).join('&');
  }

  ajax(method: string, url: string, data?: any): Promise<any> {
    let subscriber: { unsubscribe: () => void; } | null = null;
    method = method.toLowerCase();
    const q = new Promise((resolve, reject) => {
      // console.log(method, url, data);
      // @ts-ignore
      subscriber = this.http[method]<any>(url, data).subscribe((res: any) => {
        // @ts-ignore
        subscriber.unsubscribe();
        if (!res) {
          res = {code: 200};
        }
        resolve(res);
      }, (err: any) => {
        if (err.errorCode === 1011 ) {
          this.nzMessageService.error('权限不符，请重新登陆');
          this.router.navigate(['/login']);
          return;
        }
        reject (err);
      });
    });
    Object.defineProperty(q, 'cancel', {
      value: () => {
        if ( subscriber ) {
          subscriber.unsubscribe();
        }
      }
    });
    return q;

    // return new Promise((resolve, reject) => {
    //   // console.log(method, url, data);
    //   this.http[method](url, data).subscribe((res) => {
    //     resolve(res);
    //   }, reject);
    // });
  }

  get(url: string): Promise<any> {
    return this.ajax('GET', url);
    // let subscriber = null;
    // const q = new Promise((resolve, reject) => {
    //   subscriber = this.http.get(url).subscribe((res) => {
    //     this.data = res;
    //     resolve(res);
    //   }, reject);
    // });
    // q['cancel'] = () => {
    //   if ( subscriber ) {
    //     subscriber.unsubscribe();
    //   }
    // };
    // return q;

  }

  post(url: string, data: any) {
    return this.ajax('post', url, data);
  }

  put(url: string, data: any) {
    return this.ajax('put', url, data);
  }

  remove(url: string) {
    return this.ajax('delete', url);
  }
  getParamValueQueryString( paramName: string ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
}
