import { EventEmitter } from '@angular/core';

export class MediaDelegate {
  mediaEl!: HTMLVideoElement|HTMLAudioElement;
  public mediaPlayTimestamp = new EventEmitter();
  public mediaDataLoaded = new EventEmitter();
  public mediaPlayBarPosition = new EventEmitter();
  public mediaPlayEnd = new EventEmitter();
  public mediaLoadError = new EventEmitter();
  public mediaWaiting = new EventEmitter();
  public mediaPlaying = new EventEmitter();
  // @ts-ignore
  syncAudioCurrentTimeId: number = null;
  // private arrayBuffer = null;
  formatter = new Intl.NumberFormat('en', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    useGrouping: false,
  });
  playToTime!: number;
  constructor(mediaElement: HTMLVideoElement|HTMLAudioElement) {
    this.mediaEl = mediaElement;
    this.mediaEl.onloadeddata = this.onMediaDataLoaded.bind(this);
    this.mediaEl.onplay = this.onMediaPlay.bind(this);
    this.mediaEl.onpause = this.onMediaPause.bind(this);
    this.mediaEl.ontimeupdate = this.onMediaTimeUpdate.bind(this);
    this.mediaEl.onratechange = this.onMediaRateChange.bind(this);
    this.mediaEl.onended = this.onMediaEnded.bind(this);
    this.mediaEl.onerror = (e) => {
      this.mediaLoadError.emit(e);
    };
    this.mediaEl.onwaiting = (e) => {
      console.log('onwaiting', e);
      this.mediaWaiting.emit(e);
    };

    // this.mediaEl.onsuspend = (e) => {
    //   console.log('onsuspend', e);
    //   // this.videoWaiting.emit(e);
    // };
    this.mediaEl.ontimeupdate = (e: any) => {
      // console.log('ontimeupdate', e);
      this.mediaPlaying.emit(e);
    };

  }
  set playbackRate(val: number) {
    this.mediaEl.playbackRate = val;
  }

  set src(val) {
    // @ts-ignore
    this.mediaEl.src = val;
  }
  get src() {
    // @ts-ignore
    return this.mediaEl.src;
  }
  convertTagToTime(tag: string) {
    tag = tag.replace('[', '');
    tag = tag.replace(']', '');
    const parts = tag.split(':');
    let h = 0;
    let m = 0;
    let s = 0;
    if (parts.length === 3) {
      h = parseInt(parts[0], 10);
      m = parseInt(parts[1], 10);
      s = parseInt(parts[2], 10);
    } else if (parts.length === 2) {
      m = parseInt(parts[0], 10);
      s = parseFloat(parts[1] );
    }

    return h * 60 * 60 + m * 60 + s;
  }
  convertTimeToTag(time: number, withBracket = true): string {
    if (time === undefined) {
      return '';
    }
    const hh = Math.floor(time / 60 / 60)
      .toString()
      .padStart(2, '0');
    const mm = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const ss = this.formatter.format(time % 60);

    return withBracket ? `[${hh}:${mm}:${ss}]` : `${hh}:${mm}:${ss}`;
  }
  // setSource(ab) {
  //   this.arrayBuffer = ab;
  //   const blob = new Blob([ab], { type: 'audio/wav' });
  //   this.mediaEl.src = URL.createObjectURL(blob);
  // }
  // getDataBuffer() {
  //   return this.arrayBuffer;
  // }

  // getBufferClip(start, end) {
  //   return this.arrayBuffer.slice(start * this.arrayBuffer.length, end * this.arrayBuffer.length);
  // }
  load() {
    // @ts-ignore
    this.mediaEl.load();
  }
  syncAudioCurrentTime() {
    // @ts-ignore
    const currentTime = this.mediaEl.currentTime;

    this.mediaPlayTimestamp.emit({
      timeFormat: this.convertTimeToTag(currentTime, false),
      currentTime
    });
    if (this.playToTime &&  currentTime >= this.playToTime) {
      this.pause();
      this.playToTime = 0;
      return;
    }
    this.syncAudioCurrentTimeId = requestAnimationFrame(() => {
      this.syncAudioCurrentTime();
    });
  }
  onMediaDataLoaded(evt: any) {
    console.log('onVideoDataLoaded', evt);
    this.mediaDataLoaded.emit();
  }
  onMediaPlay() {
    this.syncAudioCurrentTimeId = requestAnimationFrame(() => {
      this.syncAudioCurrentTime();
    });
    console.log('onVideoPlay');
  }
  onMediaPause() {
    console.log('onVideoPause');
    cancelAnimationFrame(this.syncAudioCurrentTimeId);
  }
  onMediaEnded() {
    console.log('onVideoEnded');
    this.mediaPlayEnd.emit();
    cancelAnimationFrame(this.syncAudioCurrentTimeId);
  }
  onMediaTimeUpdate() {
    // console.log('onVideoTimeUpdate', this.convertTimeToTag(this.mediaEl.currentTime));
    // this.videoPlayTimestamp.emit(this.convertTimeToTag(this.mediaEl.currentTime));
  }
  onMediaRateChange() {
    console.log('onVideoRateChange');
  }
  get isPlaying() {
    return !!(this.mediaEl.currentTime > 0
      && !this.mediaEl.paused
      && !this.mediaEl.ended
      && this.mediaEl.readyState > 2);
  }

  currentTimeFormatted(time?: number) {
    let t = this.mediaEl.currentTime;
    if (typeof time !== 'undefined') {
      t = time;
    }
    return this.convertTimeToTag(t, false);
  }

  get currentTime() {
    return this.mediaEl.currentTime;
  }
  set currentTime(val) {
    this.mediaPlayBarPosition.emit({
      time: val
    });
    this.mediaEl.currentTime = val;
  }
  get dimensions () {
    const el =  this.mediaEl as HTMLVideoElement;
    return {height: el.videoHeight, width: el.videoWidth};
  }
  get duration() {
    return this.mediaEl.duration;
  }
  get durationFormatted() {
    return this.convertTimeToTag(this.mediaEl.duration, false);
  }
  get currentSrc() {
    return this.mediaEl.currentSrc;
  }
  pause() {
    this.mediaEl.pause();
  }
  async play(beginTime?: number, toTime?: number) {
    // this.playTo
    if (beginTime) {
      this.mediaEl.currentTime = beginTime;
    }
    if (toTime) {
      this.playToTime = toTime;
    } else {
      this.playToTime = 0;
    }
    // @ts-ignore
    return this.mediaEl.play();
  }
}
