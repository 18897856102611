<common-top-tool-bar [title]="this.book_id ? '編輯' : '新增'+'繪本 Reading'"
                     [back]="'/admin/v3/resource/'+bisType+'/courseware/edit/'+book_id"
                     [query]="{tabIndex: 1}"
                     [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'" class="mr-4"
            [nzSize]="'large'" nzGhost
            [routerLink]="'/admin/v3/resource/'+bisType+'/courseware'">
      取消
    </button>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            (click)="saveCoursewarePage()">
      {{this.book_id ? '儲存' : '確定新增' }}
    </button>
  </ng-template>
</common-top-tool-bar>





<div class="page-main">
  <nz-spin [nzSpinning]="isLoading">
    <div *ngIf="currentPageAudioUrl">
<!--      <app-wave-player [mediaUrl]="coursewareData.audio_url"></app-wave-player>-->
      <div class="px-4">
<!--        [parts]="currentPageAudioParts"-->
        <app-wave-player-v2 [height]="50"
                            [onDecodeCallback]="onDecodeCallback"
                            [redrawRegionTrigger]="redrawRegionTrigger"
                            [audioUrl]="currentPageAudioUrl"></app-wave-player-v2>
      </div>
    </div>
    <div  class="p-4">
      <tw-audio-uploader
        [audioUrl]=""
        comment="注：音频格式为 Mp3 文件，音频文件大小限制为 10MB。"
        (clear)="clearBookAudio($event)"

        (audioUploadFailure)="onAudioUploadFailure($event)"
        (audioUploaded)="onBookAudioUploadSuccess($event)">
<!--        [displayAudioTemplate]="displayTextTemplate"-->
        <ng-template #displayTextTemplate></ng-template>
      </tw-audio-uploader>
    </div>

    <div class="page-main-header">


      <div class="page-main-header-tools">
        <button nz-button nzType="primary" nzGhost (click)="addNewPage()">
          <i nz-icon nzType="plus" nzTheme="outline" class="fix-icon"></i>
          新增页数
        </button>
      </div>
      <div class="page-main-header-actions">
        <button nz-button nzType="default" nzDanger class="mr-4" (click)="removePage()">刪除此頁</button>
      </div>
    </div>
    <ng-template #dragIcon>
    <span nz-icon cdkDrag nzType="pause" nzTheme="outline" size="64" class="drag-icon"></span>
    </ng-template>
    <div class="page-main-table">
      <div class="table-header"></div>
      <nz-tabset nzType="line"
                 [nzTabPosition]="'left'"
                 [nzSelectedIndex]="currentPageIndex"
                 cdkDropList cdkDropListOrientation="vertical"
                 (cdkDropListDropped)="drop($event)"
                 (nzSelectedIndexChange)="pageIndexChange($event)">

<!--        cdkDropList (cdkDropListDropped)="drop($event)">-->
<!--        <tr *ngFor="let data of dataSet" cdkDrag>-->

        <nz-tab *ngFor="let page of factory_pages;
                let i = index" [nzClosable]="false"
                [nzTitle]="titleTemplate">
          <ng-template #titleTemplate>
            <div cdkDrag style="font-size: 14px">
              <div class="px-2" *cdkDragPlaceholder></div>
              {{ '页码'+(i + 1) }}
            </div>
          </ng-template>
          <div class="p-8" style="padding-left: 8px;">
            <div class="text-right leading-8">
              显示/隐藏本页：
              <nz-switch style="vertical-align: 0.125em;" [ngModel]="page.show" nzCheckedChildren="显示" nzUnCheckedChildren="隐藏"></nz-switch>
            </div>
            <div nz-row nzGutter="32">
              <div nz-col nzSpan="8">
                <div class="main-text-color font-bold text-lg mb-4">上传图片</div>
                <app-upload-image-with-preview-v2
                  #pageImage
                  [picPreview]="true"
                  [innerClass]=""
                  style="width: 100%;"
                  [displayTextTemplate]="displayTextTemplate"
                  [displayIcon]="null"
                  [picUrl]="(page.image_area?.images[0]?.imageUrl | mediaUrlPipe )"
                  (imageUploaded)="onImageUploaded($event)"
                  previewClass="bg-contain bg-no-repeat"
                  displayRatioWidth="210"
                  displayRatioHeight="297">
                  <ng-template #displayTextTemplate>
                    <div class="p-6 rounded">请将文件拖曳至此或点击此处上传图片。</div>
                  </ng-template>
                </app-upload-image-with-preview-v2>
                <div class="font-light text-xs px-4">注：图片格式为 Jpg 或 Png 文件，图片文件大小限制为 2MB</div>
              </div>
              <div nz-col nzSpan="16" class="flex flex-col">
                <div *ngIf="!coursewareData.audio_url">
                  <div class="main-text-color font-bold text-lg mb-4 flex-none">上传音频</div>
                  <div class="flex-none">
                  <tw-audio-uploader
                    [audioUrl]="(page.audio_url | mediaUrlPipe )"
                    comment="注：音频格式为 Mp3 文件，音频文件大小限制为 10MB。"
                    (clear)="clearAudio($event)"
                    (audioUploadFailure)="onAudioUploadFailure($event)"
                    (audioUploaded)="onAudioUploadSuccess($event)">
                  </tw-audio-uploader>
                  </div>
                </div>
                <div class="flex my-4">
                  <div class="flex-none leading-8 main-text-color font-bold text-lg">输入內容</div>
                  <div class="flex-1 flex justify-end">
                    <button nz-button [nzType]="'primary'" class="mr-4" (click)="addNewParagraph()" nzGhost>
                      增加段落
                    </button>

                  </div>
                </div>
                <div *ngFor="let pa of (page?.text_area?.paragraphs || []); let j = index">
                  <nz-divider></nz-divider>
                  <div>
                    <div class="flex main-text-color font-bold my-4 flex-none text">
                      <div class="flex-none">段落{{j+1}}.</div>
                      <div class="flex flex-1 flex-row-reverse">
                        <div class="flex">
                          <div class="font-light mx-2 text-sm leading-8"> 时间点： </div>
                          <div><nz-input-number class="font-light"
                                                [nzMin]="0" [nzMax]="99999" [nzStep]="0.1"
                                                [(ngModel)]="pa.audioPoint.start"
                                                (ngModelChange)="resizeAudioPart($event, i, j)"
                                                (click)="highlightAudioPart(i, j)"></nz-input-number></div>
                          <div class="font-light mx-2 text-sm leading-6"> -- </div>
                          <div><nz-input-number class="font-light"
                                                [nzMin]="0" [nzMax]="99999" [nzStep]="0.1"
                                                [(ngModel)]="pa.audioPoint.end"
                                                (ngModelChange)="resizeAudioPart($event, i, j)"
                                                (click)="highlightAudioPart(i, j)"></nz-input-number></div>

                          <span nz-icon nzType="delete" nzTheme="outline" class="cursor-pointer mx-2 mt-2" (click)="deleteParagraph($event, j)"></span>
                        </div>
                      </div>
                    </div>
                    <div>英文：
                      <a nz-button nzType="link" style="float: right" [nzSize]="'small'" [nzLoading]="transBtn.nzLoading" (click)="translate($event, j, transBtn)" #transBtn>翻译</a></div>
                    <textarea
                      nz-input
                      [(ngModel)]="pa.text"
                      placeholder="请输入英文內容"
                      style="height: 100px;"
                      class="flex-1 mb-4"
                    ></textarea>
                    <div>中文：</div>
                    <textarea
                      nz-input
                      [(ngModel)]="pa.cnTranslation"
                      placeholder="请输入中文翻译內容"
                      style="height: 100px;"
                      class="flex-1 mb-4"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </nz-tab>
      </nz-tabset>
    </div>
  </nz-spin>
</div>
