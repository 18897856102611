import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SyllabusService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  category(resource_type: string, query?: any) {
    let queryStr = '';
    if (query) {
      queryStr = `?${this.objectToQueryString(query)}`;
    }

    const url = `/syllabus/${resource_type}/category${queryStr}`;
    return super.get(url);
  }
  checkNameExist(resource_type: string, query?: any) {
    let queryStr = '';
    if (query) {
      queryStr = `?${this.objectToQueryString(query)}`;
    }
    const url = `/syllabus/${resource_type}/check/name${queryStr}`;
    return super.get(url);
  }
  deleteItem(resource_type: string, data: any) {
    return this.post(`/syllabus/${resource_type}/item/delete`, data);
  }

  save(resource_type: string, data: any) {
    return this.post(`/syllabus/${resource_type}/item/save`, data);
  }
  seq(resource_type: string, seq: any) {
    return this.post(`/syllabus/${resource_type}/item/seq`, {seq});
  }

  getResources(resourceType: string, pid: number): Promise<Array<{
    id: number;
    name: string;
  }>> {
    return this.get(`/syllabus/${resourceType}/resources/${pid}`);
  }

  getCoursewares(syllabusId: number, courseId: number): Promise<Array<{
    id: number;
    name: string;
  }>> {
    return this.get(`/courseware/list/${syllabusId}/${courseId}`);
  }

  getCoursewareResourceTypes(courseId: number): Promise<Array<{
    resourceType: string;
  }>> {
    return this.get(`/courseware/${courseId}/resource-types`);
  }

  getCoursewareResourceVideos(courseId: number): Promise<Array<{
    id: number;
    name: string;
  }>> {
    return this.get(`/courseware/${courseId}/videos`);
  }

  getCoursewareResourceAudios(courseId: number): Promise<Array<{
    id: number;
    name: string;
  }>> {
    return this.get(`/courseware/${courseId}/audios`);
  }

}
