import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';

export interface PictureBookImageArea {
  images: PictureBookImage[];
}

export interface TextArea {
  show: boolean;
  paragraphs: Paragraph[];
}

export interface PictureBookImage {
  imageUrl: string;
  hotzones: Hotzone[];
}

export interface Hotzone {
  imageUrl: string;
  text: string;
  rect: Rectangle;
  paddingY: number;
  paddingX: number;
  relatedParagraphId: number;
}

export interface Paragraph {
  id: number;
  text: string;
  cnTranslation: string;
  audioPoint: AudioPoint;
  audioUrl: string;
  keyWords: string[];
}

export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface AudioPoint {
  start: number;
  end: number;
}

interface PictureBookPage {
  id?: number;
  picture_book_id: number;
  number: number;
  audio_url: string;
  // image_url: string;
  image_area: PictureBookImageArea;
  text_area: TextArea;
  show: boolean;
}



@Injectable({
  providedIn: 'root'
})
export class CoursewareService extends BaseService {
  constructor(protected override http: HttpClient,  private userService: UserService, protected override router: Router) {
    super(http, router);
  }

  bookAttrs(bis_type: string) {
    return super.get(`/courseware/book/attribute?bis_type=${bis_type}`);
  }
  list(resource_type: string, query: any) {
    const url = `/courseware/${resource_type}/list`;
    let queryStr = '';
    if (query) {
      queryStr = `?${this.objectToQueryString(query)}`;
    }
    return super.get(`${url}${queryStr}`);
  }

  fetch(resource_type: string, id: number) {
    return super.get(`/courseware/item/${id}`);
  }

  save(resourceType: string, data: any) {
    return super.post( '/courseware/data/save', data);
  }

  setAssociateResources(book_id: number, res_type: string, data: number[]) {
    return super.post( `/courseware/item/${book_id}/relate/${res_type}`, data);
  }
  getAssociateResources(book_id: number, res_type: string) {
    return super.get( `/courseware/item/${book_id}/relate/${res_type}`);
  }

  createDpePages(book: any) {
    const picture_book_id = book.id;
    const pages = JSON.parse(JSON.stringify(book.data.pages));
    const page: PictureBookPage = {
      // id: 0,
      show: false,
      picture_book_id, // pictureBookId
      number: 0,
      audio_url: book.data.audio, // audioUrl
      // image_url: '',
      image_area: { // imageArea
        images: [

          /* {
            imageUrl: '',
            hotzones: [
              {
                imageUrl: '',
                text: '',
                rect: {
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
                },
                paddingY: 0,
                paddingX: 0,
                relatedParagraphId: 0,
              }
            ]
          } */
        ],
      },
      text_area: {
        show: true,
        paragraphs: [
          /* {
            id: 0,
            text: '',
            cnTranslation: '',
            audioUrl: '',
            audioPoint: {
              start: 0,
              end: 0,
            },
            keyWords: ['', ''],
          } */
        ],
      }
    };
    const factory_pages: PictureBookPage[] = [];
    for (let i = 0 ; i < pages.length; i++) {
      const newPage = pages[i];
      const paragraphs: Paragraph[] = [{
        id: 0,
        text: newPage.text,
        cnTranslation: '',
        audioUrl: newPage.audio,
        audioPoint: {
          start: 0,
          end: newPage.duration,
        },
        keyWords: [],
      }];

      if (newPage.datas && newPage.datas.length > 0) {
        paragraphs.length = 0;
        for (let j  = 0; j < newPage.datas.length; j++) {
          const {audio , text, duration} = newPage.datas[j];
          paragraphs.push({
            id: j,
            text,
            cnTranslation: '',
            audioUrl: audio,
            audioPoint: {
              start: 0,
              end: duration,
            },
            keyWords: [],
          });
        }

      }
      const fPage: PictureBookPage = {
        show: newPage.show,
        // id: 0,
        picture_book_id, // pictureBookId
        number: i,
        audio_url: newPage.audio, // audioUrl
        image_area: { // imageArea
          images: [
            {

              imageUrl:  newPage.imgtext,
              hotzones: []
            }
            /* {
              imageUrl: '',
              hotzones: [
                {
                  imageUrl: '',
                  text: '',
                  rect: {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                  },
                  paddingY: 0,
                  paddingX: 0,
                  relatedParagraphId: 0,
                }
              ]
            } */
          ],
        },
        text_area: {
          show: newPage.show,
          paragraphs,
          // paragraphs: [
          //   {
          //     id: 0,
          //     text: '',
          //     cnTranslation: '',
          //     audioUrl: '',
          //     audioPoint: {
          //       start: 0,
          //       end: 0,
          //     },
          //     keyWords: ['', ''],
          //   }
          // ],
        }
      };
      factory_pages.push(fPage);
    }
    return factory_pages;
  }

}
