import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';


export class DragElement {
  onMove = new EventEmitter();
  canMove = false;
  public dragEl = null;
  relX = 0;
  private readonly bindMove: any;
  // private readonly maxWidth: any;
  // @ts-ignore
  private readonly constraint:  (DragElement) => [number, number] | [number, number];
  private readonly bindDown: any;
  private readonly bindUp: any;
  // @ts-ignore
  private b4Down: (DragElement) => boolean;
  // @ts-ignore
  private start: (DragElement, event: MouseEvent) => void;
  // private onMoveFn: () => void;
  // @ts-ignore
  private moveObs: Subscription = null;
  // @ts-ignore
  constructor(el, options) {
    this.dragEl = el;
    this.b4Down = options.b4Down;
    this.constraint = options.constraint;
    this.start = options.start;
    // this.onMoveFn = onMoveFn;
    this.bindMove = this.move.bind(this);
    this.bindDown = this.down.bind(this);
    this.bindUp = this.up.bind(this);
    // @ts-ignore
    this.dragEl.addEventListener('mousedown', this.bindDown, false);
    document.addEventListener('mouseup', this.bindUp, false);
  }
  dispose() {
    // @ts-ignore
    this.dragEl.removeEventListener('mousedown', this.bindDown, false);
    document.removeEventListener('mouseup', this.bindUp, false);
    document.removeEventListener('mousemove', this.bindMove, true);
    if (this.onMove && !this.onMove.closed) {
      this.onMove.unsubscribe();
    }
    if (this.moveObs && !this.moveObs.closed) {
      this.moveObs.unsubscribe();
    }
  }
  // @ts-ignore
  down(e) {
    e.stopPropagation();
    e.preventDefault();
    // console.log('down', e);
    // @ts-ignore
    if (this.b4Down && this.b4Down.call) {
      const result = this.b4Down(this);
      if (!result) {
        return;
      }
    }
    document.addEventListener('mousemove', this.bindMove, true);
    // this.moveObs = fromEvent(document, 'mousemove').subscribe(this.bindMove);
    // relX = e.pageX - this.timeLine.offsetWidth || 0;
    // const left = parseInt(el.offsetWidth|| 0)
    // const matrix = new DOMMatrix(this.dragEl.style.transform);
    let w = 0;
    // @ts-ignore
    if (this.dragEl.style.transform) {
      // @ts-ignore
      const xy = this.dragEl.style.transform.match(/(-?[0-9\.]+)/g);
      // const matrix = new DOMMatrix(this.dragEl.style.transform);
      // const w = matrix.m41;
      w = +xy[0];
      // @ts-ignore
    } else if (this.dragEl.style.left) {
      // @ts-ignore
      w = parseInt(this.dragEl.style.left, 10);
    }
    this.relX = e.pageX - w || 0;
    this.canMove = true;
    const aa = this.relX;
    // @ts-ignore
    if (this.start && this.start.call) {
      this.start(this, e);
    }
    console.log('start relX', aa, this.relX);
  }
  up(e?: any) {
    this.canMove = false;
    document.removeEventListener('mousemove', this.bindMove, true);
    if (this.moveObs && !this.moveObs.closed) {
        this.moveObs.unsubscribe();
    }
  }
  move(e?: any) {
    if (!this.canMove) {
      return;
    }
    // let w = 0;
    // if (this.dragEl.style.transform) {
    //   const xy = this.dragEl.style.transform.match(/(-?[0-9\.]+)/g);
    //   // const matrix = new DOMMatrix(this.dragEl.style.transform);
    //   // const w = matrix.m41;
    //   w = +xy[0];
    // }
    // @ts-ignore
    let [l, r] = [0, this.dragEl.parentElement.offsetWidth];
    if (this.constraint) {
      // @ts-ignore
      if (this.constraint.call) {
        [l, r] = this.constraint(this);
      } else if (Array.isArray(this.constraint)) {
        [l, r] = this.constraint as unknown as [number, number];
      }
    }


    let posX = e.pageX - this.relX;
    if (posX > r) {
      // this.dragEl.style.transform = `translateX(${r}px)`;
      // return;
      posX = r;
    }
    if (posX < l ) {
      // this.dragEl.style.transform = `translateX(${l}px)`;
      // return;
      posX = l;
    }
    // this.dragEl.style.transform = `translateX(${posX}px)`;
    this.onMove.emit({
      dd: this,
      position: posX
    });

  }
}


