import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { NzTableQueryParams } from 'ng-zorro-antd/table/src/table.types';




@Component({
  selector: 'tw-audio-uploader',
  template: `

    <app-upload-audio-v2
      style="width: 100%;display: inline-block;"
      [audioUrl]="audioUrl"
      [displayTextTemplate]="displayAudioTemplate"
      (audioUploadFailure)="uploadFailure($event)"
      (audioUploaded)="uploaded($event)">
     
    </app-upload-audio-v2>
    <div class="font-light text-xs px-4" *ngIf="comment">{{comment}}</div>
    <ng-template #defaultDisplayAudioTemplate>
      <div class="py-1 px-4 rounded font-light text-x dash-border rounded" style="
        color: #aaa;
        width: 100%;
        background-color: #fafafa;">
                      <span *ngIf="!audioUrl" class="leading-8">
                      请将文件拖曳至此，或点击此处上传音频文件
                      </span>
        <div class="flex" *ngIf="audioUrl">
          <div class="flex-1">
            <div *ngIf="audioName" class="leading-8">{{audioName}}</div>
            <audio [class]="{
              hidden: !!audioName
              }" [src]="audioUrl" controls></audio>
          </div>
          <div class="flex-none h-full"
               (click)="clearAudio($event)"
               style="line-height: 32px;
                             color:#FB4A4A;
                             text-decoration: underline;
                             font-weight: 500;" >Delete</div>
        </div>
      </div>

    </ng-template>
  `,
  styles: [
    `
      audio::-webkit-media-controls-enclosure {
        background: none;
        height: 32px;
        max-height: 32px;
      }
      audio::-webkit-media-controls-volume-control-container{
        display: none;
      }
      audio::-internal-media-controls-overflow-button {
        display: none;
      }
      
      audio::-internal-media-controls-download-button {
        display:none;
      }
      audio::-webkit-media-controls-enclosure {
        overflow:hidden;
      }
      audio::-webkit-media-controls-panel {
        width: calc(100% + 30px); /* Adjust as needed */
      }
      video::-internal-media-controls-download-button {
        display:none;
      }
      video::-webkit-media-controls-enclosure {
        overflow:hidden;
      }
      audio {
        height: 32px;
      }
      .dash-border {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DDDDDDff' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");}
      .ant-upload {
        line-height: 32px;
      }
      .audio-del {
        line-height: 32px;
        color:#FB4A4A;
        text-decoration: underline;
        font-weight: 500;
      } 
    `
  ],
  // standalone: true
})
export class CommonTwAudioUploaderComponent implements OnInit{
  @Input() audioUrl!: string;
  @Input() comment!: string;
  @Input() audioName!: string;
  @Output() audioUploadFailure: EventEmitter<any> = new EventEmitter();
  @Output() audioUploaded: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Input() displayAudioTemplate?: TemplateRef<any>;

  @ViewChild('defaultDisplayAudioTemplate', {static: true }) defaultDisplayAudioTemplate!: TemplateRef<any>;

  constructor() {

  }

  ngOnInit() {
    if (!this.displayAudioTemplate) {
      this.displayAudioTemplate = this.defaultDisplayAudioTemplate;
    }
  }
  uploaded($event: any) {
    this.audioUploaded.emit($event);
  }
  uploadFailure($event: any) {
    this.audioUploadFailure.emit($event);
  }
  clearAudio($event: any) {
    console.log('clearAudio');
    $event.stopPropagation();
    this.clear.emit($event);
  }

}
