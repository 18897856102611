import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CheckPointsService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  checkNameExist(resourceType: string, query?: any) {
    const queryStr = this.objectToQueryString(query);
    const url = `/syllabus/${resourceType}/check/checkPointName?${queryStr}`;
    return super.get(url);
  }


  deleteItem(resourceType: string, data: any) {
    return this.post(`/syllabus/${resourceType}/item/deleteCheckPoint`, data);
  }

  save(resourceType: string, data: any) {
    return this.post(`/syllabus/${resourceType}/item/saveCheckPoint`, data);
  }



}
