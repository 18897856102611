import {Component, OnInit, ViewChildren, QueryList} from '@angular/core';
import {
    ResourcePushTemplateDayOfWeek,
    ResourcePushTemplateStartingPoint,
    ResourcePushTemplateStartingPoints,
    ResourcePushWeekTemplate,
    ResourcePushWeekTemplateHomework,
    ResourcePushWeekTemplateHomeworkResource
} from '../../../../../../model/ResourcePushTemplate';
import {ActivatedRoute} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminResourceCategoryTemplateHomeworkComponent } from './homework.component';
import { HomeworkPushTemplatesService } from 'src/app/services/homework-push-templates.service';

@Component({
    selector: 'app-admin-book-category-template',
    templateUrl: './index.component.html',
})
export class AdminBookCategoryTemplateComponent implements OnInit {

    startingPoint: ResourcePushTemplateStartingPoint = 1;
    id = 0;
    categoryId = 0;
    categorySubId = 0;

    @ViewChildren(AdminResourceCategoryTemplateHomeworkComponent)
    homeworkComponents!: QueryList<AdminResourceCategoryTemplateHomeworkComponent>;
    form: FormGroup;
    templates: ResourcePushWeekTemplate[] = [];
    loading = true;
    saving = false;

    protected readonly ResourcePushTemplateStartingPoints = ResourcePushTemplateStartingPoints;
    protected readonly ResourcePushTemplateDayOfWeek = ResourcePushTemplateDayOfWeek;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private message: NzMessageService,
        private homeworkPushTemplatesService: HomeworkPushTemplatesService,
    ) {
        this.form = this.fb.group({
            templates: this.fb.array([])
        });
    }

    get templatesForm() {
        return this.form.get('templates') as FormArray;
    }

    async ngOnInit() {
        this.id = Number(this.route.snapshot.queryParams['id']);
        this.startingPoint = Number(this.route.snapshot.queryParams['startingPoint']) as ResourcePushTemplateStartingPoint;
        this.categoryId = Number(this.route.snapshot.queryParams['categoryId']);
        this.categorySubId = Number(this.route.snapshot.queryParams['categorySubId']);
        await this.fetchData();
    }

    private async fetchData() {
        this.loading = true;
        this.templates = await this.homeworkPushTemplatesService.list(this.categoryId, this.categorySubId, this.startingPoint);
        this.templatesForm.clear();
        this.templates.forEach(templates => this.addTemplateForm(templates));
        this.loading = false;
    }

    private createTemplateForm(templates: Partial<ResourcePushWeekTemplate> = {}) {
        return this.fb.group({
            daysOfWeek: [templates.daysOfWeek || [], [Validators.required, Validators.minLength(1)]],
            remark: [templates.remark || ''],
            homeworks: [templates.homeworks || []]
        });
    }

    private addTemplateForm(templates: Partial<ResourcePushWeekTemplate>) {
        this.templatesForm.push(this.createTemplateForm(templates));
    }

    handleHomeworkChange(templatesIndex: number, homeworkIndex: number, homework: ResourcePushWeekTemplateHomework) {
        this.templates[templatesIndex].homeworks[homeworkIndex] =
            Object.assign(this.templates[templatesIndex].homeworks[homeworkIndex], homework);
    }

    handleAddTemplate() {
        const newTemplate = {
            daysOfWeek: [],
            remark: '',
            homeworks: [{}]
        };
        this.templates.push(newTemplate);
        this.addTemplateForm(newTemplate);
    }

    handleDeleteTemplate(index: number) {
        this.templates.splice(index, 1);
        this.templatesForm.removeAt(index);
    }

    handleSelectDayOfWeek(index: number, day: ResourcePushTemplateDayOfWeek) {
        const templatesForm = this.templatesForm.at(index);
        const daysOfWeekControl = templatesForm.get('daysOfWeek');
        const currentDays = [...(daysOfWeekControl?.value || [])];

        if (currentDays.includes(day)) {
            currentDays.splice(currentDays.indexOf(day), 1);
        } else {
            currentDays.push(day);
        }
        
        daysOfWeekControl?.setValue(currentDays);
        this.templates[index].daysOfWeek = currentDays;
    }

    handleAddHomework(templatesIndex: number) {
        const newHomework = {
            type: null,
            resourceId: null,
            resourceSubId: null,
        };
        this.templates[templatesIndex].homeworks.push(newHomework);
    }

    handleDeleteHomework(templatesIndex: number, homeworkIndex: number) {
        this.templates[templatesIndex].homeworks.splice(homeworkIndex, 1);
    }

    isDaySelected(templatesIndex: number, day: ResourcePushTemplateDayOfWeek): boolean {
        return this.templates[templatesIndex].daysOfWeek?.includes(day) || false;
    }

    validateForm(): boolean {
        let isValid = true;

        // 验证周表单
        this.templatesForm.controls.forEach((templatesForm) => {
            if (templatesForm.invalid) {
                isValid = false;
                templatesForm.markAllAsTouched();
            }
        });

        // 验证作业组件
        this.homeworkComponents.forEach(component => {
            if (!component.validate()) {
                isValid = false;
            }
        });

        return isValid;
    }

    async handleSubmit() {
        if (this.saving) {
            return;
        }
        if (!this.validateForm()) {
            this.message.error('请检查表单填写是否正确');
            return;
        }
        const data = this.templates.map((templates, index) => {
            const templatesForm = this.templatesForm.at(index);
            return {
                categoryId: this.categoryId,
                subCategoryId: this.categorySubId,
                startingPoint: this.startingPoint,
                seq: index + 1,
                homeworks: templates.homeworks,
                daysOfWeek: templatesForm.get('daysOfWeek')?.value,
                remark: templatesForm.get('remark')?.value,
            };
        }) as ResourcePushWeekTemplate[];
        
        this.saving = true;
        try {
            await this.homeworkPushTemplatesService.update(this.categoryId, this.categorySubId, this.startingPoint, data);
            this.message.success('保存成功');
            this.fetchData();
        } catch (error) {
            this.message.error('保存失败');
        } finally {
            this.saving = false;
        }
    }
}
