<div>
  <nz-spin style=" overflow: hidden;width: 100%; height: 100%;" [nzSpinning]="isLoadingMediaData">
    <div class="video-box">
      <div class="video-wrapper">
        <video #mediaElement  [ngStyle]="{display: isVideo ? 'flex': 'none'}"></video>
<!--        <audio #mediaElement *ngIf="!isVideo"></audio>-->
        <div class="loading" [ngStyle]="{display: isMediaWaiting ? 'flex': 'none'}">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>
    <div class="button-box"  >
      <div class="content">
        <div class="center" >
          <div style="display: flex; line-height: 36px;">
            <span style="width: 300px;margin-right: 20px">{{currentAudioTime}}/{{currentAudioDuration}}</span>
            <div style="display: flex; line-height: 36px;">

              <span>播放速度：</span>&nbsp;
              <span  style="width: 150px;">
              <nz-slider [(ngModel)]="playbackRate"
                         (ngModelChange)="changePlaybackRate($event)"
                         [nzMax]="2" [nzMin]="0.25" [nzStep]="0.25"></nz-slider>
            </span>

            </div>
            &nbsp;
            &nbsp;
            <div style="position: absolute;right: 0;">
              <button nz-button nzSize="default"
                      nzType="primary"
                      (click)="togglePlay($event)">
                <ng-container *ngIf="isPlaying"><i nz-icon nzType="pause" nzTheme="outline"></i>暂停</ng-container>
                <ng-container *ngIf="!isPlaying"><i nz-icon nzType="caret-right" nzTheme="outline"></i>播放</ng-container>
              </button>
              &nbsp;
              <button nz-button nzSize="default"
                      nzType="primary"
                      id="enterbtn"
                      [disabled]="!canSetTimeBlock"
                      (click)="setTimestampPhase(null, true)">
                <!-- setTimestampPhase setTimestampPoint-->
                <!--                            {{isSettingTimeBlockPoint ? '设置结束点' : '设置起始点'}}-->
                插入配音
              </button>
              &nbsp;
              <!--                        <button nz-button nzSize="default"-->
              <!--                                nzType="danger"-->
              <!--                                (click)="saveUserData()"><i nz-icon nzType="cloud-upload" nzTheme="outline"></i>保存</button>-->
            </div>
          </div>



        </div>
        <div class="timestamp-container">
          <ng-template #insertLineContentTemplate>
            <div>
              <p>Content</p>
              <p>Content</p>
            </div>
          </ng-template>
          <div class="timestamp-line"
               (click)="selectTimePoint(i)"
               [ngClass]="{selected: selectHighlightTimePointIndex === i}"
               *ngFor="let item of timePointData; let i = index">
            <div class="time-tag" [ngClass]="{warn: item.warn}">
              [{{convertTimeToTag(item.startTime) || ''}}
              ~
              {{convertTimeToTag(item.endTime) ||  ''}}]
            </div>

            <!--                        <div class="add-line" style="margin-right: 12px;">-->
            <!--                            <button nz-tooltip nzTooltipTitle="向后插入一行" nz-button nzType="danger" nzSize="small" nzShape="circle"-->
            <!--                                    (click)="insertTimePoint(i)">-->
            <!--                                <i nz-icon nzType="plus" nzTheme="outline"></i>-->
            <!--                            </button>-->
            <!--                        </div>-->
            <div class="time-content">
              <!--              <app-upload-image-with-preview-->
              <!--                [picUrl]="item.data"-->
              <!--                (imageUploaded)="onImageUploadSuccess($event)"-->
              <!--              ></app-upload-image-with-preview>-->
              <input nz-input (blur)="lostFocus($event)" [(ngModel)]="item.text" />
            </div>
            <!--                        <div class="line-break">-->
            <!--                            <label nz-checkbox nz-tooltip nzTitle="添加换行" [(ngModel)]="item.newLine"></label>-->
            <!--                        </div>-->
            <div class="time-del">
              <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="removeTimePoint(i)">
                <i nz-icon nzType="delete" nzTheme="outline"></i>
              </button>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="wave-player" [ngStyle]="{visibility: mediaUrl ? 'visible' : 'hidden'}" >

      <div class="scroll-tool">
        <canvas #rulerCanvasEl style="width: 100%"></canvas>
        <div class="thumbs-bar">
          <canvas #canvasEl  *ngIf="thumbNumbers > 0" [ngStyle]="{height: waveHeight + 'px'}" style="width: 100%"></canvas>
          <div class="point-line" [ngStyle]="{height: waveHeight + 'px'}">
            <div
              *ngFor="let item of timePointData; let i = index" class="time-block"
              [ngClass]="{selected: selectHighlightTimePointIndex === i}"
              [ngStyle]="{left: timeToPosition(item.startTime) + 'px',
                     width:  durationToWidth(item.endTime - item.startTime) + 'px',
                     zIndex: selectHighlightTimePointIndex === i ? 1 : 0}"
              [attr.data-index]="i"
              appCustomDrag
              [b4Down]="highlightSelect()"
              [move]="blockOnMove()"
              [constraint]="blockMoveRange()" >
              <div class="block-dd dd-left " appCustomDrag
                   [attr.data-index]="i"
                   [b4Down]="blockLeftB4Down()"
                   [constraint]="blockLeftRange()"
                   [start]="blockLeftStart()"
                   [move]="blockLeftMove()">
                <i nz-icon nzType="pause" nzTheme="outline"></i>
              </div>
              <div class="block-dd dd-right" appCustomDrag
                   [attr.data-index]="i"
                   [b4Down]="blockRightB4Down()"
                   [constraint]="blockRightRange()"
                   [start]="blockRightStart()"
                   [move]="blockRightMove()">
                <i nz-icon nzType="pause" nzTheme="outline"></i>
              </div>

            </div>
          </div>
        </div>

        <div class="time-line"
             appCustomDrag
             style="transform: translateX(0px);"
             [move]="ctrlBarOnMove"
             #timeLineEl >
          <div class="ctrl-bar drag-bar">
          </div>
          <div class="play-bar"></div>
        </div>

      </div>

      <div *ngIf="false">
        <nz-slider nzRange style="flex: 1" [nzTipFormatter]="formatter" [nzStep]="0.01" [nzMax]="timeRangeObj.max" [nzMin]="timeRangeObj.min"
                   (nzOnAfterChange)="timeRangeAfterChange($event)"
                   [(ngModel)]="timeRangeSelector"></nz-slider>
        <div style="width: 100%;
    position: relative;
    height: 30px;
    display: flex;
    flex-direction: row-reverse;">
          <button [disabled]="!isScaleTimeLine" style="margin-right: 8px;" nz-button nzSize="small"
                  (click)="restoreTimeLine()"
                  nzType="primary">
            返回</button>
          <button style="margin-right: 8px;" nz-button nzSize="small"
                  (click)="scaleTimeLine()"
                  nzType="primary">
            缩放时间轴</button>
        </div>
      </div>

    </div>
  </nz-spin>
</div>
