<div class="table-operations">
    <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/v3/resources/audio/add'">
        <i nz-icon nzType="plus" nzTheme="outline"></i>添加新音频套系</button>
</div>
<nz-table #classTable [nzData]="displayData"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalNumber">
    <thead>
    <tr>
        <th>id</th>
        <th>套系名称</th>
        <th>音频数目</th>
        <th style="text-align: center">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <td>{{data.id}}</td>
        <td>{{data.name}}</td>
        <td>{{data.item_num}}</td>
        <td style="width:10rem">
            <div class="operations">

                <a (click)=" editData(data.id)">编辑</a>
                <a nz-popconfirm nzPopconfirmTitle="确定删除？"
                   (nzOnConfirm)="deleteData(data.id)" >
                    删除
                </a>
            </div>

        </td>
    </tr>
    </tbody>
</nz-table>
