import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, UntypedFormControl } from '@angular/forms';
import { CoursewareService } from '../../../../../services/courseware.service';
import { BookItemBarComponent } from './bar';
import { TwNoticeService } from '../../../../../services/tw-notice.service';
import { HWResourceService } from '../../../../../services/huawei-resource.service';
import * as _ from 'lodash';
import { SyllabusService } from '../../../../../services/syllabus.service';
import { MediaV2Service } from '../../../../../services/media-v2.service';

@Component({
  selector: 'app-admin-resource-book-form',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceBookFormComponent implements OnInit {
  user: any = null;
  constUserType = UserType;
  currentSyllabusId = 0;
  currentCourseId = 0;

  isLoading = false;
  form!: UntypedFormGroup;

  syllabusList: { syllabus_id: number, course_id: number}[] = [];


  book_id = 0;
  bookAttrs: any = {};
  syllabusChildren = [];
  syllabusKeyValueItems: any = {};

  rootThemesKeyValueItems: any = {};
  subThemesKeyValueItems: any = {};

  relTags: any[] = [];


  bisType = 'cartoon';
  tabIndex = 0;
  items_status: any = {};
  // readingStatus = false;
  // classroomReadingStatus = false;

  host = '';
  protocol = '';
  bucket = '';

  itemService!: CoursewareService|MediaV2Service;

  constructor(private userService: UserService,
              private clsService: ClassService,
              private syllabusService: SyllabusService,
              private coursewareService: CoursewareService,
              private mediaService: MediaV2Service,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    const u = new URL(window.location.href);
    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;
    this.activatedRoute.title.subscribe({
      next: (title) => {
      }
    });
    console.log('[form] this.activatedRoute', this.activatedRoute.pathFromRoot[2].data);
    this.bisType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    if (this.bisType === 'video' || this.bisType === 'audio') {
      this.itemService = this.mediaService;
    } else {
      this.itemService = this.coursewareService;
    }
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.tabIndex && parseInt(query.tabIndex, 10) === query.tabIndex * 1) {
        this.tabIndex = parseInt(query.tabIndex, 10);
      }
    });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });

    this.form = this.fb.group({
      id: [ this.book_id ],
      // syllabus_id: [ '', [ Validators.required ] ],
      // course_id: [ '', [ Validators.required ] ],
      // theme: [ [] , [Validators.required] ],
      // level: [ '', [Validators.required]  ],
      // themes: this.fb.array([]),
      name: [ '' , [ Validators.required ]],
      cover: [ '', [ Validators.required ] ],
      url: [ '', (['audio', 'video'].includes(this.bisType) ? [Validators.required] : null) ],
      tagsGroupArray: this.fb.array([])
    });
    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.book_id = params['book_id'] || 0;

    });
    // @ts-ignore
    window.aaa = this;
  }

  clearVideo($event: any) {}
  onVideoUploadFailure($event: any) {}
  onVideoOverSize($event: any) {
    this.nzMessageService.error('too large');
  }
  onVideoCoverUploadSuccess(evt: any) {
    this.form.controls['cover'].setValue(evt.url);
  }
  onVideoUploadSuccess(evt: any) {
    if (this.bisType === 'video') {
      this.form.controls['url'].setValue(evt.url);
    }
  }

  clearAudio($event: any) {
    console.log('clearAudio', $event);
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
  onAudioUploadFailure(evt: any) {
    console.log('onAudioUploadFailure', evt);
  }
  onAudioUploadSuccess(evt: any) {
    console.log('onAudioUploadSuccess', evt);
    if (this.bisType === 'audio') {
      this.form.controls['url'].setValue(evt.url);
    }

  }





  // get themes() {
  //   return this.form.get('themes') as FormArray;
  // }
  get tagsGroupArray () {
    return (this.form.get('tagsGroupArray') || []) as FormArray;
  }

  addTagsGroup({syllabus_id = 0, course_id = 0}: {syllabus_id?: number, course_id?: number, tags: any[]}) {
    console.log('addTagsGroup');
    const tagFb = this.fb.group({
      syllabus_id: this.fb.control(+syllabus_id, Validators.required),
      course_id: this.fb.control(+course_id, Validators.required),
    });
    this.tagsGroupArray.push(tagFb);

  }
  removeTagsGroup(index: number): void {
    console.log('removeTagsGroup', index);
    this.tagsGroupArray.removeAt(index);
  }
  clearTagsField({prefix = '', parent = null}: {prefix?: string, parent?: FormGroup|null}) {
    if (!parent) {
      parent = this.form;
    }
    const keys = Object.keys(parent.controls);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith(`${prefix}.`)) {
        parent.removeControl(keys[i]);
      }
    }
  }
  addTagsField({id, value = null, prefix = '', parent = null}: {id: any, value?: any, prefix?: string,  parent?: FormGroup|null}): void {
    if (!parent) {
      parent = this.form;
    }
    const tagsControl = [];
    parent.addControl(
      `${prefix}.` + id,
      // new UntypedFormControl(null, Validators.required)
      new UntypedFormControl(value)
    );
  }

  ngOnInit() {
    this.isLoading = true;
    // this.coursewareService.bookAttrs(this.bisType).then((bookAttrs) => {
    this.syllabusService.category(this.bisType).then((bookAttrs) => {
      console.log('bookAttrs', bookAttrs);
      const _themesKeyValueItems: any = {};
      bookAttrs.themes.forEach((theme: any) => {
        if (!_themesKeyValueItems[theme.syllabus_id] ) {
          _themesKeyValueItems[theme.syllabus_id] = [];
        }
        _themesKeyValueItems[theme.syllabus_id].push(theme);
      });





      const _syllabusKeyValueItems: any = {};
      const _rootThemesKeyValueItems: any = {};

      bookAttrs.syllabus.forEach((syllabus: any) => {
        if (!_syllabusKeyValueItems[syllabus.id] ) {
          _syllabusKeyValueItems[syllabus.id] = [];
        }
        if (_themesKeyValueItems[syllabus.id]) {

          _rootThemesKeyValueItems[syllabus.id] = _themesKeyValueItems[syllabus.id];
          delete _themesKeyValueItems[syllabus.id];
        }
        _syllabusKeyValueItems[syllabus.id].push(syllabus);
      });
      this.rootThemesKeyValueItems = _rootThemesKeyValueItems;
      this.subThemesKeyValueItems = _themesKeyValueItems;
      this.syllabusKeyValueItems = _syllabusKeyValueItems;

      const _allThemesKeyValueItems: any = {};
      const tagsGroupItems = _.groupBy(bookAttrs.themes, 'syllabus_id');
      console.log('tag group ', tagsGroupItems);

      this.bookAttrs = bookAttrs;
      if (this.book_id) {
        this.itemService.fetch(this.bisType, this.book_id).then((result) => {
          console.log('itemService.fetch', result);
          this.form.controls['name'].setValue(result.name);
          const cover = (result.cover && (result.cover.startsWith('http') ? result.cover : this.protocol + '//' + this.host + '/' + (this.bucket ? this.bucket + '/' : '') + result.cover));
          this.form.controls['cover'].setValue(cover);

          if (result.url && this.form.controls['url'] ) {
            const url = (result.url && (result.url.startsWith('http') ? result.url : this.protocol + '//' + this.host + '/' + (this.bucket ? this.bucket + '/' : '') + result.url));
            this.form.controls['url'].setValue(url);
          }


          this.items_status = result.items_status;
          this.relTags = result.themes;

          this.relTags.forEach((tagGroup: any, i: number) => {
            this.addTagsGroup(tagGroup );
            this.changeSyllabus(i, tagGroup.syllabus_id);
            this.changeCourse(i, tagGroup.course_id);

            // syllabusChildren
            // const groupArray = this.form.controls['tagsGroupArray'] as FormArray;
            // const group = groupArray.at(groupArray.length - 1) as FormGroup;
            // tagGroup.tags.forEach((v: any) => {
            //   this.addTagsField({id: v.tag_group_id , value: v.tag_id, prefix: 'tag_group_id', parent: group});
            // });
          });




          this.isLoading = false;
        }).catch(err => {
            throw err;
        });
      } else {
        this.isLoading = false;
      }

    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  currentSubOptions (group_index: number) {
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    const syllabus_id = group.controls['syllabus_id'];
    const item = this.bookAttrs.syllabus.find((s: any) => {
      return +s.id === +syllabus_id.value;
    });
    return  item?.children || [];
  }

  currentRootTags() {

  }

  changeSyllabus(group_index: number, syllabus_id: number) {
    console.log('changeSyllabus', group_index, syllabus_id);
    // if (!(+syllabus_id)) {
    //   return;
    // }
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    group.controls['course_id'].setValue('');
    this.clearTagsField({prefix: 'tag_group_id', parent: group});
    // this.form.controls['course_id'].setValue('');
    if (!syllabus_id) {
      this.currentSyllabusId = 0;
      this.currentCourseId = 0;
      this.syllabusChildren = [];
      return;
    }
    const item = this.bookAttrs.syllabus.find((s: any) => {
      return +s.id === +syllabus_id;
    });
    this.syllabusChildren = item ? item.children : [];
    this.currentSyllabusId = syllabus_id;
    const selectedTags = this.rootThemesKeyValueItems[syllabus_id] || [];
    // this.rootSyllabusThemes = this.syllabusKeyValueItems[syllabus_id] || [];
    const tagGroupItem = this.relTags.find(tag => +tag.syllabus_id == +syllabus_id);
    selectedTags.forEach((tag: any) => {
      // const value = tag.children.map((v: any) => v.id);
      const tagItem = tagGroupItem.tags.find((s: any) => {
        return s.tag_group_id === +tag.id;
      });
      this.addTagsField({
        id: tag.id,
        value: tagItem?.tag_id ,
        prefix: 'tag_group_id',
        parent: group});
    });
  }

  changeCourse(group_index: number, course_id: number) {
    console.log('changeCourse', group_index, course_id);
    // if (!(+course_id)) {
    //   return;
    // }
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    // group.controls['course_id'].setValue(+course_id, {emitViewToModelChange: false});
    group.controls['course_id'].setValue(+course_id);
    this.clearTagsField( {prefix: 'tag_id', parent: group});
    if (!course_id) {
      this.currentCourseId = 0;
      return;
    }

    const item = this.bookAttrs.themes.filter((s: any) => {
      return +s.syllabus_id === +course_id;
    });
    this.currentCourseId = course_id;
    const selectedTags = this.subThemesKeyValueItems[course_id] || [];
    const tagGroupItem = this.relTags.find(t => +t.course_id == +course_id);
    selectedTags.forEach((tag: any) => {
      // const value = tag.children.map((v: any) => v.id);
      const tagItem = tagGroupItem.tags.find((s: any) => {
        return s.tag_group_id === +tag.id;
      });
      this.addTagsField({
        value: tagItem?.tag_id,
        id: tag.id,
        prefix: 'tag_id',
        parent: group});
    });
  }

  tabIndexChange($evt: any) {
    console.log('tabIndexChange', $evt, this.tabIndex);
    this.tabIndex = $evt;
  }
  noticeReadingStatus() {
    this.twNoticeService.warning({nzContent: '請先上傳Reading素材'});
  }
  setCover(event: any) {
    this.form.controls['cover'].setValue(event.url);
  }

  submitForm = ($event: any, value: any) => {
    if ($event) {
      $event.preventDefault();
    }
    if (this.isLoading) {
      return;
    }
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
    }
    console.log('submitForm', $event, value);
    this.isLoading = true;
    this.itemService.save(this.bisType, value).then((result) => {
      this.isLoading = false;
      const modal = this.twNoticeService.success({nzContent: '保存成功'});
      modal.afterClose.subscribe(() => {
        // this.router.navigate([`/admin/v3/resource/${this.bisType}/courseware`]);
      });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsPristine();
      this.form.controls[ key ].updateValueAndValidity();
    }
  }

  dragStart($evt: any) {
    console.log('dragStart', $evt);
  }
  dragging($evt: any) {
    console.log('dragging', $evt);
  }

  protected readonly UserStatus = UserStatus;
  protected readonly Boolean = Boolean;
  protected readonly JSON = JSON;
  protected readonly Object = Object;
  protected readonly Array = Array;
}
