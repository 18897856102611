import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-ngx-drag-and-resize',
  template: ` <p>ngx-drag-and-resize works!</p> `,
  styles: [],
})
export class NgxDragAndResizeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
