import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '../../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { HWResourceService as ResourceService } from '../../../services/huawei-resource.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-upload-audio-v2',
  templateUrl: './upload-audio-v2.component.html',
  styleUrls: ['./upload-audio-v2.component.scss']
})
export class UploadAudioV2Component implements OnChanges {
  audio = new Audio();
  uploading = false;
  progress = 0;
  @Input()
  audioUrl = '';
  @Input()
  errors = 'error';
  @Output()
  audioUploaded = new EventEmitter();
  @Output()
  audioUploadFailure = new EventEmitter();


  @Input()
  displayText = 'Click here to upload audio';

  @Input() displayTextTemplate?: TemplateRef<any>;

  uploadStatus = '';
  @Input()
  audioItem: any = null;
  @Input()
  showUploadBtn = true;

  constructor(
    private nzMessageService: NzMessageService,
    private http: HttpClient,
    private userService: UserService,
    private resService: ResourceService
  ) {
  }
  ngOnChanges() {
    // if (!this.audioItem.type) {
    //   return;
    // }
    // this.customUpload(this.audioItem);
  }
  customUpload = (item?: any) => {
    if (!item) {
      return false;
    }
    this.audioUrl = '';
    if (!this.checkSelectFile(item)) {
      return false;
    }
    this.uploading = true;
    this.progress = 0;
    let path = 'audios/';
    if (!environment.production) {
      path = 'dev/' + path;
    }
    // this.resService.doUpload(item as any, /* ResType.AUDIO,*/ path,
    //   this.doProgress , this.uploadSuccess , this.uploadFailure );
    this.resService.doUpload({file: item,
      osspath: path,
      progress_cb: this.doProgress,
      success_cb: this.uploadSuccess,
      error_cb: this.uploadFailure});
    return false;
  }
  uploadSuccess = (name: string, hash: string, url: string, file?: File, inOSS = false) => {
    this.nzMessageService.info('Upload Success');
    // this.updateStatus(false);
    this.uploading = false;
    // this.updateSource(url);
    this.audioUrl = url;
    // this.uploadFinished(url);
    // this.audioUploaded.emit({name, hash, url});
    const a = new Audio();
    a.preload = 'metadata';
    a.src = url;
    a.addEventListener('loadedmetadata', () => {

      let duration = a.duration; // the duration variable now holds the duration (in seconds) of the audio clip
      a.preload = 'none';
      a.src = '';
      a.remove();
      if (duration) {
        duration = duration * 1000;
        // @ts-ignore
        file['duration'] = duration;
        this.audioUploaded.emit({ name, hash, url, file, inOSS});
      }

    });
    /*
    if (!inOss) {
      const a = new Audio();
      a.preload = 'metadata';
      a.src = url;
      a.addEventListener('loadedmetadata', () => {

        let duration = a.duration; // the duration variable now holds the duration (in seconds) of the audio clip
        a.preload = 'none';
        a.src = '';
        a.remove();
        if (duration) {
          duration = duration * 1000;
          file['duration'] = duration;
          this.resService.updateAudio(id, {duration}).then(() => {
            this.audioUploaded.emit({id, name, hash, url});
          });
        }

      });
    } else {
      this.audioUploaded.emit({id, name, hash, url});
    }*/
  }
  uploadFailure = (err: any, file: any) => {
    this.uploading = false;
    if (err.name && err.name === 'cancel') {
      return;
    }
    console.log(err);
    this.nzMessageService.error('Upload Error ' + err.message);
    this.audioUploadFailure.emit(file);
  }

  doProgress = (p: number) => {
    if (p > 1) {
      p = 1;
    }
    if (p < 0) {
      p = 0;
    }
    // console.log(Math.floor(p * 100));
    this.progress =  Math.floor(p * 100);
  }
  checkSelectFile(file: any) {
    if (!file) {
      return;
    }
    console.log(file.type);
    const isAudio = ['audio/mp3', 'audio/wav', 'audio/ogg', , 'audio/mpeg'].includes(file.type);
    if (!isAudio) {
      this.nzMessageService.error('You can only upload Audio file ( mp3 | wav |ogg)');
      return;
    }
    const delta =  25;
    const isOverSize = (file.size / 1024 / 1024) < delta;
    if (!isOverSize) {
      this.nzMessageService.error(`audio file  must smaller than ${delta}MB!`);
      return false;
    }
    return true;
  }

}
