import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadComponent } from 'ng-zorro-antd/upload';
import { DomSanitizer } from '@angular/platform-browser';
// import {_coursewareService} from '../../../services/courseware.service';
// import {ResourceService} from '../../../services/resource.service';
import { HWResourceService as ResourceService } from '../../../services/huawei-resource.service';
import { environment } from '../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MediaDelegate } from '../../../directives/media-delegate';
import { DragElement } from '../../../directives/drag-element';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { SystemService } from '../../../services/system.service';
import { UserService } from '../../../services/user.service';
import { SchoolService } from '../../../services/school.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';
import { QupeiyinService } from '../../../services/qupeiyin.service';

@Component({
  selector: 'app-wave-player',
  templateUrl: './wave-player.component.html',
  styleUrls: ['./wave-player.component.scss']
})
export class WavePlayerComponent implements OnChanges, OnInit {
  // @ts-ignore
  @ViewChild('canvasEl', {static: true }) canvasElRef: ElementRef;
  // @ts-ignore
  @ViewChild('rulerCanvasEl', {static: true }) rulerCanvasEl: ElementRef;
  // @ts-ignore
  @ViewChild('timeLineEl', {static: true }) timeLineEl: ElementRef;

  // DUBData = {
  //   media_url: '',
  //   size: 0,
  //   width: 0,
  //   height: 0,
  //   duration: 0,
  //   timePointData: []
  // };
  @Input()  timePointData: any[] = [];
  @Input()  rulerHeight = 80;
  @Output()  timePointDataChange = new EventEmitter();
  @Output()  duration = new EventEmitter();

  @Input()  mediaUrl!: string;

  _duration = 0;
  canSetTimeBlock = true;
  isLoadingMediaData = false;
  isMediaWaiting = false;
  timeRangeObj = {
    min: 0,
    max: 100
  };
  isScaleTimeLine = false;

  selectHighlightTimePointIndex = -1;
  waveWidth!: number;
  waveHeight!: number;
  ctx!: CanvasRenderingContext2D;
  adContext = new AudioContext();
  timeLineTimer!: number;
  timeLine: any;
  playbackRate = 1;
  // @ts-ignore
  private audioArrayBuffer: ArrayBuffer;
  timePointDataFull: any;

  private currentTimeRangDuration!: number;
  private timeRangeEditLines!: number[];



  @ViewChild('mediaElement', {static: true }) mediaElement!: ElementRef;

  timeRangeSelector = [0, 100];
  currentAudioTime = '00:00:00.000';
  currentAudioDuration = '00:00:00.000';

  isLoadingFormMeta = true;
  isFormMetaDataLoaded = false;

  hasChanges = true;
  mediaDelegate!: MediaDelegate;
  thumbNumbers = 0;

  private isSettingTimeBlockPoint: 'A'|'B'|null = null;

  private mediaPlayBarPositionSub!: Subscription;
  private mediaDataLoadedSub!: Subscription;
  private mediaPlayTimestampSub!: Subscription;
  private mediaPlayEndSub!: Subscription;
  private dragMoveBarSub!: Subscription;
  private arrowKeyUpSub!: Subscription;
  private loadMediaSub!: Subscription;
  private mediaLoadingSub!: Subscription;
  private mediaPlayingSub!: Subscription;

  isVideo = false;



  constructor(private sysSrv: SystemService,
              private userSrv: UserService,
              private schoolSrv: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private client: HttpClient,
              private sanitization: DomSanitizer,
              private modalService: NzModalService,
              private fb: UntypedFormBuilder,
              private qupeiyinSvc: QupeiyinService,
              private resourceSvc: ResourceService,
              private nzMessageService: NzMessageService) {
  }



  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.drawRulerBar();
  }

  ngOnInit() {

    // if (this.editId) {
    //
    //   this.qupeiyinSvc.getItem(this.editId).then(r => {
    //     console.log(r);
    //     this.editData = r;
    //     this.DUBData.video_url = r.url;
    //     this.isLoadingMediaData = true;
    //     this.currentStep = 1;
    //
    //   });
    // }
  }
  initStep1() {
    console.log('initStep1', this.mediaElement.nativeElement);
    this.timeLineEl.nativeElement.style = 'transform: translateX(0px);';
    this.isLoadingMediaData = true;

    this.mediaDelegate = new MediaDelegate(this.mediaElement.nativeElement);

    if (this.mediaLoadingSub) {
      this.mediaLoadingSub.unsubscribe();
    }
    this.mediaLoadingSub = this.mediaDelegate.mediaWaiting.subscribe((e: any) => {
      this.isMediaWaiting = true;
      console.log('waiting');
    });
    if (this.mediaPlayingSub) {
      this.mediaPlayingSub.unsubscribe();
    }
    this.mediaLoadingSub = this.mediaDelegate.mediaPlaying.subscribe((e: any) => {
      if (this.isMediaWaiting) {
        console.log('playing');
        this.isMediaWaiting = false;
      }
    });


    if (this.mediaPlayBarPositionSub) {
      this.mediaPlayBarPositionSub.unsubscribe();
    }
    // @ts-ignore
    this.mediaPlayBarPositionSub = this.mediaDelegate.mediaPlayBarPosition.subscribe((evt) => {
      this.calcPlayBarPositionByTime(evt.time);
    });

    if (this.mediaDataLoadedSub) {
      this.mediaDataLoadedSub.unsubscribe();
    }
    // @ts-ignore
    this.mediaDataLoadedSub = this.mediaDelegate.mediaDataLoaded.subscribe(() => {
      console.log('this.mediaDelegate.mediaDataLoaded');
      // @ts-ignore
      this._duration = this.mediaDelegate.duration;
      this.duration.emit(this._duration);
      // this.width = this.mediaElement.nativeElement.videoWidth || 0;
      // this.height = this.mediaElement.nativeElement.videoHeight || 0;
      this.timeRangeObj.max = this.mediaDelegate.duration;
      // this.dimensions = this.mediaDelegate.dimensions;
      // this.timeRangeSelector[1] = this.mediaDelegate.duration;
      this.timeRangeSelector = [0, this.mediaDelegate.duration];
      this.currentAudioDuration = this.mediaDelegate.durationFormatted;
      this.drawToolBar();

    });

    if (this.mediaPlayTimestampSub) {
      this.mediaPlayTimestampSub.unsubscribe();
    }

    this.mediaPlayTimestampSub = this.mediaDelegate.mediaPlayTimestamp.subscribe((evt: any) => {
      this.currentAudioTime = evt.timeFormat;
      let time = evt.currentTime;


      if (this.isScaleTimeLine) {
        if ( evt.startTime >= this.timeRangeSelector[0]) {
          time = evt.startTime - this.timeRangeSelector[0];
        }
        if (evt.startTime >= this.timeRangeSelector[1]) {
          this.mediaDelegate.pause();
          this.mediaDelegate.currentTime = evt.startTime; //  - 5 * 60 / 1000;
          this.calcPlayBarPositionByTime(this.timeRangeSelector[0]);
        }
      } else {
        this.calcPlayBarPositionByTime(time);
      }
    });

    if (this.mediaPlayEndSub) {
      this.mediaPlayEndSub.unsubscribe();
    }
    this.mediaPlayEndSub = this.mediaDelegate.mediaPlayEnd.subscribe(() => {
      this.calcPlayBarPositionByTime(0);
      this.selectHighlightTimePointIndex = -1;
    });
    this.mediaDelegate.src = this.mediaUrl;

  }
  lostFocus(evt: any) {
    console.log(evt);
    this.selectHighlightTimePointIndex = -1;
  }
  isInvaildSents() {
    // console.log(this.DUBData.timePointData);
    const zeroSents = this.timePointData.filter((s: any) => {
      return s.startTime === s.endTime || !s.text.trim();
    });
    return zeroSents.length > 0;
  }
  drawRulerBar(timeStart?: any, timeEnd?: any) {
    if (!timeStart) {
      timeStart = 0;
    }
    if (!timeEnd) {
      timeEnd = this.mediaDelegate.duration;
    }

    const rulerHeight = this.rulerHeight ;
    const dpr = window.devicePixelRatio || 1;
    const ruleCtx = this.rulerCanvasEl.nativeElement.getContext('2d');

    const w = this.rulerCanvasEl.nativeElement.offsetWidth;
    this.rulerCanvasEl.nativeElement.width = w * dpr;
    this.rulerCanvasEl.nativeElement.height = rulerHeight * dpr;
    this.rulerCanvasEl.nativeElement.style.height = `${rulerHeight}px`;



    // this.ctx.lineWidth = 5;
    const blank = (20 * dpr);
    const keyStep = 5;
    const keyPointNum = Math.ceil(w * dpr  / (keyStep * blank) );
    const t = (timeEnd - timeStart) / keyPointNum;
    let pointCounter = 0;
    ruleCtx.beginPath();
    ruleCtx.font = `${12 * dpr}px arial`;
    for (let i = 0; i < w * dpr; i += blank) {
      let y = 80;

      if ((i / (keyStep * blank) === parseInt(String(i / (keyStep * blank)), 10)) ) {
        // const y = (i / 100 === parseInt(String(i / 100), 10)) ? 100 : 120;
        const lbl = this.mediaDelegate.convertTimeToTag(t * pointCounter , false);
        pointCounter += 1;

        const s = ruleCtx.measureText(lbl);
        const lw = s.width;
        // const lh = s.height;
        ruleCtx.fillText(lbl, i - lw / 2, 30 );
        y = 60;
      }
      ruleCtx.moveTo(i, y);
      ruleCtx.lineTo(i, 100);
    }
    ruleCtx.stroke();
  }
  drawToolBar(timeStart?: any, timeEnd?: any) {
    this.drawRulerBar(timeStart, timeEnd);
    if (!timeStart) {
      timeStart = 0;
    }
    if (!timeEnd) {
      timeEnd = this.mediaDelegate.duration;
    }

    const dpr = window.devicePixelRatio || 1;



    let thumbWidth = 0;
    if (this.thumbNumbers) {
      this.ctx = this.canvasElRef.nativeElement.getContext('2d');
      this.waveWidth = this.canvasElRef.nativeElement.offsetWidth;
      const {width, height} = this.mediaDelegate.dimensions;
      thumbWidth = Math.ceil(this.waveWidth / this.thumbNumbers) ;
      const thumbHeight = (thumbWidth * height / width );
      this.waveHeight = thumbHeight; //  + ruleHeight;
      this.canvasElRef.nativeElement.width = this.waveWidth * dpr;
      this.canvasElRef.nativeElement.height = this.waveHeight * dpr;
      this.canvasElRef.nativeElement.style.height = `${this.waveHeight}px`;
    } else {
      this.waveHeight = this.rulerHeight;
    }





    const range = timeEnd - timeStart;
    const thumbTimeOffset = Math.ceil((range * 1000) / this.thumbNumbers);
    let thumbTime = 1;
    let finished = 0;
    if (this.isVideo && this.thumbNumbers > 0) {
      for (let i  = 0; i < this.thumbNumbers; i++) {
        const img = new Image();
        // @ts-ignore
        img.idx = i;
        img.addEventListener('load', (e) => {
          const target = e.target as HTMLImageElement;
          // @ts-ignore
          console.log(target.src, target.idx, i, thumbWidth * target.idx);
          // @ts-ignore
          this.ctx.drawImage(target, thumbWidth * target.idx * dpr , 0);
          finished++;
          if (finished === this.thumbNumbers) {
            this.isLoadingMediaData = false;
          }
        });
        img.addEventListener('error', (e) => {
          finished++;
          if (finished === this.thumbNumbers) {
            this.isLoadingMediaData = false;
          }
        });

        img.src = `${this.mediaUrl}?vframe/jpeg/offset/${Math.floor(thumbTime / 1000)}/w/${parseInt(String(thumbWidth * dpr), 10)}`;
        thumbTime += thumbTimeOffset;
      }
    } else {
      this.isLoadingMediaData = false;
    }

    this.timeLine = this.timeLineEl.nativeElement;

    /* if (this.dragMoveBarSub) {
       this.dragMoveBarSub.unsubscribe();
     }
     this.dragMoveBarSub = new DragElement(this.timeLine, {range: [0, this.waveWidth], check:  () => {
         return !this.isPlaying;
       }}).onMove.pipe(
       // sampleTime(100),
     ).subscribe(evt => {
       // this.selectHighlightTimePointIndex = -1;
       const percent = evt.position / this.waveWidth;
       let baseDur = this.mediaDelegate.duration;
       let startPoint = 0;
       let currentTime = percent * this.mediaDelegate.duration;
       if (this.isScaleTimeLine) {
         baseDur = this.currentTimeRangDuration;
         startPoint = this.timeRangeObj.min;
         currentTime = this.timeRangeObj.min + percent * baseDur;
       }
       this.mediaDelegate.currentTime = currentTime;
       this.currentAudioTime = this.mediaDelegate.currentTimeFormatted();



       this.calcPlayBarPositionByTime(currentTime);

     });*/

  }
  ctrlBarOnMove = (evt: any) => {
    const percent = evt.position / this.waveWidth;
    let baseDur = this.mediaDelegate.duration;
    let startPoint = 0;
    let currentTime = percent * this.mediaDelegate.duration;
    if (this.isScaleTimeLine) {
      baseDur = this.currentTimeRangDuration;
      startPoint = this.timeRangeObj.min;
      currentTime = this.timeRangeObj.min + percent * baseDur;
    }
    this.mediaDelegate.currentTime = currentTime;
    this.currentAudioTime = this.mediaDelegate ? this.mediaDelegate.currentTimeFormatted() : '';



    this.calcPlayBarPositionByTime(currentTime);

  }
  ngOnChanges(value: any) {
    console.log('ngOnChanges', value);
    if (value['mediaUrl'].currentValue.toLowerCase().endsWith('.mp4')) {
      this.isVideo = true;
    } else {
      this.isVideo = false;
    }
    this.initStep1();

    // if (value.LRCData && !value.LRCData.firstChange) {
    //   this.ngOnInit();
    //   // this.onAudioUploaded({url: this.LRCData.audio_url});
    // }
    // console.log('ngOnChanges', JSON.stringify(value)); // this.ngOnInit();
  }
  blockMoveRange() {
    return (dd: any) => {
      const i = +dd.dragEl.dataset.index;
      if ( i === 0) {
        // let maxX = this.waveWidth - this.timePointData[i].width;
        const cw = this.durationToWidth((this.timePointData[i] as any).endTime - (this.timePointData[i] as any).startTime );
        let maxX = this.waveWidth - cw;
        if (this.timePointData[i + 1]) {
          // maxX = this.timePointData[i + 1].position - this.timePointData[i].width;
          maxX = this.timeToPosition((this.timePointData[i + 1] as any).startTime) - cw;
        }
        return [0,  maxX];
      } else if (i === this.timePointData.length - 1) {
        const prevPhaseWidth = this.durationToWidth((this.timePointData[i - 1] as any).endTime
          - (this.timePointData[i - 1] as any).startTime);
        const prevPhaseStart = this.timeToPosition((this.timePointData[i - 1] as any).startTime);
        // const minX = this.timePointData[i - 1].position + this.timePointData[i - 1].width;
        const minX = prevPhaseStart + prevPhaseWidth;
        const nextPhaseWidth = this.durationToWidth((this.timePointData[i] as any).endTime
          - (this.timePointData[i] as any).startTime);
        // const maxX  = this.waveWidth - this.timePointData[i].width;
        const maxX  = this.waveWidth - nextPhaseWidth;
        return [ minX, maxX ];
      } else {
        // const minX = this.timePointData[i - 1].position + this.timePointData[i - 1].width;
        const minX = this.timeToPosition((this.timePointData[i - 1] as any).startTime) +
          this.durationToWidth((this.timePointData[i - 1] as any).endTime
            - (this.timePointData[i - 1] as any).startTime);
        // const maxX  = this.timePointData[i + 1].position - this.timePointData[i].width;
        const maxX  = this.timeToPosition((this.timePointData[i + 1] as any).startTime) -
          this.durationToWidth((this.timePointData[i] as any).endTime
            - (this.timePointData[i] as any).startTime);
        return [ minX, maxX ];
      }
    };
  }
  timeToPosition(time: number) {
    return this.getXPositionByTime(time);
  }
  positionToTime(posX: number) {
    const percent = posX / this.waveWidth;
    const currentTime = percent * this.mediaDelegate.duration;
    return currentTime;
    // const posMove = this.getXPositionByTime(currentTime);
  }
  durationToWidth(duration: number) {
    const w = duration * this.waveWidth / this.mediaDelegate.duration;
    return w;

  }
  getPhaseRange(item: any) {
    const start = this.timeToPosition(item.startTime);
    const width = this.durationToWidth(item.endTime - item.startTime);
    return {
      start, width, end: start + width
    };
  }

  highlightSelect() {
    return (dd: any) => {
      const idx = +dd.dragEl.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // 还得把当前控制条，挪到这个位置
      // this.movePlayBarToPos(this.timePointData[idx].position);
      // @ts-ignore
      this.movePlayBarToPos(this.timeToPosition(this.timePointData[idx].startTime));
      return true;
    };
  }
  highlightSelect__(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    if (evt.target !== evt.currentTarget) {
      return;
    }
    const idx = +evt.target.dataset.index;
    this.selectHighlightTimePointIndex = idx;
    // 还得把当前控制条，挪到这个位置
    // this.movePlayBarToPos(this.timePointData[idx].position);
    this.movePlayBarToPos(this.timeToPosition((this.timePointData[idx] as any).startTime));
    return true;
  }


  blockOnMove() {
    return ({dd, position}: {dd: DragElement, position: any}) => {
      // @ts-ignore
      const i = +dd?.dragEl?.dataset.index;
      // if (!position || this.timePointData[i].position === position) {
      //   return;
      // }
      // @ts-ignore
      const dur = this.timePointData[i].endTime - this.timePointData[i].startTime;
      const t1 = this.positionToTime(position);
      // const t2 = this.positionToTime(position + this.timePointData[i].width);
      const t2 = t1 + dur;
      // @ts-ignore
      this.timePointData[i].startTime = t1;
      // this.timePointData[i].startTimeFormatted = this.mediaDelegate.convertTimeToTag(t1, false);
      // @ts-ignore
      this.timePointData[i].endTime = t2;
      // this.timePointData[i].endTimeFormatted = this.mediaDelegate.convertTimeToTag(t2, false);
    };
  }

  convertTimeToTag(t: any) {
    return this.mediaDelegate ? this.mediaDelegate.convertTimeToTag(t, false) : '';
  }
  movePlayBarToPos (position: any) {
    const currentTime = this.positionToTime(position);
    this.mediaDelegate.currentTime = currentTime;
    this.calcPlayBarPositionByTime(currentTime);
    return currentTime;
  }

  blockLeftB4Down() {
    return (dd: any) => {
      const idx = +dd.dragEl.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // this.movePlayBarToPos(this.timePointData[idx].position);
      // @ts-ignore
      this.movePlayBarToPos(this.timeToPosition(this.timePointData[idx].startTime));
      // const currentTime = this.positionToTime(this.timePointData[i].position);
      // this.mediaDelegate.currentTime = currentTime;
      // this.calcPlayBarPositionByTime(currentTime);
      // console.log('b4Down', dd.dragEl, idx);
      return true;
    };
  }
  blockLeftRange() {
    const widthPerSec = this.waveWidth / this.mediaDelegate.duration;
    return (dd: any) => {
      const i = +dd.dragEl.dataset.index;
      let minX = 0;
      let maxX = this.waveWidth;
      if ( i === 0) {
        const left = parseInt(dd.dragEl.parentElement.style.left, 10);
        // @ts-ignore
        const w = this.durationToWidth(this.timePointData[i].endTime - this.timePointData[i].startTime);
        // maxX = left + this.timePointData[i].width;
        maxX = left + w;
      } else if (i === this.timePointData.length - 1) {
        // const {position, width}  = this.timePointData[this.timePointData.length - 2];
        // minX = position + width;
        const prevItem = this.timePointData[this.timePointData.length - 2];
        const prevPosInfo = this.getPhaseRange(prevItem);
        minX = prevPosInfo.end;
        const currPosInfo = this.getPhaseRange(this.timePointData[i]);
        // maxX  = this.timePointData[i].position + this.timePointData[i].width;
        maxX  = currPosInfo.end;
        // return [ position + width, maxX ];

      } else {
        // minX = this.timePointData[i - 1].position + this.timePointData[i - 1].width;
        const prev = this.getPhaseRange(this.timePointData[i - 1]);
        minX = prev.end;
        // maxX  = this.timePointData[i].position + this.timePointData[i].width;
        const curr = this.getPhaseRange(this.timePointData[i]);
        maxX  = curr.end;
      }
      return [ minX, maxX/* - widthPerSec */];
    };


  }
  blockLeftMove() {
    // const maxX = this.timePointData[i].position + this.timePointData[i].width;
    return ({dd, position}: {dd: any, position: any}) => {
      const i = +dd.dragEl.dataset.index;
      // if (!position || this.timePointData[i].position === position) {
      //   return;
      // }
      // const time = this.positionToTime(position);

      // this.timePointData[i].width += this.timePointData[i].position - position;
      // this.timePointData[i].position = position;
      const t1 = this.movePlayBarToPos(position);
      // @ts-ignore
      this.timePointData[i].startTime = t1;
      // this.timePointData[i].startTimeFormatted = this.mediaDelegate.convertTimeToTag(t1, false);
    };
  }
  blockLeftStart() {
    return (dd: DragElement, event: any) => {
      let w = 0;
      // @ts-ignore
      if (dd.dragEl.parentElement.style.left) {
        // @ts-ignore
        w = parseInt(dd.dragEl.parentElement.style.left, 10);
        // const matrix = new DOMMatrix(this.dragEl.style.transform);
        // const w = matrix.m41;
      }
      dd.relX = event.pageX - w;
    };
  }





  blockRightB4Down() {
    return (dd: any) => {
      const idx = +dd.dragEl.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // this.movePlayBarToPos(this.timePointData[idx].position + this.timePointData[idx].width);
      this.movePlayBarToPos(this.timeToPosition((this.timePointData[idx] as any).endTime));
      console.log('blockRightB4Down', dd.dragEl, idx);
      return true;
    };
  }
  blockRightRange() {
    const widthPerSec = this.waveWidth / this.mediaDelegate.duration;
    return (dd: any) => {
      const i = +dd.dragEl.dataset.index;
      let minX = 0;
      let maxX = this.waveWidth;
      if ( i === 0) {
        if (this.timePointData[i + 1]) {
          // maxX = this.timePointData[i + 1].position; // + this.timePointData[i + 1].width;
          // @ts-ignore
          maxX = this.timeToPosition(this.timePointData[i + 1].startTime); // + this.timePointData[i + 1].width;
        }
        const curr = this.getPhaseRange(this.timePointData[i]);
        // minX = this.timePointData[i].position;
        minX = curr.start;
      } else if (i === this.timePointData.length - 1) {
        // const {position, width}  = this.timePointData[this.timePointData.length - 1];
        const last = this.getPhaseRange(this.timePointData[this.timePointData.length - 1]);
        // minX = position;
        minX = last.start;
      } else {
        const curr = this.getPhaseRange(this.timePointData[i]);
        const next = this.getPhaseRange(this.timePointData[i + 1]);
        // minX = this.timePointData[i].position;
        // maxX  = this.timePointData[i + 1].position; // - this.timePointData[i].width ;
        minX  = curr.start ;
        maxX  = next.start;
      }
      return [ minX/* + widthPerSec*/, maxX ];
    };


  }
  blockRightMove() {
    return ({dd, position}: {dd: any, position: any}) => {
      const i = +dd.dragEl.dataset.index;
      // if (!position || this.timePointData[i].position === position) {
      //   return;
      // }
      // const t2 = this.movePlayBarToPos(this.timePointData[i].position + this.timePointData[i].width);
      const t2 = this.positionToTime(position);
      this.movePlayBarToPos(position);
      // @ts-ignore
      this.timePointData[i].endTime = t2;
      // this.timePointData[i].endTimeFormatted = this.mediaDelegate.convertTimeToTag(t2, false);
    };
  }
  blockRightStart() {
    return (dd: DragElement, event: any) => {
      // @ts-ignore
      const i = +dd.dragEl.dataset.index;
      let w = 0;
      // @ts-ignore
      if (dd.dragEl.parentElement.style.left) {
        // @ts-ignore
        w = parseInt(dd.dragEl.parentElement.style.left, 10) + dd.dragEl.parentElement.offsetWidth;
        // const matrix = new DOMMatrix(this.dragEl.style.transform);
        // const w = matrix.m41;
      }
      dd.relX = event.pageX - w ; // + this.timePointData[i].width;
    };
  }




  ngOnDestroy(): void {
    if (this.mediaPlayBarPositionSub) {
      this.mediaPlayBarPositionSub.unsubscribe();
    }
    if (this.mediaDataLoadedSub) {
      this.mediaDataLoadedSub.unsubscribe();
    }
    if (this.mediaPlayTimestampSub) {
      this.mediaPlayTimestampSub.unsubscribe();
    }
    if (this.mediaPlayEndSub) {
      this.mediaPlayEndSub.unsubscribe();
    }
    if (this.dragMoveBarSub) {
      this. dragMoveBarSub.unsubscribe();
    }
    if (this.arrowKeyUpSub) {
      this. arrowKeyUpSub.unsubscribe();
    }
    if (this.loadMediaSub) {
      this.loadMediaSub.unsubscribe();
    }

  }

  ngAfterViewInit() {
    this.timeLine = this.timeLineEl.nativeElement;

    // this.timeLineBar = this.timeLineBarEl.nativeElement;
    this.waveWidth = this.rulerCanvasEl.nativeElement.offsetWidth;
    this.waveHeight = this.rulerCanvasEl.nativeElement.offsetHeight;
    this.ctx = this.rulerCanvasEl.nativeElement.getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    this.rulerCanvasEl.nativeElement.width = this.waveWidth * dpr;
    this.rulerCanvasEl.nativeElement.height = this.waveHeight * dpr;
    this.ctx.scale(dpr, dpr);
    const cb = (e: any) => {
      if (e.code === 'ArrowUp'
        || e.code === 'ArrowDown'
        || e.code === 'ArrowLeft'
        || e.code === 'ArrowRight') {
        e.preventDefault();
        e.stopPropagation();
        // set time point
      }
    };
    document.removeEventListener('keydown', cb );
    document.addEventListener('keydown', cb );
    if (this.arrowKeyUpSub) {
      this.arrowKeyUpSub.unsubscribe();
    }
    this.arrowKeyUpSub = fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(throttleTime(100) )
      .subscribe((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.code === 'ArrowUp') {
          this.togglePlay();
          this.canvasElRef.nativeElement.focus();
        }
        if (e.code === 'ArrowDown') {

          this.setTimestampPoint();
          this.canvasElRef.nativeElement.focus();
          // set time point
        }
        if (e.code === 'ArrowLeft') {
          if (this.selectHighlightTimePointIndex >= 0) {
            this.updateTimePointData(this.selectHighlightTimePointIndex, -0.05);
          } else {
            this.mediaDelegate.currentTime -= 1;
          }
          this.canvasElRef.nativeElement.focus();

        }
        if (e.code === 'ArrowRight') {
          if (this.selectHighlightTimePointIndex >= 0) {
            this.updateTimePointData(this.selectHighlightTimePointIndex, 0.05);
          } else {
            this.mediaDelegate.currentTime += 1;
          }
          this.canvasElRef.nativeElement.focus();
        }
      });

    this.ctx.fillStyle = '#0F0';
    if (this.dragMoveBarSub) {
      this.dragMoveBarSub.unsubscribe();
    }
    this.dragMoveBarSub = new DragElement(this.timeLine, {range: [0, this.waveWidth]})
      .onMove.subscribe(evt => {
        this.selectHighlightTimePointIndex = -1;
        const percent = evt.position / this.waveWidth;
        let baseDur = this.mediaDelegate.duration;
        let startPoint = 0;
        let currentTime = percent * this.mediaDelegate.duration;
        if (this.isScaleTimeLine) {
          baseDur = this.currentTimeRangDuration;
          startPoint = this.timeRangeObj.min;
          currentTime = this.timeRangeObj.min + percent * baseDur;
        }
        this.mediaDelegate.currentTime = currentTime;
        console.log('dd', baseDur, this.mediaDelegate.currentTime  );
        this.currentAudioTime = this.mediaDelegate ? this.mediaDelegate.currentTimeFormatted() : '';
        this.calcPlayBarPositionByTime(currentTime);

      });

  }
  get isPlaying() {
    return this.mediaDelegate && this.mediaDelegate.isPlaying;
  }
  updateTimePointData(index: number, time: number) {
    const p = this.timePointData[index] as any;
    if (p) {
      const newTime = p.startTime + time;
      let posTime = newTime;
      if (this.isScaleTimeLine) {
        posTime = posTime - this.timeRangeObj.min;
      }
      this.mediaDelegate.currentTime = newTime;
      p.startTime = newTime;
      // p.position = this.calcPlayBarPositionByTime(posTime, true);
      // p.startTimeFormatted = this.mediaDelegate.currentTimeFormatted(newTime);
      if (p.startTime > this.mediaDelegate.duration) {
        p.warn = true;
      }
    }
  }
  getXPositionByTime(time: number) {
    let base = this.mediaDelegate.duration;
    if (this.currentTimeRangDuration) {
      time = time - this.timeRangeObj.min;
      if (time < 0) {
        time = 0;
      }
      base = this.currentTimeRangDuration;
    }
    return ((time / base) * this.waveWidth) ;
  }
  // @ts-ignore
  calcPlayBarPositionByTime(time: number, returnVal = false) {
    // return

    const posMove = this.getXPositionByTime(time);
    // console.log(x);
    const pos = `translateX(${posMove}px)`;
    if (returnVal) {
      return pos;
    }
    this.timeLine.style.transform = pos;

    // 检测 是否有重叠情况
    const idx = this.timePointData.findIndex((t: any, index) => {
      // if (index === this.selectHighlightTimePointIndex) {
      //   return false;
      // }
      if (this.isSettingTimeBlockPoint === 'A' && index < this.selectHighlightTimePointIndex) {
        return time < t.endTime;
      }
      if (this.isSettingTimeBlockPoint === 'B' && index > this.selectHighlightTimePointIndex ) {
        return time > t.startTime;
      }
      return time > t.startTime && time < t.endTime;
    });
    if (idx >= 0) {
      this.canSetTimeBlock = false;
    } else {
      this.canSetTimeBlock = true;
    }

    // if (!this.isSettingTimeBlockPoint) {
    //   return;
    // }
    // // const posMove = this.getXPositionByTime(time);
    // const currentLeft = this.timePointData[this.selectHighlightTimePointIndex].position;
    // const currentWidth = this.timePointData[this.selectHighlightTimePointIndex].position;
    // if (posMove <= currentLeft) {
    //   if (!this._tmpPosition) {
    //     this._tmpPosition = currentLeft;
    //     // 交换 a b 点时间
    //     [this.timePointData[this.selectHighlightTimePointIndex].startTime,
    //       this.timePointData[this.selectHighlightTimePointIndex].startTimeFormatted,
    //       this.timePointData[this.selectHighlightTimePointIndex].endTime,
    //       this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted
    //     ]
    //       = [
    //       null,
    //       null,
    //       this.timePointData[this.selectHighlightTimePointIndex].startTime,
    //       this.timePointData[this.selectHighlightTimePointIndex].startTimeFormatted,
    //     ];
    //   }
    //   this.isSettingTimeBlockPoint = 'A';
    //
    //
    // } else if (this._tmpPosition && posMove >= this._tmpPosition ) {
    //   this.isSettingTimeBlockPoint = 'B';
    //   // 交换 a b 点时间
    //   [this.timePointData[this.selectHighlightTimePointIndex].startTime,
    //     this.timePointData[this.selectHighlightTimePointIndex].startTimeFormatted,
    //     this.timePointData[this.selectHighlightTimePointIndex].endTime,
    //     this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted
    //   ]
    //     = [
    //     this.timePointData[this.selectHighlightTimePointIndex].endTime,
    //     this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted,
    //     null,
    //     null
    //   ];
    // }
    // if (this.isSettingTimeBlockPoint === 'B') {
    //   if (this._tmpPosition) {
    //     this.timePointData[this.selectHighlightTimePointIndex].position = this._tmpPosition;
    //     this._tmpPosition = 0;
    //   }
    //   this.timePointData[this.selectHighlightTimePointIndex].width = posMove - currentLeft;
    // }
    //
    // if (this.isSettingTimeBlockPoint === 'A') {
    //   this.timePointData[this.selectHighlightTimePointIndex].position = posMove;
    //   this.timePointData[this.selectHighlightTimePointIndex].width = this._tmpPosition - posMove ;
    // }
  }
  togglePlay(evt?: any) {
    if (evt) {
      evt.target.blur();
    }
    console.log('togglePlayAudio', evt);
    if (this.isPlaying) {
      this.mediaDelegate.pause();
      clearInterval(this.timeLineTimer);
      return;
    }

    let t1 = null;
    let t2 = null;
    if (this.isScaleTimeLine
      && (this.mediaDelegate.currentTime < this.timeRangeSelector[0]
        || this.mediaDelegate.currentTime >= this.timeRangeSelector[1] )) {
      // this.mediaDelegate.currentTime = this.timeRangeSelector[0];
      t1 = this.timeRangeSelector[0];
      t2 = this.timeRangeSelector[1];
    }


    if (this.selectHighlightTimePointIndex >= 0) {
      // 刚插入点，还没拖拽出段
      if ((this.timePointData[this.selectHighlightTimePointIndex] as any).startTime
        === (this.timePointData[this.selectHighlightTimePointIndex] as any).endTime) {
        t1 = (this.timePointData[this.selectHighlightTimePointIndex] as any).startTime;
      } else {
        const p = this.timePointData[this.selectHighlightTimePointIndex] as any;
        this.mediaDelegate.currentTime = p.startTime;
        t1 = p.startTime;
        t2 = p.endTime;
      }
    }
    this.mediaDelegate.play(t1, t2).then(() => {
      // this.timeLineTimer = window.setInterval(() => {
      //   if (canSetPoint) {
      //     console.log(audio.currentTime,audioImages[audio.currentTime] )
      //     audioImages.push([audio.currentTime, pointAudio]);
      //     canSetPoint = false;
      //   }
      // this.timeLine.style.width = `${Math.ceil((this.mediaDelegate.currentTime / this.mediaDelegate.duration) * 100) }%`;
      // }, 32);
    });
  }

  setTimestampPoint(timelineData: any = null, select = false) {
    if (!this.mediaDelegate.currentSrc) {
      return;
    }
    let pointTime = this.mediaDelegate.currentTime;
    if (!this.isSettingTimeBlockPoint) {
      this.isSettingTimeBlockPoint = 'B';
      // console.log('setTimestampPoint');

      let text = '';
      let newLine = false;
      if (timelineData) {
        pointTime = timelineData.startTime;
        text = timelineData.text;
        newLine = timelineData.newLine;
      }
      let warn = false;
      if (pointTime > this.mediaDelegate.duration || pointTime < 0) {
        warn = true;
      }

      const tmpAllTime = this.timePointData.map((d: object) => {
        return {...d};
      });
      // 两句起始间隔
      // tmpAllTime.forEach(p => {
      //   p.startTimeFormatted = this.mediaDelegate.convertTimeToTag(p.startTime);
      //   const delta =  Math.abs(p.startTime - p.startTime ) * 1000;
      //   if (delta < 2000) {
      //     p.warn = true;
      //   }
      // });

      // if (this.isScaleTimeLine) {
      //   pointTime = pointTime - this.timeRangeObj.min;
      // }
      const pointData = {
        startTime:  pointTime,
        endTime:  pointTime,
        text,
        warn,
        newLine
      };
      tmpAllTime.push(pointData);
      tmpAllTime.sort((a: any, b: any) => {
        return a.startTime - b.startTime;
      });
      this.timePointData.length = 0;
      let createNewPointIndex = -1;
      tmpAllTime.forEach((item, index) => {
        this.timePointData.push(item);
        if (item === pointData) {
          createNewPointIndex = index;
        }
      });


      if (select && createNewPointIndex > -1) {
        this.selectHighlightTimePointIndex = createNewPointIndex;
      }
    } else {
      this.isSettingTimeBlockPoint = null;
      (this.timePointData[this.selectHighlightTimePointIndex] as any).endTime = pointTime;
      // this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted = this.mediaDelegate.convertTimeToTag(pointTime, false);
      this.selectHighlightTimePointIndex = -1;



      // this.timePointData.forEach(p => {
      //   p.startTimeFormatted = this.mediaDelegate.convertTimeToTag(p.startTime, false);
      //   const delta =  Math.abs(p.startTime - p.endTime ) * 1000;
      //   if (delta < 2000) {
      //     p.warn = true;
      //   }
      // });
      console.log(this.timePointData);
    }



  }

  setTimestampPhase(timelineData: any = null, select = false) {
    if (!this.mediaDelegate.currentSrc) {
      return;
    }
    let startTime = this.mediaDelegate.currentTime;
    let endTime = startTime;
    if (timelineData) {
      startTime = timelineData.startTime;
      endTime = timelineData.endTime;
    }

    const perSecWidth = this.waveWidth / this.mediaDelegate.duration;

    const item = {
      text: '',
      // position: Math.ceil(this.getXPositionByTime(pointTime/* - 1*/)),
      startTime: startTime, //  - 1,
      // startTimeFormatted: this.mediaDelegate.currentTimeFormatted(pointTime/* - 1*/),
      endTime: endTime, //  + 1,
      // endTimeFormatted: this.mediaDelegate.currentTimeFormatted(pointTime/* + 1*/),
      // width: 0, // perSecWidth * 2,
    };
    (this.timePointData as any).push(item);
    this.timePointData.sort((a: any, b: any) => {
      return a.startTime - b.startTime;
    });
    this.selectHighlightTimePointIndex = this.timePointData.findIndex((t) => {
      return t === item;
    });
    this.canSetTimeBlock = false;
  }


  insertTimePoint(index: number) {
    const st =  (this.timePointData[index] as any).startTime;
    let et = st + 5;
    const next = this.timePointData[index + 1];
    if (next) {
      et = (next as any).startTime;
    }
    const time = st + (et - st) / 2;
    // if (this.isScaleTimeLine) {
    //   time = time + this.timeRangeObj.min;
    // }
    this.mediaDelegate.currentTime = time;
    this.setTimestampPoint({
      time,
      data: ''
    });

    // this.timePointData.splice(index + 1, 0, {
    //   time,
    //   timeFormatted: this.mediaEl.currentTimeFormatted(time),
    //   data: '',
    //   position: `translateX(${ this.getXPositionByTime(time)}px)`,
    // } );
    this.selectHighlightTimePointIndex = index + 1;
  }
  removeTimePoint(index: number) {
    this.timePointData.splice(index, 1);
    this.selectHighlightTimePointIndex = -1;
  }
  selectTimePoint(index: number) {
    this.selectHighlightTimePointIndex = index;
    this.mediaDelegate.currentTime = (this.timePointData[index] as any).startTime;
  }










  checkNeedBack() {
    if (this.isScaleTimeLine) {
      this.restoreTimeLine();
    }
  }
  changePlaybackRate(val: any) {
    this.mediaDelegate.playbackRate = val;
  }
  onImageUploadSuccess(evt: any) {

  }

  formatter = (value: number): string =>  {
    if (!this.mediaDelegate)  {
      return '';
    }
    return this.mediaDelegate.convertTimeToTag(value, false);
  }
  timeRangeAfterChange(value: any) {
    this.timeRangeSelector = value;
  }
  scaleTimeLine() {
    this.mediaDelegate.pause();
    this.selectHighlightTimePointIndex = -1;
    this.isScaleTimeLine = true;
    if (!this.timePointDataFull) {
      this.timePointDataFull = this.timePointData.map((line: object) => {
        return {...line};
      });
    }

    // this.timeRangeModeConfObj.startTime = this.timeRangeSelector[0];
    // this.timeRangeModeConfObj.endTime = this.timeRangeSelector[0];
    const start = this.timeRangeSelector[0];
    const end = this.timeRangeSelector[1];
    // const sp = start / this.mediaDelegate.duration;
    // const ep = end / this.mediaDelegate.duration;
    // const sl = sp * this.audioArrayBuffer.byteLength;
    // const el = ep * this.audioArrayBuffer.byteLength;
    // this.currentTimeRangDuration = end - start;
    // this.timeRangeObj.max = end;
    // this.timeRangeObj.min = start;
    // this.calcPlayBarPositionByTime(start);
    //
    // this.timeRangeSelector = [start, end];
    // this.timeRangeEditLines = [];
    // this.timePointData = this.timePointData.filter( (line, idx) => {
    //   if (line.startTime > start && line.startTime < end) {
    //     this.timeRangeEditLines.push(idx);
    //     return true;
    //   }
    //   return false;
    // }).map( line => {
    //   line.position = this.calcPlayBarPositionByTime( line.startTime , true);
    //   return line;
    // });
    // const ab = this.audioArrayBuffer.slice(sl, el);
    // this.drawArrayBuffer(ab);
    this.drawToolBar(start, end);
  }
  restoreTimeLine() {
    this.mediaDelegate.pause();
    this.timeRangeObj.max = this.mediaDelegate.duration;
    this.timeRangeObj.min = 0;
    this.timeRangeSelector = [0, this.mediaDelegate.duration];
    this.selectHighlightTimePointIndex = -1;
    this.isScaleTimeLine = false;
    this.currentTimeRangDuration = this.mediaDelegate.duration;
    if (this.timeRangeEditLines.length) {
      const head = this.timePointDataFull.slice(0, Math.min(...this.timeRangeEditLines) );
      const tail = this.timePointDataFull.slice( Math.max(...this.timeRangeEditLines) + 1 );
      // this.timePointData = [...head, ...this.timePointData, ...tail];
      // @ts-ignore
      this.timePointData = [...head, ...this.timePointData, ...tail].map(line => {
        return {...line};
      }).sort((a, b) => {
        return a.startTime - b.startTime;
      });
    } else {
      this.timePointData = [...this.timePointDataFull, ...this.timePointData];
    }
    // @ts-ignore
    this.timePointData = this.timePointData.map((line: object) => {
      // line.position = this.calcPlayBarPositionByTime(line.startTime, true);
      return {...line};
    });
    this.timePointDataFull = null;
    // const ab = this.audioArrayBuffer.slice(0);
    // this.drawArrayBuffer(ab);
    this.drawToolBar();
  }




  safeUrl(url: string) {
    if (this.mediaDelegate && this.mediaDelegate.src === url) {
      return;
    }

    if (!url) {
      return;
    }
    console.log('safeUrl', url);
    return this.sanitization.bypassSecurityTrustUrl(url);
  }







  // submitForm(): void {
  //   console.log('submitForm');
  // }
}

