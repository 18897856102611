import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class ResourceLibraryService extends BaseService {
  constructor(protected override http: HttpClient,  private userService: UserService, protected override router: Router) {
    super(http, router);
  }

  bookAttrs(bis_type: string) {
    return super.get(`/courseware/book/attribute?bis_type=${bis_type}`);
  }

  list(resource_type: string, query: any) {
    const url = `/resource/library/${resource_type}/list`;
    let queryStr = '';
    if (query) {
      queryStr = `?${this.objectToQueryString(query)}`;
    }
    return super.get(`${url}${queryStr}`);
  }

  fetch(resource_type: string, id: number) {
    return super.get(`/resource/library/${resource_type}/item/${id}`);
  }

  save(resource_type: string, data: any) {
    return super.post( `/resource/library/${resource_type}/item/save`, data);
  }



}
