export interface IRect {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface IRegion {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
export interface IRegion2 {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IArea {
  rect: IRect;
  region: IRegion;
  region2: IRegion2;
  [key: string]: IRect|IRegion|IRegion2;
}


export const getRegionAndRect = (containerElement: Element, handle: Element, xDiff = 0, yDiff = 0): IArea => {
  // const container = this.boundsContainer!.getBoundingClientRect();
  // const rect = this.elRef.nativeElement.getBoundingClientRect();
  // const style = window.getComputedStyle(this.elRef.nativeElement);
  const container = containerElement.getBoundingClientRect();
  const rect = handle.getBoundingClientRect();
  const style = window.getComputedStyle(handle);
  const matrix = new DOMMatrixReadOnly(style.transform);
  const oldX = matrix.m41;
  const oldY = matrix.m42;
  const w = rect.width;
  const h = rect.height;
  const x = oldX + xDiff;
  const y = oldY + yDiff;
  const x1 = x / container.width;
  const y1 = y / container.height;
  const x2 = (oldX + w) / container.width;
  const y2 = (oldY + h) / container.height;
  const width = w / container.width;
  const height = h / container.height;
  return {
    rect: { x, y, w, h},
    region: {x1, x2, y1, y2},
    region2: {x: x1, y: y1, width, height}
  };
  // return { x, y, w, h};
  // return  {x1, x2, y1, y2};
};

