import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';




@Component({
  selector: 'touch-to-read-config',
  template: `
    <div class="p-8" style="padding-left: 8px;">
      <div nz-row nzGutter="32">
        <div nz-col nzSpan="12">
          <nz-affix [nzOffsetTop]="150"  #affix>
            <div
              #containerRef
              id="boundingContainerDiv"
              class="w-full h-auto img-editor overflow-hidden relative"
              [ngStyle]="{
                  'width': 100+'%',
                  'aspect-ratio': imgWidth / imgHeight ,
                  'background-image': 'url(' + (imageUrl | mediaUrlPipe) + ')'}"
            >

              <div
                *ngFor="let region of hotzones; let j = index"
                (click)="anchorRegion($event, j)"
                draggableElement
                resizableElement
                [container]="containerRef"
                [(region)]="region.rect"
                class="region-handler"
                style="position: absolute"
                [enable]="j == this.currentRegionIndex"
                [ngClass]="{active: j == this.currentRegionIndex}"
              >
                <div class="region-handler-ident">{{j + 1}}</div>
              </div>
            </div>
          </nz-affix>
        </div>
        <div nz-col nzSpan="12">
          <div class="main-text-color font-bold text-lg mb-4">标记音频对应位置</div>
          <div class="text-slate-400 font-light text-xs mb-4">注：请用图形工具框出每句的范围位置，一个方框对应一个音频文件。</div>
          <button nz-button [nzType]="'primary'" class="mr-4 mb-8"
                  (click)="addImageRegion()"
                  [nzSize]="'default'" nzGhost>
            <span nz-icon  nzType="edit" nzTheme="fill" class="fix-icon"></span>
            新增点读区域
          </button>
          <div (cdkDropListDropped)="drop($event)" cdkDropList cdkDropListOrientation="vertical">
            <div *ngFor="let region of hotzones; let k = index" cdkDrag class="bg-white">
              <div class="flex p-4" [id]="'regionCfg'+k">
                <!--                      [ngClass]="{'bg-amber-100': k == currentRegionIndex}"-->
                <div class="flex-1">
                  <div class="main-text-color font-bold text-lg mb-4 flex">
                    <div class="flex-1">內容{{k + 1}}</div>
                    <div class="flex-none">
                            <span nz-icon class="fix-icon" style="color: #1890FF;cursor: pointer;"
                                  (click)="anchorRegion($event, k)"
                                  nzType="link"
                                  nzTheme="outline"></span>
                      <span nz-icon nzType="pause" nzTheme="outline" class="sort-handler" cdkDragHandle></span>
                      <span nz-icon style="color: #1890FF;cursor: pointer;"
                            class="fix-icon"
                            (click)="removeRegion($event, k)"
                            nzType="close"
                            nzTheme="outline"></span>
                    </div>

                  </div>
                  <textarea rows="4" nz-input [(ngModel)]="region.text" class="mb-4"></textarea>
                  <div class="main-text-color font-bold text-lg mb-4">音频文件{{k + 1}}</div>
                  <tw-audio-uploader
                    [audioUrl]="region.audio"
                    [audioName]="region.audioName"
                    comment="注：音频格式为 MP3 文件，音频文件大小限制为 10MB。"
                    (clear)="clearRegionAudio($event, k)"
                    (audioUploadFailure)="onAudioUploadFailure($event, k)"
                    (audioUploaded)="onAudioUploadSuccess($event, k)">
                  </tw-audio-uploader>
                </div>
                
              </div>

              <nz-divider></nz-divider>
            </div>

          </div>

        </div>
      </div>
    </div>
  `,
  styles: [
    `

      .fix-icon {
        vertical-align: 0.125em;
      }
      ::ng-deep nz-tabset[nztype='line'] .ant-tabs-nav-list>.ant-tabs-tab:first-child{
        margin-bottom: 16px;
      }

      .img-editor {
        background-size: contain;
      }

      .region-handler{
        position: absolute;
        width: 100px;
        height: 100px;
        border: 1px solid rgba(255, 0, 0, .3);

      }
      .region-handler.active{
        border: 2px solid red;
        background: transparent;
        outline: rgba(255, 255, 255, .3) solid 100vw;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 5px 1px;
        border-radius: 8px;
      }
      .region-handler-ident{
        width: 16px;
        height: 16px;
        display: block;
        background: red;
        border-radius: 8px;
        box-shadow: 1px 1px 1px #333;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }

      .region-handler.active:before{
        display: none;
      }

      .sort-handler{
        transform: rotate(90deg);
        vertical-align: 0;
        color: #1890FF;
        cursor: move;
        margin: 0 8px 0 8px;
        font-size: 24px;
      }

    `
  ]
})
export class TouchToReadConfigComponent implements OnInit, AfterViewInit {

  @Input() imgWidth!: number;
  @Input() imgHeight!: number;
  @Input() imageUrl!: string;
  @Input() hotzones!: any[];
  @Input() currentRegionIndex!: number;


  @Output() anchorRegionEvent = new EventEmitter();
  @Output() addImageRegionEvent = new EventEmitter();
  @Output() dropEvent = new EventEmitter();
  @Output() removeRegionEvent = new EventEmitter();
  @Output() clearRegionAudioEvent = new EventEmitter();
  @Output() onAudioUploadFailureEvent = new EventEmitter();
  @Output() onAudioUploadSuccessEvent = new EventEmitter();

  ngOnInit() {
    console.log('TouchToReadConfigComponent init');
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit init');
  }

  anchorRegion(evt: any, index: number) {
    this.anchorRegionEvent.emit({evt, index});
  }
  addImageRegion() {
    this.addImageRegionEvent.emit();
  }
  drop(event: CdkDragDrop<string[]>): void {
    this.dropEvent.emit(event);
  }
  removeRegion(evt: any, index: number): void {
    this.removeRegionEvent.emit({evt, index});
  }
  clearRegionAudio(evt: any, index: number): void {
    this.clearRegionAudioEvent.emit({evt, index});
  }
  onAudioUploadFailure(evt: any, index: number): void {
    this.onAudioUploadFailureEvent.emit({evt, index});
  }
  onAudioUploadSuccess(evt: any, index: number): void {
    this.onAudioUploadSuccessEvent.emit({evt, index});
  }
}
