import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, NavigationError, Params, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { UserType } from '../../model/Const';

interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  tabs: any[];
  breadcrumb = ['首页'];
  isCollapsed = true;
  triggerTemplate = null;
  menuPath = '';
  user: any;
  // @ts-ignore
  breadcrumbs: IBreadcrumb[];
  constructor( private userService: UserService,
               private router: Router,
               private activatedRoute: ActivatedRoute ,
               private permission: PermissionService) {
    console.log('admin component not load');
    this.user = userService.getUserInfo();
    const cfg: any = this.router.config;
    this.menuPath = 'admin';
    // if (this.user?.type === UserType.SCHOOL_ACCOUNT_AGENT) {
    //   this.menuPath = 'agent';
    // } else if (this.user?.type === UserType.SCHOOL_ADMIN) {
    //   this.menuPath = 'school';
    // }

    // @ts-ignore
    const admin_router_cfg = cfg.find(item => {
      if (item.path === this.menuPath) {
        return item.children;
      }
    });
    // debugger
    this.tabs = this.getMenu(admin_router_cfg);
    // console.log('menu', this.tabs);
    router.events.subscribe((val) => {

      if (val instanceof NavigationError) {
        return;
      }

      // TODO check token expire and role
      if (val instanceof NavigationEnd) {

        let url = val.urlAfterRedirects.replace('/admin/', '');
        url = url.replace(/\/\d+/g, '');
        url = url.replace(/\?.*$/g, '');

        // console.log('url2: ', url);

        const parts = url.split('/');
        // console.log('parts: ', parts);

        const nameArr = [];
        let tmpArr = this.tabs;
        for (let i = 0; i < parts.length; i++) {
          if (!tmpArr) {
            continue;
          }
          for (let j = 0; j < tmpArr.length; j++) {
            let path = tmpArr[j]['path'];
            path = path.replace(/^:.*\//g, '');
            path = path.replace(/\/:.*$/g, '');

            // console.log('path:', path);

            if (parts && path === parts[i] && tmpArr[j].name) {
              nameArr.push(tmpArr[j].name);
              tmpArr = tmpArr[j].children;
              break;
            }

          }
        }
        // console.log(nameArr);
        this.breadcrumb = nameArr;




      }
    });

  }



  ngOnInit() {

  }
  sideEvent(isCol: boolean) {
    this.isCollapsed = isCol;
  }
  shouldHighlight(item: any, parent: string): boolean {
    if (item.redirectTo) {
      return false;
    }
    // return  this.router.isActive(item.url, true);
    const cs = this.router.url.split('/');
    cs.pop();
    const c = cs.join('/');

    const us = item.url.split('/');
    us.pop();
    const u = us.join('/');

    // const c = this.router.url.replace('/index', '')
    // const u = item.url.replace('/index', '')
    const highlight =  us.length > 2 && c.startsWith(u);
    if (item.tree && item.tree.length > 0) {
      // folder
      return highlight;
    }

    let currentUrl = this.router.url.split('?')[0];

    if (currentUrl.endsWith('/index')) {
      currentUrl = currentUrl.substring( 0, currentUrl.length - 6);
    }
    let itemUrl = item.url;
    if (itemUrl.endsWith('/index')) {
      itemUrl = itemUrl.substring( 0, itemUrl.length - 6);
    }

    // return currentUrl === item.url;
    return currentUrl.startsWith(itemUrl);

    // return this.router.isActive(this.router.url, false);
  }
  isActive(item: any): boolean {
    // return this.router.url.startsWith(item.url);
    // const paths = item.url;
    // console.log(item.name,item.url,this.router.isActive('/admin/' + item.url, false))
    // const pu = [];
    /*for (const path of paths) {
      pu.push(path);
      const p = this.tabs.find(tab => {
        return tab.url.join('/') === pu.join('/');
      });
      // if ( p.children.length > 0) {
      //   pu.push('classes');
      // }
    }*/
    // const parts = this.router.url.split('/');
    // const _tmp = [];
    // for (const part of paths) {
    //
    // }
    return this.router.isActive(this.router.url, false);
    // return this.router.IsActiveMatchOptions(this.router.url, false);
    // return this.router.isActive('/admin/' + item.url, false);
  }
  isActive2(item: any, parent?: string): boolean {
    if (!item.name) {
      // may be default index
    }
    if (!item.url) {
      // return false;
    }
    const match =  this.router.isActive(item.url,false);

    // console.log(this.router.url,  item.url, parent, match);

    // return matchCount >= 2;
    // console.log(this.router.url,  item,  match);
    return match;
    // return this.router.IsActiveMatchOptions(this.router.url, false);
    // return this.router.isActive('/admin/' + item.url, false);
  }
  getMaxMatchingCount(arr1: any[], arr2: any[]): number {
    let count = 0;
    const minLength = Math.min(arr1.length, arr2.length);

    for (let i = 0; i < minLength; i++) {
      if (arr1[i] === arr2[i]) {
        count++;
      } else {
        break; // 一旦遇到不匹配就停止
      }
    }

    return count;
  }
  filterMenu(node: any) {
    // console.log('filterMenu', node);
    return true;
  }
  checkMenuVisiblePermission(node: any) {
    if (!node.name && !node.title) {
      return false;
    }
    if (node.hidden) {
      return false;
    }
    if (!(node?.data?.role)) {
      return true;
    }
    if ( Array.isArray(node.data.role) && node.data.role.length > 0 ) {
      // @ts-ignore
      const roleResult = node.data.role.includes(this.user?.type);
      return roleResult;
    }
    console.error('debug this', node);
      // @ts-ignore
    // return !node.hidden &&  node.data && node.data.role && Array.isArray(node.data.role) && node.data.role.includes(this.user?.type) ;
  }
  getItemPaths(item: { [x: string]: any; path: any; children: any; }, parent_name: any): any {
    const pn = item.path;
    const _t = {name: item['name'] ? item['name'] : parent_name, path: pn};
    const p = [_t];
    if (item.children) {
      for (const c of item.children) {
        const _p = this.getItemPaths(c, item['name']);
        for (const r of _p) {
          p.push(r);
        }
      }
    }
    return p;

  }
  getMenu(cfg: any) {
    const rs = [];
    for (const child of cfg.children) {
      if (this.checkMenuVisiblePermission(child)) {
        // console.log(1, child)
        if (child.data && child.data.role) {
          // @ts-ignore
          if (!child.data.role.includes(this.user.type)) {
            continue;
          }
        }
        if (child.data && child.data.type) {
          // @ts-ignore
          if (!child.data.type.includes(this.user.school_type)) {
            continue;
          }
        }
        if (child.children && child.children.length) {
          rs.push(this.getMenuItem(child, [], []));
        } else {
          if (!child.tree) {
            child.tree = [];
          }
          child.url = '/' + [cfg.path, child.path].join('/');
          rs.push(child);
        }

      }
    }
    return rs;
  }
  getMenuItem(item: any, path: any[], breadcrumb: any[]) {
    const _breadcrumb = breadcrumb.concat([]);
    const _path = path.concat([]);
    const displayName = item.name || item.data?.name;
    if(! displayName) {
      console.warn('cannot found display name', item);
      return item;
    }
    _breadcrumb.push(item.name);
    _path.push(item.path);
    const children = [];
    if (item.children && item.children.length) {
      let _tmp_children_count = 0;
      for (const c of item.children) {
        // @ts-ignore
        if (this.checkMenuVisiblePermission(c)) {
          // @ts-ignore
          // if (!c?.data?.role.includes(this.user.type)) {
          //   continue;
          // }
          _tmp_children_count += 1;
          children.push(this.getMenuItem(c, _path, _breadcrumb));
        }
      }
      if (_tmp_children_count === 0) {
        _path.push('index');
      }
    }
    item.breadcrumb = _breadcrumb;
    item.url = `/${this.menuPath}/${_path.join('/')}`;
    item.tree = children;
    return item;
    // return {
    //   name: item.name,
    //   url: _path,
    //   breadcrumb: _breadcrumb,
    //   icon: item.icon || '',
    //   children: children
    // };
  }

}
