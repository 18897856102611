<common-top-tool-bar [title]="this.book_id ? '编辑绘本' : '新增绘本'"
                     [back]="'/admin/v3/resource/'+bisType+'/courseware'"
                     [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'" class="mr-4"
            [nzSize]="'large'" nzGhost
            [routerLink]="'/admin/v3/resource/'+bisType+'/courseware'">
      取消
    </button>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            [disabled]="form.invalid || tabIndex==1"
            (click)="formElement.ngSubmit.emit()">
      {{this.book_id ? '保存' : '确定新增' }}
    </button>
  </ng-template>
</common-top-tool-bar>



<!--
 <div
            id="boundingContainerDiv"
            style="margin: 20px;width: 500px; height: 500px;background: moccasin"
          >
            <div
              ngxBoundsContainerSelector="#boundingContainerDiv"
              draggableElement
              resizableElement
              (ngxDragStart)="dragStart($event)"
              (ngxDragging)="dragging($event)"
              style="position: absolute; width: 100px; height: 100px; box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 5px 1px;
    border-radius: 8px; background-color: rebeccapurple; opacity: .45"
            ></div>
          </div>
-->


<div class="page-tab-main v3">
  <nz-spin [nzSpinning]="isLoading">
  <nz-tabset nzLinkRouter nzType="card" (nzSelectedIndexChange)="tabIndexChange($event)" [nzSelectedIndex]="tabIndex">
    <nz-tab>
      <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tabIndex: '0' }" queryParamsHandling="merge">
        基本信息
      </a>
      <div class="page-main">
          <div class="page-main-wrapper" style="padding: 0 2rem;">
            <form nz-form #formElement="ngForm" [formGroup]="form" (ngSubmit)="submitForm($event, form.value )">
              <div nz-row nzGutter="36">
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="name" nzRequired>输入绘本名称</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="请输入绘本名称" >
                      <input nzSize="large" nz-input formControlName="name" id="name" placeholder="请输入绘本名称" />
                    </nz-form-control>
                  </nz-form-item >
                </div>
                <div nz-col nzSpan="12" class="text-right mt-4">
                </div>
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="cover" nzRequired>上传封面图片</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="'请上传封面图片'">
                      <div class="flex items-end">
                        <app-upload-image-with-preview-v2
                          [picPreview]="true"
                          [innerClass]=""
                          style="width: 180px;display: inline-block;"
                          [displayTextTemplate]="displayTextTemplate"
                          [displayIcon]="null"
                          [picUrl]="form.controls['cover'].value"
                          (imageUploaded)="setCover($event)"
                          previewClass="bg-cover bg-center"
                          [displayRatioWidth]="0"
                          [displayRatioHeight]="0">
                          <ng-template #displayTextTemplate>
                            <div class="p-6">请将文件拖曳至此或点击此处上传图片。</div>
                          </ng-template>
                        </app-upload-image-with-preview-v2>
                        <div class="font-light text-xs px-4">注：图片格式为 Jpg 或 Png 文件，图片文件大小限制为 2MB</div>
                        <input hidden nz-input formControlName="url">
                      </div>
                    </nz-form-control>
                  </nz-form-item >
                </div>

                <div nz-col nzSpan="24" *ngIf="bisType == 'audio'">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="cover" nzRequired>上传音频</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="'请上传音频'">
                      <div class="flex items-end">
                        <tw-audio-uploader
                          [audioUrl]="(form.controls['url'].value | mediaUrlPipe )"
                          comment="注：音频格式为 Mp3 文件，音频文件大小限制为 10MB。"
                          (clear)="clearAudio($event)"
                          (audioUploadFailure)="onAudioUploadFailure($event)"
                          (audioUploaded)="onAudioUploadSuccess($event)">
                        </tw-audio-uploader>
                        <input hidden nz-input formControlName="url">
                      </div>
                    </nz-form-control>
                  </nz-form-item >
                </div>
                <div nz-col nzSpan="24" *ngIf="bisType == 'video'">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="cover" nzRequired>上传视频</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="'请上传视频'">
                      <div class="flex items-end">
                        <tw-video-uploader
                          [videoUrl]="(form.controls['url'].value | mediaUrlPipe )"
                          [limit]="100"
                          comment="注：视频格式为 Mp4 文件，音频文件大小限制为 100MB。"
                          (clear)="clearVideo($event)"
                          (videoUploadFailure)="onVideoUploadFailure($event)"
                          (videoCoverUploaded)="onVideoCoverUploadSuccess($event)"
                          (videoOverSize)="onVideoOverSize($event)"
                          (videoUploaded)="onVideoUploadSuccess($event)">
                        </tw-video-uploader>

                        <input hidden nz-input formControlName="url">
                      </div>
                    </nz-form-control>
                  </nz-form-item >
                </div>
              </div>
              <div nz-row nzGutter="36">

              </div>
              <div>
                <ng-container formArrayName="tagsGroupArray"
                              *ngFor="let tagGroupForm of tagsGroupArray.controls; let i = index;">
              <div [formGroupName]="i" style="background: #EEF5FF;" class="p-4 rounded-xl my-4 relative">
                <span nz-icon nzType="close-circle" nzTheme="fill"
                      style="z-index: 9999"
                      *ngIf="tagsGroupArray.controls.length > 1"
                      (click)="removeTagsGroup(i)"
                      class="absolute text-lg right-2 top-0 cursor-pointer"></span>
                <div nz-row nzGutter="36"  >
                  <div nz-col nzSpan="12"  [hidden]="+tagGroupForm.controls.syllabus_id.value == 0">
                    <nz-form-item>
                      <nz-form-label [nzSpan]="24" nzFor="syllabus_id" nzRequired>选择分类</nz-form-label>
                      <nz-form-control [nzSpan]="24" nzErrorTip="请选择分类">
                        <nz-select nzSize="large" nzAllowClear
                                   (ngModelChange)="changeSyllabus(i, $event)"
                                   [formControlName]="'syllabus_id'" nzPlaceHolder="选择分类">
                          <nz-option [nzValue]="+item['id']" [nzLabel]="item['name']" *ngFor="let item of bookAttrs.syllabus"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item >
                  </div>
<!--                  <input *ngIf="!tagGroupForm.controls.syllabus_id.value" hidden nz-input formControlName="syllabus_id" value="0">-->
                  <div nz-col nzSpan="12" [hidden]="+tagGroupForm.controls.course_id.value == 0">
                    <nz-form-item>
                      <nz-form-label [nzSpan]="24" nzFor="course_id" nzRequired>选择大纲</nz-form-label>
                      <nz-form-control [nzSpan]="24" nzErrorTip="请选择大纲">
                        <nz-select nzSize="large" nzAllowClear
                                   (ngModelChange)="changeCourse(i, $event)"
                                   [formControlName]="'course_id'"
                                   nzPlaceHolder="选择大纲">
                          <nz-option [nzValue]="+item['id']" [nzLabel]="item['name']"
                                     *ngFor="let item of currentSubOptions(i)"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item >
                  </div>
<!--                  <input *ngIf="!tagGroupForm.controls.course_id.value" hidden nz-input formControlName="course_id" value="0">-->
                </div>
                <div nz-row nzGutter="36" >
                  <div  nz-col nzSpan="12"
                        *ngFor="let syllabusTag of (rootThemesKeyValueItems[tagGroupForm.controls['syllabus_id'].value] || []); let i = index">
                    <nz-form-item >
                      <nz-form-label [nzSpan]="24" [nzFor]="'tag_group_id.'+syllabusTag.id">选择{{syllabusTag.name}}</nz-form-label>
                      <nz-form-control [nzSpan]="24"  [nzErrorTip]="'请选择'+syllabusTag.name">
                        <nz-select nzSize="large" nzAllowClear
                                   [formControlName]="'tag_group_id.' + syllabusTag.id"
                                   [nzPlaceHolder]="syllabusTag.name" nzMode="multiple">
                          <nz-option [nzValue]="item['id']"
                                     [nzLabel]="item['name']"
                                     *ngFor="let item of syllabusTag.children"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item >
                  </div>
                </div>
                <div nz-row nzGutter="36">

                  <div  nz-col nzSpan="12"
                        *ngFor="let themeControl of (subThemesKeyValueItems[tagGroupForm.controls['course_id'].value]); let i = index">
                    <nz-form-item >
                      <nz-form-label [nzSpan]="24" [nzFor]="'tag_id.'+themeControl.id">选择{{themeControl.name}}</nz-form-label>
                      <nz-form-control [nzSpan]="24"  [nzErrorTip]="'请选择'+themeControl.name">
                        <nz-select nzSize="large" nzAllowClear
                                   [formControlName]="'tag_id.' + themeControl.id"
                                   [nzPlaceHolder]="themeControl.name" nzMode="multiple">
                          <nz-option [nzValue]="item['id']" [nzLabel]="item['name']" *ngFor="let item of themeControl.children"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item >
                  </div>

                </div>

              </div>
                </ng-container>
              </div>
              <div class="text-right my-4">
                <a nz-button nzBlock [nzType]="'text'" class="mr-4"
                   (click)="addTagsGroup({syllabus_id: 0, course_id: 0})"
                   style="color: #40a9ff;border-color: #40a9ff;"
                        [nzSize]="'default'"  nzGhost>
                  添加归属分类
                </a>
              </div>

            </form>
          </div>


      </div>
    </nz-tab>
        <nz-tab *ngIf="bisType == 'cartoon' || bisType == 'book'" [nzDisabled]="!Boolean(book_id)">
          <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tabIndex: '1' }" queryParamsHandling="merge">
            上传素材
          </a>
          <div class="page-main">
            <div class="page-main-table p-8">
              <div class="table-header"></div>
              <div nz-row nzGutter="32" *ngIf="items_status">
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="reading" title="Reading"
                                 [status]="items_status.reading"
                                 [link]="'/admin/v3/resource/cartoon/courseware/edit/'+this.book_id+'/reading'"></book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar  icon="reading2" title="Touch To Read - 点读"
                                 [status]="items_status.touchToRead"
                                 [link]="'/admin/v3/resource/cartoon/courseware/edit/'+this.book_id+'/touch-to-read'"
                                 *ngIf="items_status && items_status.reading"></book-rel-item-bar>
                  <book-rel-item-bar icon="reading2" title="Touch To Read - 点读"
                                 *ngIf="items_status && !items_status.reading"
                                 (click)="noticeReadingStatus()"></book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="listen" title="Listening"
                                     [link]="'/admin/v3/resource/cartoon/courseware/edit/'+this.book_id+'/audio'">

                  </book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="watching" title="Watching"
                                     [link]="'/admin/v3/resource/cartoon/courseware/edit/'+this.book_id+'/video'">

                  </book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="testing" title="Quiz"
                                     [link]="'/admin/v3/resource/cartoon/courseware/edit/'+this.book_id+'/quiz'"
                  ></book-rel-item-bar>
                </div>

                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="vocabulary" title="Vocabulary"></book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="sentence" title="Sentence"></book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="dubbing" title="Dubbing"></book-rel-item-bar>
                </div>


                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="game" title="Game"></book-rel-item-bar>
                </div>
                <div nz-col nzSpan="12">
                  <book-rel-item-bar icon="bandu" title="動畫伴讀"></book-rel-item-bar>
                </div>
              </div>



            </div>
          </div>
        </nz-tab>
  </nz-tabset>
  </nz-spin>
</div>
