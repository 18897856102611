import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TagsService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  checkNameExist(resourceType: string, query?: any) {
    const queryStr = this.objectToQueryString(query);
    const url = `/tags/${resourceType}/check/name?${queryStr}`;
    return super.get(url);
  }


  deleteItem(resourceType: string, data: any) {
    return this.post(`/tags/${resourceType}/item/delete`, data);
  }

  save(resourceType: string, data: any) {
    return this.post(`/tags/${resourceType}/item/save`, data);
  }



}
