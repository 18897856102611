<div *ngIf="loading" class="flex justify-center py-10">
  <nz-spin nzSimple nzSize="large"></nz-spin>
</div>
<div class="bg-white rounded-md overflow-clip pb-4" *ngIf="!loading">
  <div class="px-5 py-4 font-semibold">
    {{ ResourcePushTemplateStartingPoints[startingPoint].label }}
  </div>
  <nz-divider class="m-0"/>

  <form [formGroup]="form">
    <div formArrayName="templates" class="flex flex-col gap-4">
      <div *ngFor="let templatesForm of templatesForm.controls; let i = index" [formGroupName]="i" class="flex flex-col gap-4">

        <!-- title -->
        <div class="flex items-center px-4 py-1 bg-blue-50">
          <div class="flex-1">
            <span class="font-semibold">第{{ i + 1 }}周</span>
          </div>
          <div>
            <button nz-button nzType="link" nzShape="circle" (click)="handleDeleteTemplate(i)"
                    class="flex justify-center items-center">
              <i nz-icon nzType="delete" class="text-red-400"></i>
            </button>
          </div>
        </div>

        <!-- content -->
        <div class="flex flex-col px-4">

          <!-- daysOfTemplates -->
          <div class="h-14">
            <div class="flex gap-4">
              <button
                *ngFor="let day of ResourcePushTemplateDayOfWeek"
                nz-button
                class="px-6"
                [nzType]="isDaySelected(i, day.value) ? 'primary' : 'default'"
                (click)="handleSelectDayOfWeek(i, day.value)">
                {{ day.label }}
              </button>
            </div>
            <div *ngIf="templatesForm.get('daysOfWeek')?.touched && templatesForm.get('daysOfWeek')?.errors"
                 class="text-red-500 text-sm">
              请选择至少选择一天
            </div>
          </div>

          <!-- remark -->
          <nz-form-control>
            <textarea
              nz-input
              nzSize="large"
              class="text-xs"
              placeholder="备注"
              rows="3"
              formControlName="remark"></textarea>
          </nz-form-control>

          <div class="flex flex-col gap-4" formArrayName="homeworks">
            <div *ngFor="let homework of templates[i].homeworks; let j = index">
              <app-admin-resource-category-template-homework
                [index]="j"
                [homework]="homework"
                (homeworkChange)="handleHomeworkChange(i, j, $event)"
                (deleteHomework)="handleDeleteHomework(i, j)"
                (addHomework)="handleAddHomework(i)"
              ></app-admin-resource-category-template-homework>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>

  <div class="px-4 flex flex-col gap-4 mt-4">

    <div>
      <button nz-button nzType="dashed" (click)="handleAddTemplate()" nzSize="large" nzBlock="true">
        添加周
      </button>
    </div>

    <div>
      <button nz-button nzType="primary" (click)="handleSubmit()" nzSize="large" class="px-10" [nzLoading]="saving">
        保存
      </button>
    </div>
  </div>

</div>
