import { Component, ContentChildren, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChildren } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { NzTableQueryParams } from 'ng-zorro-antd/table/src/table.types';




@Component({
  selector: 'app-theme-tag',
  template: `
    <div class="inline-block rounded px-2 py-1 border border-gray-400 mr-2 relative cursor-pointer" (click)="doClick($event)">
      <div *ngIf="text">
        {{text}}
      </div>
      <div *ngIf="!text">
        <ng-container *ngTemplateOutlet="contentTemplate;"></ng-container>
      </div>
      <div *ngIf="removable" (click)="doRemove($event)" class="absolute -right-1.5 -top-1.5 cursor-pointer">
        <span nz-icon nzType="close-circle" nzTheme="twotone"></span>
      </div>
    </div>
  `,
  styles: [
    `
     
    `
  ],
  // standalone: true
})
export class CommonAppThemeTagComponent {

  @Output() click?: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Input() contentTemplate?: TemplateRef<any>;
  @Input() text?: string;
  @Input() color?: string;
  @Input() removable = false;

  doRemove($event: any) {
    this.delete.emit($event);
  }
  doClick($event: any) {
    this.click?.emit($event);
  }

}
