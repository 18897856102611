<common-top-tool-bar title="繪本分類管理" [actionsTemplate]="actionsTemplate">
  <ng-template #actionsTemplate>
    <ng-container *ngIf="tabIndex == 0">
      <button nz-button class="mr-4" nzType="primary" [nzSize]="'large'" (click)="openItemDialog(cateItem, '分類',['name', 'cover'])" nzGhost>
        編輯分類
      </button>
<!--      <button nz-button nzType="primary" [nzSize]="'large'" (click)="newRootCategory({id: 0})">-->
      <button nz-button nzType="primary" [nzSize]="'large'" (click)="openItemDialog({pid: 0, id: 0}, '分類',['name', 'cover'])">
        <i nz-icon nzType="plus" nzTheme="outline"></i>新增分類
      </button>
    </ng-container>
  </ng-template>
</common-top-tool-bar>



<div class="page-tab-main">
  <nz-spin [nzSpinning]="isLoading">
    <nz-tabset nzType="card"  [ngStyle]="{height: 'px'}"

               (nzSelectedIndexChange)="tabIndexChange($event)">
          <nz-tab [nzTitle]="'繪本分類'">

            <div class="page-main pt-0">
              <div class="page-main-table">
                <div class="table-header"></div>
                <br>
                <div class="w-full">
                    <nz-tabset nzType="line" [nzTabPosition]="'left'"
                               [nzSelectedIndex]="cateTabIndex"
                               cdkDropList cdkDropListOrientation="vertical"
                               (cdkDropListDropped)="sortCate($event)"
                               (nzSelectedIndexChange)="cateIndexChange($event)">
                    <nz-tab *ngFor="let tab of tabs"
                            [nzTitle]="titleTemplate">
                      <ng-template #titleTemplate>
                        <div cdkDrag style="font-size: 14px">
                          <div class="px-2" *cdkDragPlaceholder></div>
                          {{tab.name }}
                        </div>
                      </ng-template>
                      <div style="background: #EEF5FF" class="p-4 mr-4 rounded-md">
                        <div class="w-full flex items-center mb-4">
                          <div class="flex-1 text-lg">大纲[{{cateItem.name}}]</div>
                          <div class="flex-none">
<!--                            <button nz-button nzType="primary" class="mr-4" [nzSize]="'large'" (click)="newChildCategory(tab)">-->
                            <button nz-button nzType="primary" [nzSize]="'large'" (click)="openItemDialog({id:0, pid: tab.id}, '大綱', ['name', 'cover'])">
                              <i nz-icon nzType="plus" nzTheme="outline"></i>新增大纲
                            </button>
                          </div>
                        </div>
                        <div cdkDropList (cdkDropListDropped)="sortSubCate($event)" class="flex flex-col gap-2">
                          <div class="shadow-sm rounded-md cursor-move overflow-clip"
                             cdkDrag
                             *ngFor="let item of tab.children; let i = index">

                            <div class="flex items-center p-4 bg-white">
                              <div class="flex-1 flex items-center">
                                <div class="text-gray-400 flex justify-center items-center mr-4">
                                  <i nz-icon nzType="unordered-list" nzTheme="outline" style="font-size: 20px;"></i>
                                </div>
                                <div class="text-lg">{{item.name}}</div>
                              </div>
                              <div class="flex items-center">
                                <button nz-button class="mr-4" nzType="primary" [nzSize]="'default'"
                                        (click)="openItemDialog(item, '大纲', ['name', 'cover'])" nzGhost>
                                  编辑
                                </button>
                                <i nz-icon nzType="down" [@rotateIcon]="selectedCateIndex === i ? 'expanded' : 'collapsed'"
                                   (click)="toggleCate(i)"
                                   class="text-gray-400 cursor-pointer hover:text-blue-500 transition-colors px-1 py-0.5"></i>
                              </div>
                            </div>

                            <!-- 折叠内容 -->
                            <div [@expandCollapse]="selectedCateIndex === i ? 'expanded' : 'collapsed'"
                                 class="overflow-hidden">
                              <div *ngFor="let startingPoints of ResourcePushTemplateStartingPoints"
                                   class="flex px-6 py-4 border-t-gray-200 border-t-[1px] bg-blue-50 tracking-wider">
                                <div class="font-medium text-gray-500 flex-1">{{startingPoints.label}}</div>
                                <div>
                                  <button nz-button nzType="link" nzSize="small" (click)="handleEditTemplate(item.id, startingPoints.value, tab.id)">编辑</button>
                                </div>

                              </div>
                            </div>
                          </div>

                          </div>
                      </div>
                    </nz-tab>
                  </nz-tabset>
                </div>
              </div>
            </div>
          </nz-tab>
          <nz-tab [nzTitle]="'繪本主題'">

            <div class="page-main pt-0">
              <div class="page-main-table">
                <div class="table-header"></div>
                <br>
                <div class="w-full">
                  <nz-tabset nzType="line" [nzTabPosition]="'left'"
                             [nzSelectedIndex]="cateTabIndex"
                             (nzSelectedIndexChange)="cateIndexChange($event)">
                    <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name">
<!--                      <ng-template nz-tab>-->
<!--                      <resource-category-tags [cateItem]="cateItem"-->
<!--                                              [editItem]="editItem"-->
<!--                                              [tags]="tags"-->
<!--                                              [tab]="tab"></resource-category-tags>-->
<!--                      </ng-template>-->
                      <ng-template nz-tab>
                      <div style="background: #EEF5FF" class="p-4 mr-4 rounded-md">
                        <div class="w-full flex">
                          <div class="flex-1 text-lg">主题分类 [{{cateItem.name}}]</div>
                          <div class="flex-none">
                            <button nz-button nzType="primary" class="mr-4" [nzSize]="'large'" (click)="openItemDialog({id: 0, pid:0, syllabus_id: cateItem.id}, '主题分类', ['name'])">
                              <i nz-icon nzType="plus" nzTheme="outline"></i>新增主题标签组
                            </button>
                          </div>
                        </div>



                        <nz-collapse nzAccordion class="flex shadow-sm bg-white rounded-md p-2 mt-2"
                                     style="border: 0"
                                     *ngFor="let item of tags[cateItem.id]; let i = index">
                          <nz-collapse-panel [nzHeader]="item.name"
                                             [nzExtra]="extraTpl"
                                             [nzActive]="i == 0"
                                             class="w-full border-0" style="border: 0">
                            <div>
                              <app-theme-tag
                                *ngFor="let theme of item.children; let j = index"
                                [text]="theme.name" (click)="openItemDialog(theme, '主题', ['name'], $event)" />


                            </div>
                          </nz-collapse-panel>
                          <ng-template #extraTpl>
                            <button nz-button nzType="primary" [nzSize]="'default'" (click)="openItemDialog(item, '主题分类', ['name'], $event)" class="mr-4">
                              編輯0
                            </button>
                            <button nz-button nzType="primary" [nzSize]="'default'" (click)="openItemDialog({id: 0, pid: item.id, syllabus_id: cateItem.id}, '主题', ['name'], $event )" class="mr-4" nzGhost>
                              添加
                            </button>
                          </ng-template>
                        </nz-collapse>



                        <div class="mt-4">大纲标签</div>
                        <nz-collapse class="mt-4" [nzAccordion]="true">
                          <nz-collapse-panel
                            *ngFor="let panel of cateItem.children" [nzHeader]="tagGroupName"
                            [nzExtra]="addTagsGroup"
                            [nzActive]="panel.id == editItem.id">
                            <ng-template #tagGroupName>
                              <div class="font-bold">{{panel.name}}</div>
                            </ng-template>
                            <ng-template #addTagsGroup>
                              <button nz-button nzType="primary" class="mr-4" [nzSize]="'default'" (click)="openItemDialog({id: 0, pid:0, syllabus_id: panel.id}, '分类主题', ['name'])">
                                <i nz-icon nzType="plus" nzTheme="outline"></i>新增标签组
                              </button>
                            </ng-template>

                            <nz-collapse nzAccordion class="flex shadow-sm bg-white rounded-md p-2 mt-2"
                                         style="border: 0"
                                         *ngFor="let item of tags[panel.id]; let i = index">
                              <nz-collapse-panel [nzHeader]="item.name"
                                                 [nzExtra]="extraTpl"
                                                 [nzActive]="i == 0"
                                                 class="w-full border-0 " style="border: 0">
                                <div>
                                  <app-theme-tag
                                    class="font-light"
                                    *ngFor="let theme of item.children; let j = index"
                                    [text]="theme.name" (click)="openItemDialog(theme, '主题', ['name'], $event)" />


                                </div>
                              </nz-collapse-panel>
                              <ng-template #extraTpl>
                                <button nz-button nzType="primary" [nzSize]="'default'" (click)="openItemDialog(item, '主题分类', ['name'], $event)" class="mr-4">
                                  編輯1
                                </button>
                                <button nz-button nzType="primary" [nzSize]="'default'" (click)="openItemDialog({id: 0, pid: item.id, syllabus_id: item.syllabus_id}, '主题', ['name'], $event )" class="mr-4" nzGhost>
                                  添加
                                </button>
                              </ng-template>
                            </nz-collapse>


                          </nz-collapse-panel>
                        </nz-collapse>



                      </div>
                      </ng-template>
                    </nz-tab>
                  </nz-tabset>
                </div>

                <!--
                <div style="background: #EEF5FF;" class="p-4 m-4 rounded-md">
                  <div class="w-full flex">
                    <div class="flex-1 text-lg">主題</div>
                    <div class="flex-none">
                      <button nz-button nzType="primary" [nzSize]="'large'" (click)="addThemeCate()">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>新增主題
                      </button>
                    </div>
                  </div>
                  <div class="flex shadow-sm bg-white rounded-md p-4 mt-4" *ngFor="let item of themes">
                    <div class="flex-1">{{item.name}}</div>
                    <div class="flex-none">
                      <button nz-button class="mr-4" nzType="primary" [nzSize]="'default'" (click)="editChildCategory(item, '主題')"  nzGhost>
                        編輯
                      </button>
                    </div>
                  </div>
                </div>
                -->
              </div>
            </div></nz-tab>

      <!-- 考察点 -->
      <nz-tab *ngIf="resourceType === 'book' || resourceType === 'cartoon'" [nzTitle]="'考察点'">
        <div class="page-main pt-0">
          <div class="page-main-table">
            <div class="table-header"></div>
            <br>
            <div class="w-full">
              <nz-tabset nzType="line" [nzTabPosition]="'left'"
                          [nzSelectedIndex]="cateTabIndex"
                          (nzSelectedIndexChange)="cateIndexChange($event)">
                <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name">
                  <div style="background: #EEF5FF" class="p-4 mr-4 rounded-md">
                    <div class="mt-4">考察点 [{{cateItem.name}}]</div>
                    <nz-collapse class="mt-4" [nzAccordion]="true">
                      <nz-collapse-panel
                        *ngFor="let panel of cateItem.children" [nzHeader]="tagGroupName"
                        [nzActive]="panel.id == editItem.id">
                        <ng-template #tagGroupName>
                          <div class="font-bold">{{panel.name}}</div>
                        </ng-template>
                        <nz-collapse nzAccordion class="flex shadow-sm bg-white rounded-md p-2 mt-2"
                                      style="border: 0"
                                      *ngFor="let item of [{name:'全部考察点'}]; let i = index">
                          <nz-collapse-panel [nzHeader]="item.name"
                                              [nzExtra]="extraTpl"
                                              [nzActive]="i == 0"
                                              class="w-full border-0 " style="border: 0">
                            <div>
                              <app-theme-tag
                                class="font-light"
                                *ngFor="let checkPoint of checkPoints[panel.id]; let j = index"
                                [text]="checkPoint.name" (click)="openItemDialog(checkPoint, '考察点', ['name'], $event)" />
                            </div>
                          </nz-collapse-panel>
                          <ng-template #extraTpl>
                            <button nz-button nzType="primary" [nzSize]="'default'" (click)="openItemDialog({id: 0, pid: 0, syllabus_id: panel.id}, '考察点', ['name'], $event )" class="mr-4" nzGhost>
                              添加
                            </button>
                          </ng-template>
                        </nz-collapse>
                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                </nz-tab>
              </nz-tabset>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </nz-spin>
</div>


<ng-template #modalContent>
  <div class="modal-dialog-title flex">
    <div class="flex-1">{{isEdit ?  '編輯' : '新增'}}{{title}}</div>
    <div class="flex-none" *ngIf="isEdit">
      <span nz-icon nzType="delete" nzTheme="fill" style="color: #EA8F8F; cursor: pointer" (click)="removeItem(editItem)"></span>
    </div>
  </div>
  <nz-spin [nzSpinning]="isLoading">
<!--  <form nz-form #addVideoFormElement="ngForm" [formGroup]="tabIndex == 0 ? cateForm : themeForm" (ngSubmit)="submitItemForm($event, (tabIndex == 0 ? cateForm : themeForm).value )">-->
  <form nz-form #itemFormElement="ngForm" [formGroup]="itemForm" (ngSubmit)="submitItemForm($event, itemForm.value )">
    <div nz-row nzGutter="72">
      <div nz-col nzSpan="24" *ngIf="!formFields || (formFields && formFields.length> 0 && formFields.includes('name'))">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="name" nzRequired>{{title}}名稱</nz-form-label>
          <nz-form-control [nzSpan]="24"  nzHasFeedback [nzErrorTip]="nameErrorTpl">
            <input nzSize="large" nz-input formControlName="name" id="name" [placeholder]="title + '名稱'"  />
          </nz-form-control>
          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="itemForm.get('name')?.hasError('duplicated')">
              {{title}}名稱不可重複！
            </ng-container>
          </ng-template>
        </nz-form-item >
      </div>
      <div nz-col nzSpan="24" *ngIf="!formFields || (formFields && formFields.length> 0 && formFields.includes('cover'))">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="cover" nzRequired>{{title}}分类图片</nz-form-label>
          <nz-form-control [nzSpan]="24"  nzHasFeedback>

            <input nzSize="large" hidden nz-input formControlName="cover" id="cover"   />
            <div class="text-center">
              <app-upload-image-with-preview-v2
                [picPreview]="true"
                [innerClass]=""
                style="width: 200px;display: inline-block;"
                [displayTextTemplate]="displayTextTemplate"
                [displayIcon]="null"
                [picUrl]="itemForm.get('cover').value"
                (imageUploaded)="coverUploaded($event)"
                previewClass="bg-cover"
                displayRatioWidth="1"
                displayRatioHeight="1">
                <ng-template #displayTextTemplate>
                  <div class="p-6">請將logo拖曳至此或點擊此處上傳圖片</div>
                </ng-template>
              </app-upload-image-with-preview-v2>
            </div>

          </nz-form-control>
        </nz-form-item >
      </div>
    </div>
    <div nz-row nzGutter="36">
      <div nz-col nzSpan="12">
        <a nz-button class="w-full" [nzSize]="'large'" nzType="primary" nzGhost (click)="closeItemDialog()">
          取消
        </a>
      </div>
      <div nz-col nzSpan="12">
        <button nz-button class="w-full" [disabled]="itemForm.invalid" [nzSize]="'large'" nzType="primary" >
          {{isEdit ? '儲存' : '新增'}}
        </button>
      </div>
    </div>
  </form>
  </nz-spin>
</ng-template>
<nz-modal
  [nzWidth]="360"
  nzWrapClassName="v3"
  [nzKeyboard]="false"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  [(nzVisible)]="formDialogVisible"
  [nzContent]="modalContent"
  [nzTitle]="null"
  [nzFooter]="null"
  (nzOnCancel)="closeItemDialog()"
>
<!--  [nzTitle]="isEdit ?  '編輯'+title : '新增'+title"-->


</nz-modal>


<nz-modal
  [nzWidth]="360"
  nzWrapClassName="v3"
  [nzKeyboard]="false"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  [(nzVisible)]="newThemeCategoryVisible"
  [nzContent]="newThemeCategoryContent"
  [nzTitle]="null"
  [nzFooter]="null"
  (nzOnCancel)="closeThemeCategoryDialog()"
>
  <ng-template #newThemeCategoryContent>
    <!--  [nzTitle]="isEdit ?  '編輯'+title : '新增'+title"-->
    <div class="modal-dialog-title flex">
      <div class="flex-1">{{isEdit ?  '編輯' : '新增'}}{{title}}</div>
      <div class="flex-none" *ngIf="isEdit">
        <span nz-icon nzType="delete" nzTheme="fill" style="color: #EA8F8F; cursor: pointer" (click)="removeItem(tabIndex)"></span>
      </div>
    </div>
    <nz-spin [nzSpinning]="isLoading">
      <form nz-form  #themeCategoryFormElement="ngForm" [formGroup]="itemForm" (ngSubmit)="submitItemForm($event, itemForm.value )">
        <div nz-row nzGutter="72">
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name" nzRequired>{{title}}名稱</nz-form-label>
              <nz-form-control [nzSpan]="24"  nzHasFeedback [nzErrorTip]="nameErrorTpl">
                <input nzSize="large" nz-input formControlName="name" [placeholder]="title + '名稱'"  />
              </nz-form-control>
              <ng-template #nameErrorTpl let-control>
                <ng-container *ngIf="itemForm.get('name')?.hasError('duplicated')">
                  主题分类名稱不可重複！
                </ng-container>
              </ng-template>
            </nz-form-item >
          </div>

        </div>
        <div nz-row nzGutter="36">
          <div nz-col nzSpan="12">
            <a nz-button class="w-full" [nzSize]="'large'" nzType="primary" nzGhost (click)="closeThemeCategoryDialog()">
              取消
            </a>
          </div>
          <div nz-col nzSpan="12">
            <button nz-button class="w-full" [disabled]="themeForm.invalid" [nzSize]="'large'" nzType="primary" >
              {{isEdit ? '儲存' : '新增'}}
            </button>
          </div>
        </div>
      </form>
    </nz-spin>
  </ng-template>

</nz-modal>
