<common-top-tool-bar [title]="pageTitle" [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            [queryParams]="{tabIndex: 0}"
            [routerLink]="'/admin/v3/resource/'+bisType+'/courseware/add'">
      <i nz-icon [nzType]="'plus'" nzTheme="outline"></i>新增{{bisType=='cartoon' ? '繪本' : '教材' }}
    </button>
  </ng-template>
</common-top-tool-bar>

<div class="page-main">
  <nz-spin [nzSpinning]="isLoadingAttr">
  <tw-table-filter
    actionStyle="align-items: center;display: flex;"
    wrapperStyle="height: auto; padding-bottom: 1em;"
    (doFilter)="doFilter($event)">
    <div class="ml-2 inline">
      <common-search-input
        [(value)]="filter.name"
        [placeholder]="'搜尋教材名稱'"
        (clear)="clearSearch()"></common-search-input>
    </div>

    <nz-select nzAllowClear class="m-2"
               [nzDropdownMatchSelectWidth]="false"
               [ngModel]="filter.category_id"
               (ngModelChange)="changeCategory($event)"
               nzPlaceHolder="分類">
      <nz-option [nzValue]="item['id']" [nzLabel]="item['name']" *ngFor="let item of bookAttrs.syllabus"></nz-option>
    </nz-select>
    <nz-select  nzAllowClear class="m-2"
                [(ngModel)]="filter.course_id"
                [nzDropdownMatchSelectWidth]="false"
                (ngModelChange)="changeCourse($event)"
                nzPlaceHolder="大纲">
      <nz-option [nzValue]="item['id']" [nzLabel]="item['name']" *ngFor="let item of syllabusKeyValueItems[filter.syllabus_id]?.children"></nz-option>
    </nz-select>

    <nz-select  nzAllowClear class="m-2"
                *ngFor="let theme of rootSyllabusThemes;"
                [(ngModel)]="filter.themes[theme.id]"
                [nzDropdownMatchSelectWidth]="false"
                [nzPlaceHolder]="themeKeyValueItems[theme.id].name">
      <nz-option [nzValue]="item['id']" [nzLabel]="item['name']"
                 *ngFor="let item of theme.children"></nz-option>
    </nz-select>

    <nz-select  nzAllowClear class="m-2"
                *ngFor="let theme of subSyllabusThemes;"
                [(ngModel)]="filter.themes[theme.id]"
                [nzDropdownMatchSelectWidth]="false"
                [nzPlaceHolder]="themeKeyValueItems[theme.id].name">
      <nz-option [nzValue]="item['id']" [nzLabel]="item['name']"
                 *ngFor="let item of theme.children"></nz-option>
    </nz-select>




<!--    <nz-select  nzAllowClear class="mr-4"-->
<!--                [(ngModel)]="filter.theme_id"-->
<!--                [nzDropdownMatchSelectWidth]="false"-->
<!--                nzPlaceHolder="主題">-->
<!--      <nz-option [nzValue]="item['id']" [nzLabel]="item['name']" *ngFor="let item of bookAttrs.theme"></nz-option>-->
<!--    </nz-select>-->
<!--    <nz-select  nzAllowClear class="mr-4"-->
<!--                [(ngModel)]="filter.level"-->
<!--                [nzDropdownMatchSelectWidth]="false"-->
<!--                nzPlaceHolder="分級">-->
<!--      <nz-option [nzValue]="item['id']" [nzLabel]="item['name']" *ngFor="let item of bookAttrs.level"></nz-option>-->
<!--    </nz-select>-->
    <nz-select nzAllowClear
               [(ngModel)]="filter.reserve3"
               [nzDropdownMatchSelectWidth]="false"
               nzPlaceHolder="教材狀態">
      <nz-option [nzValue]="1" [nzLabel]="'启用'"></nz-option>
      <nz-option [nzValue]="0" [nzLabel]="'禁用'"></nz-option>
    </nz-select>
<!--    <nz-range-picker [nzPlaceHolder]="['新增日期區間', '新增日期區間']" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-range-picker>-->
  </tw-table-filter>
  </nz-spin>
  <tw-table class="data-table" #dataTable
            [nzTotal]='totalNum'
            [nzPageIndex]="filter.pageIndex"
            [nzPageSize]="10"
            (nzQueryParams)="onQueryParamsChange($event)"
            [nzData]="dataSet"
            [nzLoading]="isLoading"
  >
    <thead>
    <tr>
      <th [nzWidth]="'150'">序號</th>
      <th>封面</th>
      <th>名稱</th>
      <th style="width: 420px">項目</th>
      <th>新增日期</th>
      <th>繪本狀態</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of dataSet">
      <td style="width: 150px;">{{row.id}}</td>
      <td>
        <div class="h-16 w-24 m-1 rounded cover" [ngStyle]="{
        'background-image': 'url(' +  (row.cover && (row.cover.startsWith('http') ? row.cover : protocol+'//'+host+'/' + (bucket ? bucket + '/' : '') +row.cover))  + ')'
        }">
        </div>

      </td>

      <td>{{row.name}}</td>
      <td>
        <ng-container *ngIf="row.items_status">
          <nz-tag [nzColor]="'#EBFAEB'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.reading">Reading</nz-tag>
          <nz-tag [nzColor]="'#FEF7EA'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.classroomReading">Reading課堂</nz-tag>
          <nz-tag [nzColor]="'#DAE36F'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.listening">Listening</nz-tag>
          <nz-tag [nzColor]="'#FFE9E9'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.watching">Watching</nz-tag>
          <nz-tag [nzColor]="'#FEFEEA'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.vocabulary">Vocabulary</nz-tag>
          <nz-tag [nzColor]="'#EAFCFE'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.sentence">Sentence</nz-tag>
          <nz-tag [nzColor]="'#DCD1FF'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.dubbing">Dubbing</nz-tag>
          <nz-tag [nzColor]="'#D8EAFF'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.testing">Testing</nz-tag>
          <nz-tag [nzColor]="'#FCD9FF'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.game">Game</nz-tag>
          <nz-tag [nzColor]="'#D9F8FF'" class="mr-1 mb-1 text-slate-700 rounded w-24" *ngIf="row.items_status.co_reading">動畫伴讀</nz-tag>
        </ng-container>
      </td>

      <td>{{row.created_date}}</td>
      <td>
        <nz-switch
          (ngModelChange)="changeStatus($event, row)"
          [ngModel]="row.enabled"></nz-switch>
      </td>
      <td style="width: 18rem;">
        <div class="operations">
<!--          <button nz-button nzType="primary" class="mr-4" [routerLink]="'/school/class/'+ row.class_id+'/student'" [queryParams]="row">查看詳細</button>-->
          <button nz-button nzType="primary" class="mr-4">查看詳細</button>
          <button nz-button nzType="primary" class="mr-4" [routerLink]="'/admin/v3/resource/'+bisType+'/courseware/edit/'+ row.id" [queryParams]="{tabIndex: '0'}">編輯</button>
        </div>

      </td>
    </tr>
    </tbody>
  </tw-table>
</div>
