import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmType, ModalOptions } from 'ng-zorro-antd/modal/modal-types';
import { NzModalRef } from 'ng-zorro-antd/modal/modal-ref';

@Injectable({
  providedIn: 'root'
})

export class TwNoticeService extends NzModalService {

  override confirm(options?: ModalOptions, confirmType?: ConfirmType) {
    // return super.confirm(options, confirmType);
    const ref = super.confirm({
      nzTitle: '', // 'This is a notification message',
      nzIconType: undefined,
      // nzOkText: null,
      nzCancelText: '取消',
      nzClosable: false,
      nzMaskClosable: true,
      nzOkDanger: true,
      nzWidth: 438,
      // nzCentered: true,
      nzWrapClassName: 'tw-modal-confirm-wrapper',
      nzClassName: 'tw-modal-confirm confirm',
      nzBodyStyle: {
        padding: '2rem;'
      },
      ...options
    }, confirmType);
    return ref;
  }

  override info(options?: ModalOptions) {
    return super.info(options);
  }
  override success(options?: ModalOptions) {
    let nzWidth = 180;
    if (typeof options?.nzContent == 'string') {
      nzWidth = (options?.nzContent.length + 2) * 16 + 40;
    }
    const ref = super.success({
      nzTitle: '', // 'This is a notification message',
      // nzContent: '已新增訂單',
      nzIconType: 'check-circle',
      nzOkText: null,
      nzCancelText: null,
      nzClosable: false,
      nzMaskClosable: true,
      nzWidth,
      nzCentered: true,
      nzWrapClassName: 'tw-notice-wrapper',
      nzClassName: 'tw-notice success',
      nzBodyStyle: {
        padding: '0'
      },
      ...options
    });
    return ref;
  }
  override error(options?: ModalOptions) {
    let nzWidth = 180;
    if (typeof options?.nzContent == 'string') {
      nzWidth = (options?.nzContent.length + 2) * 16 + 40;
    }
    const ref = super.error({
      nzTitle: '', // 'This is a notification message',
      // nzContent: '已新增訂單',
      nzIconType: 'close-circle',
      nzOkText: null,
      nzCancelText: null,
      nzClosable: false,
      nzMaskClosable: true,
      nzWidth,
      nzCentered: true,
      nzWrapClassName: 'tw-notice-wrapper',
      nzClassName: 'tw-notice error',
      nzBodyStyle: {
        padding: '0'
      },
      ...options
    });
    return ref;
  }
  override warning(options?: ModalOptions) {
    let nzWidth = 180;
    if (typeof options?.nzContent == 'string') {
      nzWidth = (options?.nzContent.length + 2) * 16 + 40;
    }

    const ref = super.warning({
      nzTitle: '', // 'This is a notification message',
      nzIconType: 'exclamation-circle',
      nzOkText: null,
      nzCancelText: null,
      nzClosable: false,
      nzMaskClosable: true,
      nzWidth,
      nzCentered: true,
      nzWrapClassName: 'tw-notice-wrapper',
      nzClassName: 'tw-notice warning',
      nzBodyStyle: {
        padding: '0'
      },
      ...options
    });
    return ref;
  }

}
