import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MediaService } from '../../../../../../services/media.service';


@Component({
  selector: 'app-resources-video-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class ResourcesVideoIndexComponent implements OnInit {
  displayData: any[] = [];
  totalNumber = 0;
  currentPageIndex = 1;
  constructor(private userService: UserService,
              private mSrv: MediaService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }

  ngOnInit() {
    this.loadData(1);
  }
  loadData(page: any) {
    this.mSrv.getVideoSeriesList(page).then(resp => {
      this.displayData = resp.rows;
      this.totalNumber = resp.total;
      this.currentPageIndex = page;
    });
  }

  editData(id: any) {
    this.router.navigate([`/admin/v3/resources/video/edit/${id}`]);
  }
  deleteData(id: any) {
    this.mSrv.removeVideoById(id).then(() => {
      this.loadData(1);
      this.currentPageIndex = 1;
    });
  }
}
