import {
  AfterContentChecked,
  AfterContentInit, AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { IArea, getRegionAndRect, IRegion, IRegion2 } from './utils';




@Directive({
  selector: '[draggableElement]',
})
export class DraggableElementDirective implements OnInit, AfterViewInit {
  _enable = false;
  // @Input() ngxBoundsContainerSelector: string | null = null;
  @Input() container: Element | null = null;
  @Input() set enable(value: boolean) {
    console.log('enable', value);
    this._enable = value;
    this.ngAfterViewInit();
  }


  // @Input() valueType: keyof IArea | string = 'region2';
  // @Input() region: IArea[keyof IArea]|null = null;
  @Input() region: IRegion2|null = null;
  @Output() regionChange: EventEmitter<IRegion2> = new EventEmitter< IRegion2>();

  @Output() ngxDragStart: EventEmitter<void> = new EventEmitter<void>();
  @Output() ngxDragging: EventEmitter<void> = new EventEmitter<void>();
  @Output() ngxDragEnd: EventEmitter<void> = new EventEmitter<void>();

  private dragging = false;
  private mousePosition: { x: number; y: number } = { x: 0, y: 0 };
  // private container!: Element | null;

  constructor(private elRef: ElementRef<HTMLElement>, private renderer2: Renderer2) {
    if (this.elRef.nativeElement.parentElement) {
      const parentStyle = window.getComputedStyle(this.elRef.nativeElement.parentElement);
      if (
        (parentStyle.alignItems && parentStyle.alignItems !== 'normal') ||
        (parentStyle.justifyContent && parentStyle.justifyContent !== 'normal')
      ) {
        this.renderer2.setStyle(this.elRef.nativeElement.parentElement, 'alignItems', 'unset');
        this.renderer2.setStyle(this.elRef.nativeElement.parentElement, 'justifyContent', 'unset');
      }
      // @ts-ignore
      window.dd = this;
    }


    this.elRef.nativeElement.addEventListener('mousedown', (ev) => {
      if (!this._enable) {
        return;
      }
      if (!ev.defaultPrevented && ev.returnValue) {
        this.dragging = true;

        // const container = this.container!.getBoundingClientRect();
        // const rect = this.elRef.nativeElement.getBoundingClientRect();
        // const style = window.getComputedStyle(this.elRef.nativeElement);
        // const matrix = new DOMMatrixReadOnly(style.transform);
        // const oldX = matrix.m41;
        // const oldY = matrix.m42;
        //
        // const w = rect.width;
        // const h = rect.height;
        // const x = oldX;
        // const y = oldY;
        //
        // const x1 = oldX / container.width;
        // const y1 = oldY / container.height;
        // const x2 = (oldX + w) / container.width;
        // const y2 = (oldX + h) / container.height;

        // const r = this.getCurrentPosition();

        const r = getRegionAndRect(this.container!, this.elRef.nativeElement);
        this.regionChange.emit(r.region2);
        this.ngxDragStart.emit();
        this.renderer2.setStyle(this.elRef.nativeElement, 'z-index', 999);
        ev.preventDefault();
      }
    });
  }

  ngOnInit() {
    // setTimeout(() => {
    const container = this.container?.getBoundingClientRect();
      // @ts-ignore
      console.log('[draggable] ngOnInit', this.region, this.elRef, container);
    // });
  }
  ngAfterViewInit () {
    // setTimeout(() => {
      const container2 = this.container?.getBoundingClientRect();
      // @ts-ignore
      console.log('[draggable] ngAfterViewInit', this.region, this.elRef, container2);
      if (this.region) {
        const container = this.container?.getBoundingClientRect();
        // const x = container!.width * this.region.x1;
        // const y = container!.height * this.region.y1;
        // const width = (this.region.x2 - this.region.x1) * container!.width;
        // const height = (this.region.y2 - this.region.y1) * container!.height;

        const x = container!.width * this.region.x;
        const y = container!.height * this.region.y;
        const width = this.region.width * container!.width;
        const height = this.region.height * container!.height;



        this.renderer2.setAttribute(
          this.elRef.nativeElement,
          'style',
          `position:absolute;
        transform: ${ this.asTranslateString(x, y)};
        width: ${width}px;height: ${height}px;`
        );
      }
    // }, 1000);

    // this.container =
    //   typeof this.ngxBoundsContainerSelector === 'string'
    //     ? document.querySelector(this.ngxBoundsContainerSelector)
    //     : document.body;
    // const r = getRegionAndRect(this.container!, this.elRef.nativeElement);
    // console.log(1, r);
  }


  @HostListener('window:mousemove', ['$event']) onMouseMove(ev: MouseEvent) {
    if (this.dragging && this.mousePosition && !ev.defaultPrevented && ev.returnValue) {
      ev.preventDefault();
      let xDiff = ev.clientX - this.mousePosition.x;
      let yDiff = ev.clientY - this.mousePosition.y;

      const container = this.container!.getBoundingClientRect();
      const rect = this.elRef?.nativeElement?.getBoundingClientRect();
      const style = window.getComputedStyle(this.elRef.nativeElement);
      // const matrix = new WebKitCSSMatrix(style.transform);
      const matrix = new DOMMatrixReadOnly(style.transform);
      const oldX = matrix.m41;
      const oldY = matrix.m42;
      const containerX = 0; // container.x;
      const containerY = 0; // container.y;


      if (this.container) {
        if (oldX + xDiff < containerX) {
          xDiff = containerX - oldX;
        }
        if (
          oldX + xDiff + rect.width > containerX + container.width
        ) {
          xDiff = containerX + container.width - (oldX + rect.width);
        }

        if (oldY + yDiff < containerY) {
          yDiff = containerY - oldY;
        }
        if (oldY + yDiff + rect.height > containerY + container.height) {
          yDiff = containerY + container.height - (oldY + rect.height);
        }
      }
      // const r = this.getCurrentPosition(xDiff, yDiff);
      const r = getRegionAndRect(this.container!, this.elRef.nativeElement, xDiff, yDiff);
      // console.log(1, r);

      // const x = oldX + xDiff;
      // const y = oldY + yDiff;
      // const w = rect.width;
      // const h = rect.height;
      // console.log(2, r.rect.x, r.rect.y);
      // const x = container.width * r.x1;
      // const y = container.height * r.y1;
      // console.log(222222222, r);
      this.renderer2.setStyle(
        this.elRef.nativeElement,
        'transform',
        this.asTranslateString(r.rect.x, r.rect.y)
      );


      // const x1 = oldX / container.width;
      // const y1 = oldY / container.height;
      // const x2 = (oldX + w) / container.width;
      // const y2 = (oldX + h) / container.height;



      // console.log(3, {
      //   rect: { x, y, w, h},
      //   region: {x1, x2, y1, y2}
      // });
      // console.log(3, r.region);
      this.ngxDragging.emit();
      this.regionChange.emit(r.region2);
    }
    this.mousePosition = { x: ev.clientX, y: ev.clientY };
  }

  @HostListener('window:mouseup', ['$event']) onMouseUp(ev: MouseEvent) {
    if (this.dragging) {
      ev.preventDefault();

      // const r = this.getCurrentPosition();
      // const r = getRegionAndRect(this.container!, this.elRef.nativeElement);

      this.ngxDragEnd?.emit();

      this.dragging = false;
      this.renderer2.setStyle(this.elRef.nativeElement, 'z-index', 1);
    }
  }

  asTranslateString(x: number, y: number): string {
    return `translate(${this.asPxString(x)},${this.asPxString(y)})`;
  }

  asPxString(num: number): string {
    return num.toString().concat('px');
  }

}
