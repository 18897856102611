<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/v3/resources/video/index'">
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    </div>
    <form nz-form [formGroup]="validateForm">
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>套系名称</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入套系名称！">
                <input nz-input formControlName="name" placeholder="套系标题">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>设置封面</nz-form-label>
            <nz-form-control [nzSpan]="12" >
                <div class="series-cover">
                    <input nz-input type="hidden" formControlName="cover_id">
                    <app-upload-image-with-preview
                            [picUrl]="coverUrl"
                            [picItem]="null"
                            (imageUploaded)="onImageUploadSuccess($event)"
                    ></app-upload-image-with-preview>
                </div>

            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>添加视频</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback>
                <div class="book-images book-field">
                    <!--<app-upload-video-->
                            <!--[showUploadBtn]="false"></app-upload-video>-->
                    <input nz-input type="hidden" formControlName="finishUploading">

                    <nz-upload
                            [nzMultiple]="false"
                            nzAccept = "video/mp4"

                            [nzBeforeUpload]="handleBeforeUpload">
                        <button nz-button [disabled]="_currentUploadCount !== 0">
                            <i nz-icon nzType="upload"></i><span>Click to Upload Video</span>
                        </button>
                    </nz-upload>


                    <!--<nz-upload-->
                            <!--nzType="drag"-->
                            <!--[nzMultiple]="true"-->
                            <!--nzAccept = "video/mp4"-->
                            <!--nzListType="picture"-->
                            <!--nzAction="https://jsonplaceholder.typicode.com/posts/"-->
                            <!--[nzBeforeUpload]="handleBeforeUpload">-->
                        <!--<p class="ant-upload-drag-icon">-->
                            <!--<i nz-icon type="inbox"></i>-->
                        <!--</p>-->
                        <!--<p class="ant-upload-text">Click or drag video file to this area to upload</p>-->
                        <!--<p class="ant-upload-hint">Support for a single or bulk upload. </p>-->
                    <!--</nz-upload>-->


                    <!--<app-upload-audio-->
                            <!--[audioUrl]=""-->
                            <!--(audioUploaded)="onAudioUploadSuccess($event)"></app-upload-audio>-->

                    <div class="audio-list" style="margin-top: 2em;">
                        <ngx-sortable
                                listStyle="{
                                width: '100%',
                                height: '400px',
                            }"
                                [items]="displayFilesList"
                                [name]="'Video List'"
                                (listSorted)="listOrderChanged($event)">
                            <ng-template let-item>
                                <div class="sortable-list-item">
                                    <app-upload-video
                                        style="flex: 0 0 auto;"
                                        [videoFile]="item" [videoUrl]="item.url"
                                        (videoUploadFailure)="onVideoUploadFailure($event)"
                                        (videoUploaded)="onVideoUploadSuccess($event)">
                                    </app-upload-video>
                                    <i class="remove" *ngIf="item.res_id" (click)="removeAudioFile(item, $event)" nz-icon nzType="close-circle" nzTheme="outline"></i>
                                    <label style="margin-right: 1rem;flex: 1 1 auto;">{{item.name}}</label>
                                </div>
                                <nz-divider style="margin: 0"></nz-divider>
                            </ng-template>
                        </ngx-sortable>
                    </div>


                </div>
            </nz-form-control>
        </nz-form-item>




        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <!--(ngSubmit)="submitForm($event,validateForm.value)"-->
                <button style="margin-right:1rem;" nz-button nzType="primary" (click)="submitForm($event, validateForm.value)" [disabled]="!validateForm.valid">Submit</button>
                <!--<button nz-button (click)="resetForm($event)">Reset</button>-->
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
