import { Component, EventEmitter, Input, Output } from '@angular/core';




@Component({
  selector: 'common-search-input',
  template: `
    <nz-input-group  [nzSize]="size"
                     style="width: 300px;margin-right: .5rem" 
                     [nzPrefix]="prefixTemplateUser" [nzSuffix]="inputClearTpl">
      <input type="text" nz-input
             (input)="valueChange.emit(value)"
            [(ngModel)]="value" [placeholder]="placeholder||''"  />
    </nz-input-group>
    <ng-template #prefixTemplateUser>
      <span nz-icon nzType="search"></span>
    </ng-template>
    <ng-template #inputClearTpl>
      <span
        nz-icon
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="value"
        (click)="clearValue()"
      ></span>
    </ng-template>
  `,
  styles: [
    `
      :host{
        flex: 1;
      }
      i {
        cursor: pointer;
      }
    `
  ],
  // standalone: true
})
export class CommonSearchInputComponent {
  @Input() value!: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input() size: 'large' | 'default' | 'small' = 'default';
  @Input() placeholder!: string;
  @Output() clear = new EventEmitter();
  clearValue() {
    this.clear.emit(this.value);
    this.value = '';
  }
}
