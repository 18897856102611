import { Component, ContentChildren, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChildren } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { NzTableQueryParams } from 'ng-zorro-antd/table/src/table.types';




@Component({
  selector: 'tw-table-filter',
  template: `

    <div class="page-main-header" style="{{wrapperStyle}}">
      <div class="page-main-header-tools">
        <ng-content></ng-content>
      </div>
      <div class="page-main-header-actions" style="{{actionStyle}}">
        <button nz-button nzType="primary" style="margin-right: 1rem;" (click)="loadData({pageIndex: 1})">過濾</button>
      </div>
    </div>
  `,
  styles: [
    `
     
    `
  ],
  // standalone: true
})
export class CommonTwTableFilterComponent {

  @Output() doFilter: EventEmitter<any> = new EventEmitter();
  @Input() filtersTemplate?: TemplateRef<any>;
  @Input() wrapperStyle?: string;
  @Input() actionStyle?: string;
  //   [nzFrontPagination]="false"
  //   [nzShowTotal]="rangeTemplate"
  // (nzQueryParams)="onQueryParamsChange($event)"
  //   [nzData]="dataSet"
  //   [nzLoading]="isLoading"
  loadData(params: any): any {
    this.doFilter.emit(params);
  }
}
