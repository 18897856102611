import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../../services/school.service';
import { ClassService } from '../../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CoursewareService } from '../../../../../../services/courseware.service';
import { TwNoticeService } from '../../../../../../services/tw-notice.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';

@Component({
  selector: 'app-admin-resource-book-item-touch-to-read',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceBookItemTouchToReadComponent implements OnInit {
  user: any = null;
  constUserType = UserType;

  isLoading = false;
  form!: UntypedFormGroup;
  book_id = 0;
  currentPageIndex = 0;
  currentRegionIndex = 0;

  factory_pages: any[] = [];
  selectParagraphTextId = -1;


  coursewareData: any = {};
  syllabusChildren = [];
  bisType = 'cartoon';

  viewContainer!: ElementRef;

  @ViewChild('containerRef', { static: false })
  set containerRef(el: ElementRef)  {
    console.log('containerRef', el);
    this.viewContainer = el;
  }
  @ViewChildren('affix') affixs!: ElementRef[];


  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private coursewareService: CoursewareService,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private cdRef: ChangeDetectorRef,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();

    this.activatedRoute.title.subscribe({
      next: (title) => {
      }
    });
    this.bisType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;

    // console.log('[form] this.activatedRoute', this.activatedRoute.pathFromRoot[2].data);
    // this.activatedRoute.pathFromRoot[2].data.subscribe((data) => {
    //   this.bisType = data['bis_type'];
    //   console.log('bisType', this.bisType);
    // });

    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.school && parseInt(query.school, 10) === query.school * 1) {
    //     this.school_id = parseInt(query.school, 10);
    //   }
    // });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });


    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.book_id = params['book_id'] || 0;
    });

    // @ts-ignore
    window.aaa = this;

  }

  ngOnInit() {
    if (this.book_id) {
      this.isLoading = true;
      this.coursewareService.fetch(this.bisType, this.book_id).then((result) => {
        console.log('coursewareService.fetch', result);
        this.coursewareData = result;
        // const scaleX = rect.width / width;
        // const scaleY = rect.height / height;
        // this.imageScale = Math.min(scaleX, scaleY);
        if (!result?.data?.factory_pages) {
          this.nzMessageService.error('请先保存Reading数据');
          return;
        }
        this.factory_pages = result?.data.factory_pages.filter((p: any) => {
          return p.show;
        });
        this.isLoading = false;
      }).catch(err => {
        this.isLoading = false;
        this.nzMessageService.error(err.message || 'System Error');
      });
    }

  }

  pageIndexChange(index: number) {
    console.log('pageIndexChange', index);
    this.currentPageIndex = index;

    // const rect = this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones[0].rect;

    const affix = this.affixs.find((_, i) => i === index );


    const pageImage = this.factory_pages[index].image_area.images[0];
    if (!pageImage.img_width || !pageImage.img_height) {
      this.isLoading = true;
      const img = new Image();
      img.onload = () => {
        pageImage.img_width = img.width;
        pageImage.img_height = img.height;
        // @ts-ignore
        // affix.ngAfterViewInit();
        this.isLoading = false;

      };
      img.src = pageImage.imageUrl;
    } else {
      // @ts-ignore
      // affix.ngAfterViewInit();
    }

    // setTimeout(() => {
    //   this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones[0].rect = {...rect};
    // }, 1000);
  }


  anchorRegion($event: any, regionIndex: number) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.currentRegionIndex = regionIndex;
  }

  checkRegionConfig (pageIndex: number) {
    console.log('checkRegionConfig, page:', pageIndex);
    let msg = '';
    const idx = this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones?.findIndex((r: any, i: number) => {
      if (typeof r.relatedParagraphId !== 'undefined') {
        return false;
      } else {
        if (Boolean(!r.text)) {
          msg = `please fill text for region ${i + 1} in page ${pageIndex + 1}`;
          return true;
        }

        if (Boolean(!r.audio_url)) {
          msg = `please upload audio for region ${i + 1} in page ${pageIndex + 1}`;
          return true;
        }
      }

      return false;
    });
    if (idx >= 0) {
      this.nzMessageService.error(msg);
      return idx;
    }
    return -1;
  }
  checkPageRegionConfig() {

    const pIdx = this.factory_pages.findIndex((p: any, pi: number) => {
      const rIndex = p.image_area.images[0].hotzones.findIndex((r: any, ri: number) => {
        const idx = this.checkRegionConfig(pi);
        return idx >= 0;
      });
      return rIndex >= 0;
    });
    return pIdx;
  }
  saveCoursewarePageRegion() {
    if (this.isLoading) {
      return;
    }
    const idx = this.checkPageRegionConfig();
    if (idx >= 0) {
      return;
    }


    const hotZoneData = {
      id: this.book_id,
      data: {
        ...this.coursewareData.data,
        // pages: this.coursewareData.pages,
        factory_pages: this.factory_pages}};
    console.log('hotZoneData', hotZoneData);
    this.isLoading = true;
    this.coursewareService.save(this.bisType, hotZoneData).then((result) => {
      this.isLoading = false;
      this.twNoticeService.success({nzContent: '儲存成功'});
      // const modal = this.twNoticeService.success({nzContent: '新增成功'})
      // modal.afterClose.subscribe(() => {
      //   this.router.navigate([`/admin/v3/resource/${this.bisType}/manage`]);
      // });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }
  changeRegionRef(evt: any) {
    console.log('changeRegionRef', evt);
    const region = this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones[this.currentRegionIndex]
    region.relatedParagraphId = 0;
    // this.currentPageIndex
    // this.currentRegionIndex
  }
  addImageRegion() {
    console.log('addImageRegion');

    const result = this.checkRegionConfig(this.currentRegionIndex);
    if (result >= 0) {
      return;
    }
    let hotzones = this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones;
    if (!hotzones) {
      this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones = [];
      hotzones = this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones;
    }
    hotzones.push({
      id:  hotzones.length,
      imageUrl: '',
      text: '',
      rect: {
        // x: 0,
        // y: 0,
        // width: 0,
        // height: 0,
      },
      paddingY: 0,
      paddingX: 0,
      relatedParagraphId: -1,
    });
    this.currentRegionIndex = hotzones.length - 1;
    // this.factory_pages[this.currentPageIndex].text_area.paragraphs[this.currentRegionIndex] = {
    //   cnTranslation: ''
    // };
  }
  regionParagraphText(pageIndex: number, regionIndex: number,  relatedParagraphId: number) {
    if (relatedParagraphId === -1) {
      return this.factory_pages[pageIndex].image_area.images[0].hotzones[pageIndex];
    } else {
      return this.factory_pages[pageIndex].text_area.paragraphs[regionIndex];
    }

  }
  regionParagraphTextTranslation(pageIndex: number, regionIndex: number, relatedParagraphId: number) {
    if (relatedParagraphId === -1) {
      return this.factory_pages[pageIndex].image_area.images[0].hotzones[pageIndex].l
    } else {
      return this.factory_pages[pageIndex].text_area.paragraphs[regionIndex];
    }
  }

  onAudioUploadFailure(evt: any, regionIndex: number) {
    console.log('onAudioUploadFailure', evt);
  }
  onAudioUploadSuccess(evt: any, regionIndex: number) {
    const {duration, name} = evt.file;
    const { hotzones } = this.factory_pages[this.currentPageIndex].image_area.images[0];
    hotzones[regionIndex].audio = evt.url;
    hotzones[regionIndex].audioName = name;
    hotzones[regionIndex].duration = duration;
    console.log('onAudioUploadSuccess', evt);
  }
  removeRegion($event: any, k: number) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones.splice(k, 1);
    this.currentRegionIndex = 0;
  }
  clearRegionAudio($event: any, k: number) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones[k].audio_url = null;
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    moveItemInArray(this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones, event.previousIndex, event.currentIndex);
    console.log('hotzones', this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones);
  }

  translate(evt: any, index: number, comp: any) {
    console.log('translate', evt, comp);
    // @ts-ignore
    comp.nzLoading = true;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);
    fetch('https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=en&to=zh-Hans', {
      method: 'POST',
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': 'b61e900d6c7d483c9b3666430c887835',
        'Ocp-Apim-Subscription-Region': 'eastasia',
      },
      body: JSON.stringify([
        {
          // @ts-ignore
          'Text': this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones[index].text
        }
      ])
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // @ts-ignore
        this.factory_pages[this.currentPageIndex].image_area.images[0].hotzones[index].cnTranslation =  data[0].translations[0].text;
        comp.nzLoading = false;
        clearTimeout(timeoutId);
      })
      .catch(error => {
        clearTimeout(timeoutId);
        comp.nzLoading = false;
        console.error('Error:', error);
      });
  }


  protected readonly JSON = JSON;
}
