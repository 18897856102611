import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions';
import Hover from 'wavesurfer.js/dist/plugins/hover';
// import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'
const random = (min: number, max: number) => Math.random() * (max - min) + min;
const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;



interface Part {
  start: number;
  end: number;
  content: string;
}

@Component({
  selector: 'app-wave-player-v2',
  templateUrl: './wave-player-v2.component.html',
  styleUrls: ['./wave-player-v2.component.scss']
})
export class WavePlayerV2Component implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  isLoadingMediaData = false;
  zoom = 1;
  _waveformElRef!: ElementRef;
  regions!: RegionsPlugin;
  ws!: WaveSurfer;
  activeRegion: Region | null = null;

  @Input() audioUrl?: string;
  @Input() loop?: true;
  @Input() height = 0;
  @Input() width = 0;
  // @Input() parts: Part[] = [];
  @Input() onDecodeCallback!: (comp: typeof this) => any[];
  @Input() onRegionCreatedCallback!: Function;
  @Input() onRegionClickedCallback!: Function;
  @Input() redrawRegionTrigger!: EventEmitter<any>;
  @Input() redrawRegionCallback!: Function;



  // @ViewChild('waveform', {static: true}) waveformElRef!: ElementRef;
  @ViewChild('waveform', { static: false })
  set waveformElRef(el: ElementRef)  {
    this._waveformElRef = el;
    const width = el.nativeElement.offsetWidth;
    this.ws = WaveSurfer.create({
      // container: '#waveform',
      height: this.height || 30,
      width: this.width || width,
      container: this._waveformElRef.nativeElement,
      waveColor: 'rgb(200, 0, 200)',
      progressColor: 'rgb(100, 0, 100)',
      url: this.audioUrl,
      plugins: [this.regions, Hover.create({
        lineColor: '#ff0000',
        lineWidth: 2,
        labelBackground: '#555',
        labelColor: '#fff',
        labelSize: '11px',
      })],
    });

    this.ws.on('decode', () => {
      console.log('decode');
      this._redrawRegions();
      // if (this.parts) {
      //   this.parts.forEach((part: Part) => {
      //     this.addPart(part);
      //   });
      // }
      return;
      // Regions
      this.regions.addRegion({
        start: 0,
        end: 8,
        content: 'Resize me',
        color: randomColor(),
        drag: false,
        resize: true,
      });
      this.regions.addRegion({
        start: 9,
        end: 10,
        content: 'Cramped region',
        color: randomColor(),
        minLength: 1,
        maxLength: 10,
      });
      this.regions.addRegion({
        start: 12,
        end: 17,
        content: 'Drag me',
        color: randomColor(),
        resize: false,
      });

      // Markers (zero-length regions)
      this.regions.addRegion({
        start: 19,
        content: 'Marker',
        color: randomColor(),
      });
      this.regions.addRegion({
        start: 20,
        content: 'Second marker',
        color: randomColor(),
      });
    });

    this.regions.enableDragSelection({
      color: 'rgba(255, 0, 0, 0.1)',
    });

    this.regions.on('region-updated', (region) => {
      console.log('Updated region', region);
    });
    this.regions.on('region-update', (region, side) => {
      console.log('Update region', region, side);
    });

    // Update the zoom level on slider change
    this.ws.once('decode', () => {
      console.log('decode ready');
      this.isLoadingMediaData = false;
      // document.querySelector('input[type="range"]').oninput = (e) => {
      //   const minPxPerSec = Number(e.target.value)
      //   this.ws.zoom(minPxPerSec);
      // }
    });
    this.ws.on('interaction', () => {
      this.activeRegion = null;
    });

    this.regions.on('region-created', (region) => {
      console.log('region-created', region);
      if ( this.onRegionCreatedCallback) {
        this.onRegionCreatedCallback(region);
      }
    });
    this.regions.on('region-in', (region) => {
      console.log('region-in', region);
      this.activeRegion = region;
    });
    this.regions.on('region-out', (region) => {
      console.log('region-out', region);
      if (this.activeRegion === region) {
        if (this.loop) {
          region.play();
        } else {
          this.activeRegion = null;
        }
      }
    });
    this.regions.on('region-clicked', (region, e) => {
      e.stopPropagation(); // prevent triggering a click on the waveform
      this.activeRegion = region;
      if (this.onRegionClickedCallback) {
        this.onRegionClickedCallback(region);
      }
      region.play();
      region.setOptions({ color: randomColor() });
    });
  }




  constructor() {
    this.regions = RegionsPlugin.create();
  }

  ngOnInit() {

  }
  ngOnChanges(value: any) {
    console.log('ngOnChanges', value);
    if (value.audioUrl) {
      this.audioUrl = value.audioUrl.currentValue;
    }

    // if (value.parts) {
    //   this.parts = value.parts.currentValue;
    // }
    if (this.ws && this.audioUrl) {
      this.ws.load(this.audioUrl);
    }

    if (value.redrawRegionTrigger && value.redrawRegionTrigger.currentValue !== value.redrawRegionTrigger.previousValue) {
      if (value.redrawRegionTrigger.previousValue) {
        value.redrawRegionTrigger.previousValue.unsubscribe();
      }
      console.log('value.redrawRegionTrigger.currentValue', value.redrawRegionTrigger.currentValue);
      value.redrawRegionTrigger.currentValue.subscribe((options: any) => {
        console.log('redraw region');
        this._redrawRegions(options);
      });
    }
    // if (this.parts
    //   && Array.isArray(this.parts)) {
    //   this.regions.clearRegions();
    //   if (this.parts) {
    //     this.parts.forEach((part: Part) => {
    //       this.addPart(part);
    //     });
    //   }
    // }
  }

  _redrawRegions(options?: any) {
    console.log('_redrawRegions', options);
    const rs = this.regions.getRegions();
    if (rs[0]) {
      // rs[0].end = 15;
      rs[0].setOptions({
        end: 15
      });
      return;
    }

    // debugger
    // this.regions.clearRegions();
    // if (index) {
    //   const regions = this.regions.getRegions();
    //   debugger
    // }
    if (this.onDecodeCallback) {
      const parts = this.onDecodeCallback(this);
      parts.forEach((part: Part) => {
        this.addPart(part);
      });
    }
  }

  addPart(part: any) {

    const r = this.regions.addRegion({
      // start: part.start,
      // end: part.end,
      // content: part.content,
      color: randomColor(),
      drag: true,
      resize: true,
      content: `${part.id + 1}`,
      ...part.audioPoint
    });
    r.on('update', (side) => {
      console.log('update', side);
    });
  }

  ngAfterViewInit() {

  }
  ngOnDestroy() {
    this.regions.destroy();
    this.ws.destroy();
  }

  zoomChange($event: any) {
    console.log('zoom change', $event);
    this.ws.zoom(Number($event));
  }
}

