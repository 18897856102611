<nz-upload class="p-audio-uploader"
           style="width: 100%;"
           [nzShowUploadList]="false"
           nzAccept = ".mp3"
           [nzBeforeUpload]="customUpload">

  <ng-container *ngIf="displayText && !displayTextTemplate">
    <button nz-button type="button" *ngIf="showUploadBtn"
            [ngClass]="{error: !!errors}">
      <i class="anticon anticon-upload"></i><span>Upload Audio</span>
    </button>
    <span>{{displayText}}</span>
  </ng-container>
  <ng-container *ngTemplateOutlet="displayTextTemplate;"></ng-container>
</nz-upload>
<!--<nz-divider *ngIf="showUploadBtn"></nz-divider>-->
<nz-progress *ngIf="uploading" [nzPercent]="progress"></nz-progress>
<!--<audio *ngIf="audioUrl && !uploading" [src]="audioUrl" controls></audio>-->
