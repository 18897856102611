import { Pipe, PipeTransform } from '@angular/core';
import { HWResourceService } from '../services/huawei-resource.service';


@Pipe({
  name: 'mediaUrlPipe'
})
export class MediaUrlPipe implements PipeTransform {

  transform(url = '') {
    const u = new URL(window.location.href);
    const protocol = u.protocol;
    const host = HWResourceService.cdnHost;
    const bucket = '';
    return url && (url.startsWith('http') ? url : `${protocol}//${host}/${(bucket ? bucket + '/' : '')}` +  (url.startsWith('/' ? url.substring(1) : url)));
  }

}
