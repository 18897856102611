import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LevelsService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  fetchLevelTree() {
    return super.get(`/levels/tree`).then((resp) => {
      return resp;
    });
  }
  fetchLevels() {
    return super.get(`/levels`).then((resp) => {
      return resp;
    });
  }
  fetchChildrenLevels(parent: string|number) {
    return super.get(`/levels/tree/${parent}`).then((resp) => {
      return resp;
    });
  }

  checkNameExist (query: any) {
    let url = `/levels/check/name`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }
  list(query: any) {
    let url = `/levels/list`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }

  deleteItem(data: any) {
    return this.post('/levels/item/delete', data);
  }
  save(data: any) {
    return this.post('/levels/item/save', data);
  }
  seq(data: any) {
    return this.post('/levels/item/seq', data);
  }

}
