import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../../services/school.service';
import { ClassService } from '../../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CoursewareService, Paragraph } from '../../../../../../services/courseware.service';
import { TwNoticeService } from '../../../../../../services/tw-notice.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { UploadImageWithPreviewComponent } from '../../../../../common/upload-image-with-preview/upload-image-with-preview.component';
import {
  UploadImageWithPreviewV2Component
} from '../../../../../common/upload-image-with-preview-v2/upload-image-with-preview-v2.component';
import { HWResourceService } from '../../../../../../services/huawei-resource.service';
import { WavePlayerV2Component } from '../../../../../common/wave-player-v2/wave-player-v2.component';


@Component({
  selector: 'app-admin-resource-book-item-reading',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceBookItemReadingComponent implements OnInit {
  user: any = null;
  constUserType = UserType;

  isLoading = false;
  form!: UntypedFormGroup;
  book_id = 0;
  currentPageIndex = 0;

  pages: any[] = [];
  factory_pages: any[] = [];
  // currentPageAudioParts: any[] = [];
  coursewareData: any = {};
  currentPageAudioUrl = null;

  redrawRegionTrigger = new EventEmitter<any>();


  bookAttrs: any = {};
  syllabusChildren = [];
  bisType = 'cartoon';
  imageScale = 1;


  @ViewChild('pageImage', {static: false})
  pageImage: UploadImageWithPreviewV2Component | undefined;
  protocol: string;
  host: string;
  bucket: string | undefined;


  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private coursewareService: CoursewareService,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();

    const u = new URL(window.location.href);
    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;
    this.activatedRoute.title.subscribe({
      next: (title) => {
      }
    });

    // @ts-ignore
    this.bisType = this.router.browserUrlTree.root.children.primary?.segments[3]?.path;
    // this.activatedRoute.pathFromRoot[2].data.subscribe((data) => {
    //   this.bisType = data['bis_type'];
    //   console.log('bisType', this.bisType);
    // });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.school && parseInt(query.school, 10) === query.school * 1) {
    //     this.school_id = parseInt(query.school, 10);
    //   }
    // });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });


    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.book_id = params['book_id'] || 0;
    });

    // @ts-ignore
    window.aaa = this;
  }


  onDecodeCallback = (comp: WavePlayerV2Component) => {
    console.log('onDecodeCallback', this);
    console.log('this.coursewareData', this.factory_pages[this.currentPageIndex].text_area.paragraphs);
    return this.factory_pages[this.currentPageIndex].text_area.paragraphs;
  }

  ngOnInit() {


    if (this.book_id) {
      this.isLoading = true;
      this.coursewareService.fetch(this.bisType, this.book_id).then((result) => {
        console.log('coursewareService.fetch', result);
        this.coursewareData = result;

        if (result?.data?.pages) {
          this.pages = [...result?.data.pages];
        }
        this.factory_pages = result?.data?.factory_pages || [];
        // if (!result?.data?.factory_pages) {
        //   if (!result.data) {
        //     result.data = {};
        //   }
        //   if (!result.data.dpe_page) {
        //     this.factory_pages = this.coursewareService.createDpePages(result);
        //     // this.factory_pages = factory_pages.filter(fp => fp.show);
        //   }
        // } else {
        //   this.factory_pages = result?.data?.factory_pages;
        // }
        this.isLoading = false;
      }).catch(err => {
        this.isLoading = false;
        this.nzMessageService.error(err.message || 'System Error');
      });
    }

  }

  pageIndexChange(index: number) {
    console.log('pageIndexChange', index);
    this.currentPageIndex = index;
    if (this.factory_pages[this.currentPageIndex].audio_url) {
      this.currentPageAudioUrl = this.factory_pages[this.currentPageIndex].audio_url;
    } else {
      this.currentPageAudioUrl = this.coursewareData.audio;
    }
    this.redrawRegionTrigger.emit();
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex == event.currentIndex) {
      return;
    }
    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);
    console.log('drop', this.pages);
  }
  onImageUploaded(evt: any) {
    const {width, height} = evt.file;
    console.log('onImageUploaded', {width, height}, evt);
    console.log('pageImage', this.pageImage?.previewElement);
    if (this.pageImage) {
      const rect = this.pageImage.previewElement?.nativeElement.getBoundingClientRect();
      console.log('rect', rect);
      const scaleX = rect.width / width;
      const scaleY = rect.height / height;
      this.imageScale = Math.min(scaleX, scaleY);
    }
    this.pages[this.currentPageIndex].img = evt.url;
    this.pages[this.currentPageIndex].img_width = width;
    this.pages[this.currentPageIndex].img_height = height;
  }
  onAudioUploadFailure(evt: any) {
    console.log('onAudioUploadFailure', evt);
  }
  onBookAudioUploadSuccess(evt: any) {
    this.coursewareData.audio_url = evt.url;
    if (this.factory_pages[this.currentPageIndex].audio_url) {
      this.currentPageAudioUrl = this.factory_pages[this.currentPageIndex].audio_url;
    } else {
      this.currentPageAudioUrl = this.coursewareData.audio;
    }
    // this.coursewareData.data = evt.url;
  }
  clearBookAudio(evt: any) {
    this.coursewareData.audio_url = '';
  }
  onAudioUploadSuccess(evt: any) {
    // console.log('onAudioUploadSuccess', evt)
    const {duration, name} = evt.file;
    this.pages[this.currentPageIndex].audio = evt.url;
    this.pages[this.currentPageIndex].duration = duration;

    this.factory_pages[this.currentPageIndex].audio_url = evt.url;
  }

  clearAudio($event: any) {
    console.log('clearAudio', $event);
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.pages[this.currentPageIndex].audio = null;
    this.factory_pages[this.currentPageIndex].audio_url = null;
    this.currentPageAudioUrl = this.coursewareData.audio;

  }
  removePage(): void {
    this.twNoticeService.confirm({
      nzContent: '刪除此頁?',
      nzOnOk: (e) => {
        this.pages.splice(this.currentPageIndex, 1);
      }
    });

  }

  deleteParagraph(evt: any, index: number) {
    this.factory_pages[this.currentPageIndex].text_area.paragraphs.splice(index, 1);
  }
  addNewParagraph() {
    const idx = this.factory_pages[this.currentPageIndex].text_area.paragraphs.length;
    this.factory_pages[this.currentPageIndex].text_area.paragraphs.push({
      id: idx,
      text: '',
      cnTranslation: '',
      audioUrl: '',
      audioPoint: {
        start: 0,
        end: 0,
      },
      keyWords: [],
    });
    this.redrawRegionTrigger.emit();
    // this.currentPageAudioParts = [...this.factory_pages[this.currentPageIndex].text_area.paragraphs];
  }

  highlightAudioPart(pageIndex: number, paragraphIdx: number) {
    // this.currentPageAudioParts = [...this.dpe_pages[pageIndex].text_area.paragraphs[paragraphIdx]];
  }
  resizeAudioPart($event: any, pageIndex: number, paragraphIdx: number) {
    console.log('resizeAudioPart', $event, pageIndex, paragraphIdx);
    this.redrawRegionTrigger.emit({pageIndex, paragraphIdx, value: $event});
  }

  checkPageConfig () {
    let msg = '';
    const idx = this.factory_pages.findIndex((p, i) => {
       if (Boolean(!p.image_area.images[0].imageUrl)) {
         msg = `please upload image in page ${i}`;
         return true;
       }
      if (p.show) {
        const allPageHasText = p.text_area.paragraphs.every(( (pa: Paragraph) => {
          return pa.text && pa.text.trim();
        }));
        if (Boolean(!allPageHasText)) {
          msg = `please fill text in page ${i}`;
          return true;
        }
      }


      if (!this.currentPageAudioUrl && Boolean(!p.audio_url)) {
         msg = `please upload audio in page ${i}`;
         return true;
       }
       return false;
    });
    if (idx >= 0) {
      this.nzMessageService.error(msg);
      return idx;
    }
    return -1;
  }

  addNewPage() {
    const idx = this.checkPageConfig();
    if (idx >= 0) {
      this.currentPageIndex = idx;
      return;
    }
    this.pages.push({});
  }


  saveCoursewarePage() {
    if (this.isLoading) {
      return;
    }
    const idx = this.checkPageConfig();
    if (idx >= 0) {
      this.currentPageIndex = idx;
      return;
    }

    // const factory_pages = this.coursewareService.createDpePages(this.book_id, this.pages);
    const newData = {id: this.coursewareData.id, data: {...this.coursewareData.data, factory_pages: this.factory_pages }};
    console.log('factory_pages', newData);
    // delete newData.created_date;
    // delete newData.updated_date;
    // this.coursewareService.save({id: this.book_id, data: {pages: this.pages}}).then((result) => {
    this.coursewareService.save(this.bisType, newData).then((result) => {
      this.isLoading = false;
      this.twNoticeService.success({nzContent: '儲存成功'});
      // const modal = this.twNoticeService.success({nzContent: '新增成功'})
      // modal.afterClose.subscribe(() => {
      //   this.router.navigate([`/admin/v3/resource/${this.bisType}/manage`]);
      // });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }


  submitForm = ($event: any, value: any) => {
    if ($event) {
      $event.preventDefault();
    }
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
    }
    if (this.isLoading) {
      return;
    }
    console.log('submitForm', $event, value);
    this.isLoading = true;
    this.coursewareService.save(this.bisType, value).then((result) => {
      this.isLoading = false;
      const modal = this.twNoticeService.success({nzContent: '新增成功'})
      modal.afterClose.subscribe(() => {
        this.router.navigate([`/admin/v3/resource/${this.bisType}/courseware`]);
      });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsPristine();
      this.form.controls[ key ].updateValueAndValidity();
    }
  }

  translate(evt: any, index: number, comp: any) {
    console.log('translate', evt, comp);
    // @ts-ignore
    window.aaa = comp;
    comp.nzLoading = true;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);
    fetch('https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=en&to=zh-Hans', {
      method: 'POST',
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': 'b61e900d6c7d483c9b3666430c887835',
        'Ocp-Apim-Subscription-Region': 'eastasia',
      },
      body: JSON.stringify([
       {
         // @ts-ignore
          'Text': this.factory_pages[this.currentPageIndex].text_area.paragraphs[index].text
        }
      ])
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // @ts-ignore
        this.factory_pages[this.currentPageIndex].text_area.paragraphs[index].cnTranslation = data[0].translations[0].text;
        comp.nzLoading = false;
        clearTimeout(timeoutId);
      })
      .catch(error => {
        clearTimeout(timeoutId);
        comp.nzLoading = false;
        console.error('Error:', error);
      });
  }


  protected readonly JSON = JSON;
}
