import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {
    ResourcePushWeekTemplateHomework,
    ResourcePushWeekTemplateHomeworkLessonSubTypes,
    ResourcePushWeekTemplateHomeworkResource,
    ResourcePushWeekTemplateHomeworkTypes, 
    ResourcePushWeekTemplateHomeworkTypeValues,
    ResourcePushWeekTemplateLevel3Resource,
} from 'src/app/model/ResourcePushTemplate';
import {SyllabusService} from '../../../../../../services/syllabus.service';

@Component({
    selector: 'app-admin-resource-category-template-homework',
    templateUrl: './homework.component.html',
})
export class AdminResourceCategoryTemplateHomeworkComponent implements OnInit {
    @Input() index!: number;
    @Input() homework!: ResourcePushWeekTemplateHomework;
    @Output() homeworkChange = new EventEmitter<ResourcePushWeekTemplateHomework>();

    level1Resources: ResourcePushWeekTemplateHomeworkResource[] = [];
    level2Resources: ResourcePushWeekTemplateHomeworkResource[] = [];
    level3Resources: ResourcePushWeekTemplateHomeworkResource[] = [];
    level4ResourcesMap: Record<string, ResourcePushWeekTemplateHomeworkResource[]> = {};

    @Output() deleteHomework = new EventEmitter<void>();
    @Output() addHomework = new EventEmitter<void>();

    form!: FormGroup;
    loading = true;

    protected readonly ResourcePushWeekTemplateHomeworkTypes = ResourcePushWeekTemplateHomeworkTypes;
    protected readonly ResourcePushWeekTemplateHomeworkLessonSubTypes = ResourcePushWeekTemplateHomeworkLessonSubTypes;
    protected readonly ResourcePushWeekTemplateHomeworkTypeValues = ResourcePushWeekTemplateHomeworkTypeValues;

    constructor(
        private fb: FormBuilder,
        private syllabusService: SyllabusService,
    ) {}

    async ngOnInit() {
        this.initForm();
        this.setupFormListeners();
        const { type, level1ResourceId, level2ResourceId, level3Resources } = this.homework;
        const tasks = [
            type && this.updateLevel1Resources(type),
            level1ResourceId && this.updateLevel2Resources(level1ResourceId),
            level2ResourceId && this.updateLevel3Resources(),
        ];

        for (const level3Resource of level3Resources || []) {
            tasks.push(this.updateLevel4Resources(level3Resource.level3ResourceId, level3Resource.level3ResourceType));
        }

        await Promise.all(tasks.filter(Boolean));
        this.loading = false;
    }

    private initForm() {
        this.form = this.fb.group({
            type: [this.homework.type || null, [Validators.required]],
            level1ResourceId: [this.homework.level1ResourceId || null, [Validators.required]],
            level1ResourceName: [this.homework.level1ResourceName || ''],
            level2ResourceId: [this.homework.level2ResourceId || null, [Validators.required]],
            level2ResourceName: [this.homework.level2ResourceName || ''],
            level3ResourceIds: [this.homework.level3ResourceIds || [], [
                () => this.isLevel3ResourceIds() ? Validators.minLength(1) : null,
            ]],
            level3Resources: this.fb.array(
                this.homework.level3Resources?.map(level3Resource => this.createLevel3ResourceForm(level3Resource)) || [],
                {
                    validators: [() => this.isLevel3ResourceArray() ? Validators.minLength(1) : null]
                }
            )
        });
    }

    private createLevel3ResourceForm(level3Resource?: ResourcePushWeekTemplateLevel3Resource) {
        if (!level3Resource) {
            level3Resource = {
                level3ResourceId: null,
                level3ResourceType: null,
                level4ResourceIds: [],
            };
        }
        return this.fb.group({
            level3ResourceId: [level3Resource.level3ResourceId || null, [Validators.required]],
            level3ResourceType: [level3Resource.level3ResourceType || null, [Validators.required]],
            level4ResourceIds: [level3Resource.level4ResourceIds || [], [Validators.required, Validators.minLength(1)]]
        });
    }
    

    private async updateLevel1Resources(type?: string) {
        if (!type) {
            this.level1Resources = [];
            return;
        }
        this.level1Resources = await this.syllabusService.getResources(type, 0);
    }

    private async updateLevel2Resources(level1ResourceId?: number) {
        const type = this.form.get('type')!.value;
        if (!level1ResourceId || !type) {
            this.level2Resources = [];
            return;
        }
        this.level2Resources = await this.syllabusService.getResources(type, level1ResourceId);
    }

    private async updateLevel3Resources() {
        const level1ResourceId = this.form.get('level1ResourceId')!.value;
        const level2ResourceId = this.form.get('level2ResourceId')!.value;
        if (!level1ResourceId || !level2ResourceId) {
            this.level3Resources = [];
            return;
        }
        this.level3Resources = await this.syllabusService.getCoursewares(level1ResourceId, level2ResourceId);
    }

    private setupFormListeners() {
        // 监听表单变化并更新父组件
        this.form.valueChanges.subscribe(value => {
            this.homeworkChange.emit({
                ...this.homework,
                ...value
            });
        });

        // 监听类型变化
        this.form.get('type')!.valueChanges.subscribe(async type => {
            const level1ResourceIdControl = this.form.get('level1ResourceId')!;
            level1ResourceIdControl.setValue(null);
            await this.updateLevel1Resources(type);
        });

        // 监听教材变化
        this.form.get('level1ResourceId')!.valueChanges.subscribe(async level1ResourceId => {
            const level2ResourceIdControl = this.form.get('level2ResourceId')!;
            level2ResourceIdControl.setValue(null);
            await this.updateLevel2Resources(level1ResourceId);
        });

        // 监听教材级别变化
        this.form.get('level2ResourceId')!.valueChanges.subscribe(async level2ResourceId => {
            this.form.get('level3ResourceIds')!.setValue([]);
            this.getLevel3ResourcesForm().clear();
            await this.updateLevel3Resources();
        });
    }

    getLevel3ResourcesForm(): FormArray {
        const control = this.form.get('level3Resources');
        if (!(control instanceof FormArray)) {
            throw new Error('level3Resources is not a FormArray');
        }
        return control;
    }

    isLevel3ResourceIds(): boolean {
        const type = this.form?.get('type')?.value;
        return type ? [
            ResourcePushWeekTemplateHomeworkTypeValues.VIDEO,
            ResourcePushWeekTemplateHomeworkTypeValues.AUDIO,
            ResourcePushWeekTemplateHomeworkTypeValues.DUBBING,
            ResourcePushWeekTemplateHomeworkTypeValues.BOOK,
        ].includes(type) : false;
    }

    isLevel3ResourceArray(): boolean {
        const type = this.form?.get('type')?.value;
        return type
            ? [ResourcePushWeekTemplateHomeworkTypeValues.CARTOON, ResourcePushWeekTemplateHomeworkTypeValues.EXERCISE].includes(type)
            : false;
    }

    getValidateStatus(control: AbstractControl | null): string {
        if (!control) { return ''; }
        return control.touched ? (control.invalid ? 'error' : 'success') : '';
    }

    getLevel4Resources(level3ResourceControl: AbstractControl): ResourcePushWeekTemplateHomeworkResource[] {
        const level3ResourceId = level3ResourceControl.get('level3ResourceId')?.value;
        const level3ResourceType = level3ResourceControl.get('level3ResourceType')?.value;
        if (level3ResourceId === undefined || level3ResourceId === null) {
            return [];
        }
        const key = [level3ResourceId, level3ResourceType].filter(Boolean).join(':');
        return this.level4ResourcesMap[key] || [];
    }

    handleAddLevel3ResourceAdd() {
        const level3ResourceForm = this.createLevel3ResourceForm();
        this.getLevel3ResourcesForm().push(level3ResourceForm);
        const type = this.form.get('type')?.value;
        if (type === ResourcePushWeekTemplateHomeworkTypeValues.CARTOON) {

            const _updateLevel4Resources = async () => {
                const level3ResourceId = level3ResourceForm.get('level3ResourceId')?.value;
                const level3ResourceType = level3ResourceForm.get('level3ResourceType')?.value;
                await this.updateLevel4Resources(level3ResourceId, level3ResourceType);
            };

            level3ResourceForm.get('level3ResourceType')?.valueChanges.subscribe(_updateLevel4Resources);
            level3ResourceForm.get('level3ResourceId')?.valueChanges.subscribe(_updateLevel4Resources);
        }
    }


    private async updateLevel4Resources(level3ResourceId?: number | null, level3ResourceType?: number | null) {
        if (!level3ResourceId) {
            return;
        }

        const key = [level3ResourceId, level3ResourceType].filter(Boolean).join(':');
        if (this.level4ResourcesMap[key]) {
            return;
        }

        let resources: ResourcePushWeekTemplateHomeworkResource[] = [];
        switch (level3ResourceType) {
            case 2:
                resources = await this.syllabusService.getCoursewareResourceVideos(level3ResourceId);
                break;
            case 3:
                resources = await this.syllabusService.getCoursewareResourceAudios(level3ResourceId);
                break;
            default:
                break;
        }
        this.level4ResourcesMap[key] = resources;
    };

    handleDeleteLevel3Resource(level3ResourceIndex: number) {
        this.getLevel3ResourcesForm().removeAt(level3ResourceIndex);
    }

    handleDeleteHomework() {
        this.deleteHomework.emit();
    }

    handleAddHomework() {
        this.addHomework.emit();
    }

    validate() {
        // 标记所有控件为已触摸
        this.form.markAllAsTouched();
        // print errors
        console.log(this.form.valid, this.form.errors, this.form);
        return this.form.valid;
    }

}
