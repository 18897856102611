import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  checkNameExist(query?: any) {
    let url = `/theme/check/name`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }


  deleteItem(data: any) {
    return this.post('/theme/item/delete', data);
  }

  save(data: any) {
    return this.post('/theme/item/save', data);
  }



}
