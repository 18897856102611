<common-top-tool-bar [title]="pageTitle"
                     [back]="'/admin/v3/resource/'+bisType+'/courseware/edit/'+book_id"
                     [query]="{tabIndex: 1}"
                     [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'" class="mr-4"
            [nzSize]="'large'" nzGhost
            [routerLink]="'/admin/v3/resource/'+bisType+'/courseware'">
      取消
    </button>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            (click)="save($event)">
      {{this.book_id ? '儲存' : '確定新增' }}
    </button>
  </ng-template>
</common-top-tool-bar>


<input nz-input [(ngModel)]="audio_relate" placeholder="large size" nzSize="large" />


