import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CoursewareService } from '../../../../../services/courseware.service';
import { HWResourceService } from '../../../../../services/huawei-resource.service';
import { MediaService } from '../../../../../services/media.service';
import { MediaV2Service } from '../../../../../services/media-v2.service';
import { SyllabusService } from '../../../../../services/syllabus.service';


@Component({
  selector: 'app-admin-media-resource-list',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class AdminMediaResourceListComponent implements OnInit {
  dataSet: any[] = [];
  totalNum = 0;
  totalClasses = 0;
  currentPageIndex = 1;
  user: any = null;
  constUserType = UserType;
  isLoading = false;
  isLoadingAttr = false;
  pageTitle: string | undefined = '';

  bookAttrs: any = {};
  themeKeyValueItems: any = {};
  syllabusKeyValueItems: any = {};
  rootSyllabusThemes: any = [];
  subSyllabusThemes: any = [];

  currentSyllabusId = 0;
  currentCourseId = 0;


  filter: any = {
    pageIndex: 1,
    name: '',
    syllabus_id: '',
    course_id: '',
    themes: {
    },
    reserve3: '',
    // created_on: '',
  };
  host = '';
  protocol = '';
  bucket = '';
  // bisType = 'cartoon';
  resourceType = '';



  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private mediaService: MediaV2Service,
              private syllabusService: SyllabusService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    const u = new URL(window.location.href);

    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;

    this.activatedRoute.title.subscribe({
      next: (title) => {
        this.pageTitle = title;
      }
    });
    // console.log('[list] this.activatedRoute', this.activatedRoute.pathFromRoot[2].data);
    // let version = this.router.browserUrle.root.children.primary.segments[1].path;
    // this.activatedRoute.pathFromRoot[2].data.subscribe((data) => {
    //   this.bisType = data['bis_type'];
    //   console.log('bisType', this.bisType);
    // });
    this.resourceType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.school && parseInt(query.school, 10) === query.school * 1) {
    //     this.school_id = parseInt(query.school, 10);
    //   }
    // });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });
    // @ts-ignore
    window.aaa = this;
  }
  changeStatus($event: any, row: any ) {
    console.log('changeStatus', $event, row);
    let enabled = 0;
    if ($event) {
      enabled = 1;
    }
    row.enabled = enabled;
    row.reserve3 = enabled;
    console.log('changeStatus reserve3', enabled);
    this.isLoading = true;
    this.mediaService.save(this.resourceType, {id: row.id, reserve3: enabled, enabled }).then(() => {
      this.isLoading = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.message || 'System Error');
      this.isLoading = false;
    });
  }

  changeCategory(syllabus_id: number) {
    this.filter.course_id = '';
    this.filter.syllabus_id = syllabus_id;
    if (!syllabus_id) {
      this.rootSyllabusThemes.length = 0;
      this.subSyllabusThemes.length = 0;
      return;
    }
    const a = this.syllabusKeyValueItems[syllabus_id];
    const root_themes = this.bookAttrs.themes.filter((s: any) => {
      return s.syllabus_id === syllabus_id;
    });

    console.log('chang syllabus', syllabus_id, root_themes);
    this.rootSyllabusThemes = root_themes;
  }

  changeCourse(course_id: number) {
    this.filter.course_id = course_id;
    if (!course_id) {
      this.subSyllabusThemes.length = 0;
      return;
    }
    const sub_themes = this.bookAttrs.themes.filter((s: any) => {
      return s.syllabus_id === course_id;
    });
    console.log('chang course', course_id, sub_themes);
    this.subSyllabusThemes = sub_themes;
  }


  changeSubTheme(theme_group_id: number, theme_id: number) {
    console.log('changeSubTheme', theme_group_id, theme_id);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log('onQueryParamsChange', params);
    const { pageSize, pageIndex, sort, filter } = params;
    // const currentSort = sort.find(item => item.value !== null);
    // const sortField = (currentSort && currentSort.key) || null;
    // const sortOrder = (currentSort && currentSort.value) || null;
    this.loadData(params);
  }
  ngOnInit() {
    this.loadData({pageIndex: 1});
    this.isLoadingAttr = true;
    // @ts-ignore
    this.syllabusService.category(this.resourceType).then((bookAttrs: any) => {
      this.bookAttrs = bookAttrs;
      const _cateThemeKeyValueItems: any = {};
      bookAttrs.themes.forEach((theme: any) => {
        _cateThemeKeyValueItems[theme.id] = theme;
      });
      this.themeKeyValueItems = _cateThemeKeyValueItems;
      const _syllabusKeyValueItems: any = {};
      bookAttrs.syllabus.forEach((syllabus: any) => {
        _syllabusKeyValueItems[syllabus.id] = syllabus;
      });
      this.syllabusKeyValueItems = _syllabusKeyValueItems;
      console.log('ngOnInit', bookAttrs);
      this.isLoadingAttr = false;
    }).catch((err: any) => {
      this.isLoadingAttr = false;
      this.nzMessageService.error(err.message || 'System Error');
    });

  }
  doFilter($evt: any) {
    if (!$evt) {
      return;
    }
    console.log('doFilter', $evt, this.filter);
    this.loadData({pageIndex: 1, ...this.filter});
  }
  clearSearch() {
    this.filter.name = '';
    // this.loadData({pageIndex: 1});
  }
  loadData(query?: any) {
    console.log('loadData query', query);
    this.isLoading = true;
    this.mediaService.list(this.resourceType, {...query}).then((result: any) => {
      console.log('loadData data', result);
      this.dataSet = result.rows.map((r: any) => {
        r.created_date = r.created_date.substring(0, 10);
        return r;
      });
      this.totalNum = result.total;
      this.isLoading = false;

    }).catch((err: any) => {
      this.nzMessageService.error(`錯誤：${err.message}`);
      this.isLoading = false;
    });

  }

}
