import { Component, Input, TemplateRef } from '@angular/core';




@Component({
  selector: 'common-top-tool-bar',
  template: `
    <div class="table-operations toolbar-operations">
  <span nz-icon nzType="arrow-left"
        *ngIf="back"
        style="align-items: center;display: flex;color: black;    padding-right: 1rem;"
        [routerLink]="back"
        [queryParams]="query"
        nzTheme="outline"></span>
      <div class="toolbar-main-title">{{title}}
        <div class="toolbar-main-title-content"></div>
      </div>
      <div class="toolbar-main-action">
        <ng-container *ngTemplateOutlet="actionsTemplate;"></ng-container> 
      </div>
    </div>
  `,
  styles: [
    `
     
    `
  ]
})
export class CommonTopToolBarComponent {

  @Input() back?: string;
  @Input() query?: any;
  @Input() title?: string;
  @Input() actionsTemplate?: TemplateRef<any>;
}
